
<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'New_Agent_Request' | translate }}</h2>
        </div>
    </div>
</div>



<section class="contact-area ptb-70 bg-light">



    <div class="container">

        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <p><i class='bx bx-info-circle text-primary'></i> {{ 'New_Agent_Request_Attachment_TXT' | translate  }}</p>


            </div>
        </div>

        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Attachment' | translate}}</h3>

                <mat-table [dataSource]="dataSourceAttachment" matSort>

                    <ng-container matColumnDef="num">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Num.' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachmentTypeNameEn">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Document' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="attachmentTypeNameEn"> {{row.attachmentTypeNameEn}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachmentTypeNameAr">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Document' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="attachmentTypeNameAr"> {{row.attachmentTypeNameAr}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="filePath">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Picture' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="filePath"> <img [src]="apiURL + row.filePath" style="max-height: 80px; max-width: 80px; margin: 3px; width: 100%;" onerror="this.onerror=null; this.src='https://images.assetsdelivery.com/compings_v2/yehorlisnyi/yehorlisnyi2104/yehorlisnyi210400016.jpg'" /> </mat-cell>

                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div class="text-success" *ngIf="row.filePath != ''"><i class="bx bx-check" style="font-size:40px"></i></div>
                            <button mat-icon-button class="btn btn-primary" (click)="openAttachmentPopUp(row, false)" *ngIf="row.filePath == ''" style="font-size:20px"><i class="bx bx-plus"></i></button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;">
                    </mat-row>

                    <ng-container matColumnDef="noData">
                        <mat-footer-cell *matFooterCellDef colspan="6">
                            {{'No_data.' | translate }}
                        </mat-footer-cell>
                    </ng-container>


                </mat-table>


                <div>
                    <br />

                    <button class="default-btnWhite" *ngIf="isDone"  style="width:150px" type="button" (click)="GoNextBT()">{{'Submit' | translate }}</button>
                </div>


            </div>
        </div>
    </div>

</section>

