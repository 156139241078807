import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { OrderPersonAttachmentService } from '../../../../appshared/orders/order-person-attachment/order-person-attachment.service';
import { VWVisaTypeAttachmentTypeOrderPersonAttachment } from '../../../../appshared/visa/visa-type-attachment-type/visa-type-attachment-type.module';
import { VisaTypeAttachmentTypeService } from '../../../../appshared/visa/visa-type-attachment-type/visa-type-attachment-type.service';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-personal-attachment-dialog',
  templateUrl: './personal-attachment-dialog.component.html',
    styleUrls: ['./personal-attachment-dialog.component.scss'],
    providers: [VisaTypeAttachmentTypeService]
})
export class PersonalAttachmentDialogComponent implements OnInit {

    imageUrl: string = "https://images.assetsdelivery.com/compings_v2/yehorlisnyi/yehorlisnyi2104/yehorlisnyi210400016.jpg";
    fileToUpload: File = null;
    pid: number = 0;
    public files: any[];
    public SelectedLanguage: string = "en";


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public service: OrderPersonAttachmentService, private toastr: ToastrService,
        public servAttachment: VisaTypeAttachmentTypeService, public translate: TranslateService,
        public dialogRef: MatDialogRef<PersonalAttachmentDialogComponent>, private loader: AppLoaderService
    ) {
        this.files = [];
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit() {
        this.buildItemForm(this.data.payload)
    }


    buildItemForm(item: VWVisaTypeAttachmentTypeOrderPersonAttachment) {

        if (Object.keys(item).length != 0) {



            this.servAttachment.formData2 = Object.assign({}, item);
            if (this.servAttachment.formData2.orderPersonAttachmentID == null) {

                this.servAttachment.formData2.orderPersonAttachmentID = 0;

                this.service.formData.orderPersonAttachmentID = 0;
                this.service.formData.orderPersonID = this.data.lid;
                this.service.formData.attachmentTypeID = this.servAttachment.formData2.attachmentTypeID;
                this.service.formData.type = "Client Attachment";

                if (this.SelectedLanguage == "ar") {
                    this.servAttachment.formData2.attachmentTypeNameEn = this.data.payload.attachmentTypeNameAr;
                    this.servAttachment.formData2.descriptionEn = this.data.payload.descriptionAr;
                }

            }
            else {

                this.service.formData.orderPersonID = this.servAttachment.formData2.orderPersonID;
                this.service.formData.attachmentTypeID = this.servAttachment.formData2.attachmentTypeID;
                this.service.formData.filePath = this.servAttachment.formData2.filePath;
                this.service.formData.notes = this.servAttachment.formData2.notes;
                this.service.formData.orderPersonAttachmentID = this.servAttachment.formData2.orderPersonAttachmentID;
                this.service.formData.type = this.servAttachment.formData2.type;

                if (this.servAttachment.formData2.filePath != "") {
                    this.imageUrl = environment.apiUrl2 + 'Resources/Attachment/Order/' + this.servAttachment.formData2.filePath;
                }
                if (this.SelectedLanguage == "ar") {
                    this.servAttachment.formData2.attachmentTypeNameEn = this.data.payload.attachmentTypeNameAr;
                    this.servAttachment.formData2.descriptionEn = this.data.payload.descriptionAr;
                }

            }
        }
    }


    onSubmit(form: NgForm, event) {
        if (event.files.length != 0) {

            if (this.service.formData.orderPersonAttachmentID == 0)
                this.insertRecordWithFile(form, event.files);
            else
                this.updateRecordWithFile(form, event.files);
        }
        else {
            this.toastr.error('Please Add Attachment', '');
     
        }
    }


    insertRecord(form: NgForm) {
      
        this.service.postService().subscribe(
            res => {

                this.dialogRef.close(form);
            },
            err => { console.log(err); }
        );
    }


    updateRecord(form: NgForm) {
   
        this.service.putService().subscribe(
            res => {
                this.dialogRef.close(form);
            },
            err => { console.log(err); }
        );
    }

    handleFileInput(file: FileList) {
        this.fileToUpload = file.item(0);

        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        }
        reader.readAsDataURL(this.fileToUpload);
    }


    insertRecordWithFile(form: NgForm, fileToUpload) {
        var classes = [];
        this.loader.open();
        this.service.postServiceWithFile(fileToUpload)
            .subscribe(res => {
                this.isUpload(res, form);
           

            })

    }

    isUpload(event: any, form: NgForm) {
     
        if (event.statusText == "OK") {
   this.loader.close();
            this.dialogRef.close(form);
        }
    }


    updateRecordWithFile(form: NgForm, fileToUpload) {
     
        this.service.putServiceWithFile(fileToUpload).subscribe(res => {
            this.isUpload(res, form);


        })
    }


    goToLink() {
        window.open(environment.apiUrl2 + 'Resources/Attachment/Order/' + this.service.formData.filePath, "_blank");
    }
}
