import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { JobCategoryModule } from './job-category.module';

@Injectable({
  providedIn: 'root'
})
export class JobCategoryService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getService(): Observable<JobCategoryModule[]> {
        return this.http.get<JobCategoryModule[]>(environment.apiURL + 'Master/JobCategory/');
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Master/JobCategory/' + id).toPromise();
    }

    formData: JobCategoryModule = new JobCategoryModule();
    list: JobCategoryModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Master/JobCategory/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Master/JobCategory/' + this.formData.jobCategoryID, this.formData);
    }
}
