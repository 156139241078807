import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { MultipageModule } from './multipage.module';

@Injectable({
  providedIn: 'root'
})
export class MultipageService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<MultipageModule[]> {
        return this.http.get<MultipageModule[]>(environment.apiURL + 'WebTools/Multipage/');
    }

    getServiceType(type: string): Observable<MultipageModule[]> {
        return this.http.get<MultipageModule[]>(environment.apiURL + 'WebTools/Multipage/search/' + type)
            .pipe(
               
            );
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'WebTools/Multipage/' + id).toPromise();
    }
     
    formData: MultipageModule = new MultipageModule();
    list: MultipageModule[];

    postService() {
        return this.http.post(environment.apiURL + 'WebTools/Multipage/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'WebTools/Multipage/' + this.formData.multipageID, this.formData);
    }
}
