export class CountryModule {
    countryID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    isVisible: boolean = true;
    notes: string = '';
 }

export interface Country {
    countryID: number;
    nameAr: string;
    nameEn: string;
    isVisible: boolean;
    notes: string;
}
