export class OrderUserCommentModule {
    orderUserCommentID: number = 0;
    orderID: number = 0;
    userID: number = 0;
    commentType: string = '';
    details: string = '';
    addDate: Date = new Date();

}

export class VWOrderUserCommentAndMGUserModule {
    orderUserCommentID: number = 0;
    orderID: number = 0;
    userID: number = 0;
    commentType: string = '';
    details: string = '';
    addDate: Date = new Date();
    employeeName: string = '';
 }
