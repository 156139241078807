<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'New_Agent_Request' | translate }}</h2>
            <p style="color:azure"></p>
        </div>
    </div>
</div>



<section class="contact-area ptb-70 bg-light">
    <div class="container">

        <div class="cart-totals">

            <h3 class="text-center text-success">{{'Your_Order_is_complete' | translate }}</h3>
            <h1 class="text-center text-success"><i class='bx bx-check-circle'></i></h1>
           
            <button class="text-center default-btn" style="margin:20px" (click)="goHome()">{{"Go_to_Home" | translate }}</button>
        </div>

    </div>
</section>
