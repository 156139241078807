import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { CurrencyModule } from './currency.module';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceId(id: number): Observable<CurrencyModule[]> {
        return this.http.get<CurrencyModule[]>(environment.apiURL + 'Master/Currency/' + id);
    }

    getService(): Observable<CurrencyModule[]> {
        return this.http.get<CurrencyModule[]>(environment.apiURL + 'Master/Currency/');
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Master/Currency/' + id).toPromise();
    }

    formData: CurrencyModule = new CurrencyModule();
    list: CurrencyModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Master/Currency/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Master/Currency/' + this.formData.currencyID, this.formData);
    }
}
