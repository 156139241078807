export class OrderPersonAttachmentModule {
    orderPersonAttachmentID: number = 0;
    orderPersonID: number = 0;
    attachmentTypeID: number = 0;
    filePath: string = '';
    type: string = '';
    notes: string = '';
}


export class VWOrderPersonAttachmentAndAttachmentTypeModule {
    orderPersonAttachmentID: number = 0;
    orderPersonID: number = 0;
    attachmentTypeID: number = 0;
    filePath: string = '';
    type: string = '';
    notes: string = '';
    attachmentTypeNameAr: string = '';
    attachmentTypeNameEn: string = '';
    firstName: string = '';
    lastName: string = '';
    fullName: string = '';
    descriptionAr: string = '';
    descriptionEn: string = '';
}


export class VWOrderPersonAttachmentModule {
    orderPersonAttachmentID: number = 0;
    orderPersonID: number = 0;
    attachmentTypeID: number = 0;
    filePath: string = '';
    type: string = '';
    notes: string = '';
    attachmentTypeNameAr: string = '';
    attachmentTypeNameEn: string = '';
    firstName: string = '';
    lastName: string = '';
    fullName: string = '';
    orderID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
}
