export class ReligionModule {
    religionID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    notes: string = '';
}


export interface Religion {
    religionID: number;
    nameAr: string;
    nameEn: string;
    notes: string;
}
