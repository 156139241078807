export class FeedbackModule {
    feedbackID: number = 0;
    name: string = '';
    phoneNo: string = '';
    email: string = '';
    categoryID: number = 0;
    message: string = '';
    addDate: Date = new Date();
    type: string = '';
    notes: string = '';
    status: string = '';
    completedDate: string = '';
    dialing: string = '+968';
    completedByUserID: number = 0;
}

