import { Component, OnInit } from '@angular/core';
import { CategoryModule } from '../../../appshared/masters/category/category.module';
import { CategoryService } from '../../../appshared/masters/category/category.service';
import { NgForm } from '@angular/forms';
import { FeedbackService } from '../../../appshared/webTools/feedback/feedback.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { FeedbackModule } from '../../../appshared/webTools/feedback/feedback.module';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    public categoryData: CategoryModule[];
   /* serviceData2: CategoryModule = new CategoryModule();*/

    constructor(public service: FeedbackService, public servCategory: CategoryService, private snackBar: MatSnackBar, private toastr: ToastrService, public translate: TranslateService, private router: Router, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    public SelectedLanguage: string = "en";

    ngOnInit(): void {
        this.service.formData = new FeedbackModule();
        this.buildItemForm()
    }
    buildItemForm() {
        this.getAllCategory();
        this.service.formData.categoryID = 1;
        this.service.formData.status = "Waiting";
    }


    public getAllCategory = () => {
        //this.loader.open();
        this.servCategory.getCategory("Enguiry Type")
            .subscribe(res => {
                this.categoryData = res as CategoryModule[];
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

    onSubmit(form: NgForm) {
        if (!this.validateEmail(this.service.formData.email)) {
            return;
        }
        if (this.service.formData.feedbackID == 0)
            this.insertRecord(form);
    }

    insertRecord(form: NgForm) {
        this.service.formData.dialing = '+' + this.Dialing;
        this.service.postService().subscribe(
            res => {
                /* this.serviceData2 = res as CategoryModule;*/

                this.toastr.success('Added Successfully!', '');

                this.router.navigate(['home']);
                this.service.formData = new FeedbackModule();

               // this.snackBar.open('Added Successfully!', 'OK', { duration: 4000 });
            },
            err => { console.log(err); }
        );
    }

    validateEmail(email) {
        var emailTest = true;
        if (email != '') {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(String(email).toLowerCase()))
            emailTest = re.test(String(email).toLowerCase())
            if (emailTest != true) {
                this.toastr.warning("Please enter valid email")

            }

        }
        return emailTest;
    }

    ///////////////////////////////////////////////////////////////////////////
    Dialing: string = "968";
    telOptions = { initialCountry: 'om', preferredCountries: ['om'] };
    phone_number_standard = '';
    onCountryChange(event) {
        this.Dialing = event.dialCode;
    }
}
