import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';
import { VWAgentRequestAttachmentTypeAgentRequestAttachment } from '../../../../../appshared/agent/agent-request-attachment-type/agent-request-attachment-type.module';
import { AgentRequestAttachmentTypeService } from '../../../../../appshared/agent/agent-request-attachment-type/agent-request-attachment-type.service';
import { RequestAttachmentService } from '../../../../../appshared/agent/request-attachment/request-attachment.service';
import { AppLoaderService } from '../../../../../common/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-agent-request-attachment-dialog',
  templateUrl: './new-agent-request-attachment-dialog.component.html',
  styleUrls: ['./new-agent-request-attachment-dialog.component.scss']
})
export class NewAgentRequestAttachmentDialogComponent implements OnInit {
    imageUrl: string = "https://images.assetsdelivery.com/compings_v2/yehorlisnyi/yehorlisnyi2104/yehorlisnyi210400016.jpg";
    fileToUpload: File = null;
    pid: number = 0;
    public files: any[];
    public SelectedLanguage: string = "en";


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public service: RequestAttachmentService, private toastr: ToastrService,
        public servAttachment: AgentRequestAttachmentTypeService, public translate: TranslateService,
        public dialogRef: MatDialogRef<NewAgentRequestAttachmentDialogComponent>, private loader: AppLoaderService
    ) {
        this.files = [];
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }
    //  AgentRequestAttachmentTypeService
    ngOnInit() {
        this.buildItemForm(this.data.payload)
    }


    buildItemForm(item: VWAgentRequestAttachmentTypeAgentRequestAttachment) {

        if (Object.keys(item).length != 0) {

            this.servAttachment.formData2 = Object.assign({}, item);
            this.servAttachment.formData2.nameAr = this.data.payload.attachmentTypeNameAr;
            this.servAttachment.formData2.nameEn = this.data.payload.attachmentTypeNameEn;
            if (this.SelectedLanguage == "ar") {
                this.servAttachment.formData2.nameEn = this.data.payload.attachmentTypeNameAr;
                this.servAttachment.formData2.descriptionEn = this.data.payload.descriptionAr;
            }

            if (this.servAttachment.formData2.requestAttachmentID == null) {

                this.servAttachment.formData2.requestAttachmentID = 0;

                this.service.formData.requestAttachmentID = 0;
                this.service.formData.requestID = this.data.lid;
                this.service.formData.attachmentTypeID = this.servAttachment.formData2.attachmentTypeID;
                this.service.formData.type = "Agent Attachment";

            }
            else {


                this.service.formData.requestID = this.servAttachment.formData2.requestID;
                this.service.formData.attachmentTypeID = this.servAttachment.formData2.attachmentTypeID;
                this.service.formData.filePath = this.servAttachment.formData2.filePath;
                this.service.formData.notes = this.servAttachment.formData2.notes;
                this.service.formData.requestAttachmentID = this.servAttachment.formData2.requestAttachmentID;
                this.service.formData.type = this.servAttachment.formData2.type;
                if (this.servAttachment.formData2.filePath != "") {
                    this.imageUrl = environment.apiUrl2 + 'Resources/Attachment/Agent/' + this.servAttachment.formData2.filePath;
                }


            }
        }
    }


    onSubmit(form: NgForm, event) {
        if (event.files.length != 0) {

            if (this.service.formData.requestAttachmentID == 0)
                this.insertRecordWithFile(form, event.files);
            else
                this.updateRecordWithFile(form, event.files);
        }
        else {
            this.toastr.error('Please Add Attachment', '');

        }
    }


    insertRecord(form: NgForm) {

        this.loader.open();
        this.service.postService().subscribe(
            res => {

                this.dialogRef.close(form);
                this.loader.close();
            },
            err => {
                this.loader.close(); console.log(err); }
        );
    }


    updateRecord(form: NgForm) {

        this.loader.open();
        this.service.putService().subscribe(
            res => {
                this.dialogRef.close(form);
                this.loader.close();
            },
            err => {
                this.loader.close(); console.log(err); }
        );
    }

    handleFileInput(file: FileList) {
        this.fileToUpload = file.item(0);

        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        }
        reader.readAsDataURL(this.fileToUpload);
    }


    insertRecordWithFile(form: NgForm, fileToUpload) {

        this.loader.open();
        this.service.postServiceWithFile(fileToUpload).subscribe(res => {
                   this.isUpload(res, form);
               },
            err => {
                this.loader.close(); console.log(err); }
        );
    }


     isUpload(event: any, form: NgForm) {
    if (event.statusText == "OK") {
        this.loader.close();
        this.dialogRef.close(form);
    }
}

    updateRecordWithFile(form: NgForm, fileToUpload) {

        this.loader.open();
        this.service.putServiceWithFile(fileToUpload).subscribe(
            res => {
                this.dialogRef.close(form);
                this.loader.close();
            },
            err => {
                this.loader.close(); console.log(err); }
        );
    }


    goToLink() {
        window.open(environment.apiUrl2 + 'Resources/Attachment/Agent/' + this.service.formData.filePath, "_blank");
    }
}
