export class VisaTypeInputTypesModule {
    visaTypeInputTypesID: number = 0;
    visaTypeID: number = 0;
    inputTypesID: number = 0;
}
export class VWVisaTypeInputTypesAndInputTypesModule {
    visaTypeInputTypesID: number = 0;
    visaTypeID: number = 0;
    inputTypesID: number = 0;
    name: string = '';
    inputType: string = '';
    code: string = '';
    descriptionAr: string = '';
    descriptionEn: string = '';
}
