import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { LanguageModule } from './language.module';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<LanguageModule[]> {
        return this.http.get<LanguageModule[]>(environment.apiURL + 'Master/Language/');
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Master/Language/' + id).toPromise();
    }

    formData: LanguageModule = new LanguageModule();
    list: LanguageModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Master/Language/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Master/Language/' + this.formData.languageID, this.formData);
    }
}
