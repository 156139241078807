import { Component, OnInit } from '@angular/core';
import { MultipageModule } from '../../../appshared/webTools/multipage/multipage.module';
import { MultipageService } from '../../../appshared/webTools/multipage/multipage.service';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-application-status',
  templateUrl: './application-status.component.html',
  styleUrls: ['./application-status.component.scss']
})
export class ApplicationStatusComponent implements OnInit {
    public PageContentHTML: string = "";
    public PageTitle: string = "";
    private datas: any;


    constructor(private service: MultipageService, public translate: TranslateService, private loader: AppLoaderService, private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //scroll to top
                window.scrollTo(0, 0); `enter code here`
            }
        });
    }

    ngOnInit(): void {
        this.getAllService();

        document.body.scrollTop = 0;

    }



    public getAllService = () => {
        /*    this.isLoading = true;*/
        //this.loader.open();
        this.service.getServiceType("Application Status")
            .subscribe(res => {
                this.loader.close();
                this.datas = res as MultipageModule[];

                this.service.formData.contentAr = this.datas.contentAr;
                this.service.formData.contentEn = this.datas.contentEn;
                this.service.formData.isVisible = this.datas.isVisible;
                this.service.formData.multipageID = this.datas.multipageID;
                this.service.formData.titleAr = this.datas.titleAr;
                this.service.formData.titleEn = this.datas.titleEn;
                this.service.formData.type = this.datas.type;
                if (this.translate.currentLang == "ar") {

                    this.PageContentHTML = this.service.formData.contentAr;
                    this.PageTitle = this.service.formData.titleAr;
                }
                else {

                    this.PageContentHTML = this.service.formData.contentEn;
                    this.PageTitle = this.service.formData.titleEn;
                }


            },
                err => {
                    this.loader.close(); console.log(err);
                });

        /*  this.isLoading = false;*/
    }
}
