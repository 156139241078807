export class JobCategoryModule {
    jobCategoryID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    notes: string = '';
}

export interface JobCategory {
    jobCategoryID: number;
    nameAr: string;
    nameEn: string;
    notes: string;
}
