import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-complete-payment-dialog',
  templateUrl: './complete-payment-dialog.component.html',
  styleUrls: ['./complete-payment-dialog.component.scss']
})
export class CompletePaymentDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        public dialogRef: MatDialogRef<CompletePaymentDialogComponent>, private service: OrderService, private ServSavedOrder: SavedOrderService, public translate: TranslateService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    public SelectedLanguage: string = "en";
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    dates: string = "";
    email: string = "";



    orderNo: number = 0;
    arrivalDate: Date = new Date();
    visitCountry: string = "";
    visaType: string = "";
    nationality: string = "";

    ngOnInit(): void {

        this.vtid = this.data.vtid;
        this.nid = this.data.nid;
        this.vid = this.data.vid;
        this.rid = +this.data.rid;
        this.oid = +this.data.oid;
        this.email = this.data.email;
        this.dates = this.data.dates;
        this.service.VWformData = this.data.vwOrder;

        this.arrivalDate = this.service.VWformData.arrivalDate;
        this.orderNo = this.service.VWformData.orderNo;

        if (this.SelectedLanguage == "ar") {
            this.visaType = this.service.VWformData.visaTypeNameAr;
            this.visitCountry = this.service.VWformData.visaCountryNameAr;
            this.nationality = this.service.VWformData.nationalityNameAr;
        }
        else {
            this.visaType = this.service.VWformData.visaTypeNameEn;
            this.visitCountry = this.service.VWformData.visaCountryNameEn;
            this.nationality = this.service.VWformData.nationalityNameEn;
        }

    }


    Continue() {
        this.paymentGateway();
        this.dialogRef.close();
    }

    Forget() {

        this.ServSavedOrder.removeOrder(this.service.VWformData.orderID);
        this.dialogRef.close();
    }



    paymentGateway() {

        const formTag = this.createPayOnlineFormTag();
        $('body').append(formTag);
        $('#formPayOnline').submit();

    }


    private createPayOnlineFormTag() {
        var langu = 'En'
        if (this.SelectedLanguage == 'ar') {
            langu = 'Ar'
        }

        var paymentLink = environment.apiUrlPay;

        return '<form id="formPayOnline" method="POST" action="' + paymentLink + '">'
            + '<input type="hidden" name="ReferenceNo" value="' + this.service.VWformData.orderNo + '">'
            + '<input type="hidden" name="Currency" value="OMR">'
            + '<input type="hidden" name="Language" value="' + langu + '">'
            + '<input type="hidden" name="Amount" value="' + this.service.VWformData.net + '">'
            + '<input type="hidden" name="Source" value="Visa.Hekaya">'
            + '<input type="hidden" name="PostJsonURL" value="' + environment.apiURL + 'Orders/Voucher/PaymentTransaction">'
            + '<input type="hidden" name="PostSuccessURL" value="https://visa.hekaya.co/payment-result;oid=' + this.service.VWformData.orderID + '">'
            + '<input type="hidden" name="PostFaildURL" value="https://visa.hekaya.co/payment-result;oid=' + this.service.VWformData.orderID + '">'
            + '<input type="hidden" name="Field1" value="' + this.service.formData.orderID + '">'
            + ' <input type="hidden" name="Field2" value="Order Receipt">'
            + '<input type="hidden" name="Field3" value="' + this.service.VWformData.currencyID + '">'
            + '<input type="hidden" name="Field4" value="' + this.service.VWformData.netCurr + '">'
            + '<input type="hidden" name="Field5" value="0">'//AgentID
            + '<input type="hidden" name="Field6" value="">'
            + '<input type="hidden" name="Field7" value="">'
            + ' <input type="hidden" name="Field8" value="' + environment.apiURL + 'Manage/MGUser/Login">'

            + ' <input type="submit" value="Pay Now">'
            + '</form>';
    }

}
