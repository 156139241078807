import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { VWAgentRequestAttachmentType, VWAgentRequestAttachmentTypeAgentRequestAttachment } from '../../../../appshared/agent/agent-request-attachment-type/agent-request-attachment-type.module';
import { AgentRequestAttachmentTypeService } from '../../../../appshared/agent/agent-request-attachment-type/agent-request-attachment-type.service';
import { VWRequestAttachmentTypeAndAttachmentTypeModule } from '../../../../appshared/agent/request-attachment/request-attachment.module';
import { RequestAttachmentService } from '../../../../appshared/agent/request-attachment/request-attachment.service';
import { RequestModule } from '../../../../appshared/agent/request/request.module';
import { RequestService } from '../../../../appshared/agent/request/request.service';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';
import { NewAgentRequestAttachmentDialogComponent } from './new-agent-request-attachment-dialog/new-agent-request-attachment-dialog.component';


@Component({
    selector: 'app-new-agent-request-attachment',
    templateUrl: './new-agent-request-attachment.component.html',
    styleUrls: ['./new-agent-request-attachment.component.scss']
})
export class NewAgentRequestAttachmentComponent implements OnInit {


    constructor(public service: RequestService, public dialog: MatDialog, public translate: TranslateService, private route: ActivatedRoute, private servRequestAttachment: RequestAttachmentService, private servAttachment: AgentRequestAttachmentTypeService, private toastr: ToastrService, private router: Router, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
        if (this.SelectedLanguage == "ar") {
            this.displayedColumnsAttachment = ['num', 'attachmentTypeNameAr', 'filePath', 'actions'];
        }
    }

    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    rid: number = 0;
    countAttachment: number = 0;
    public apiURL: string = "";
    isDone: boolean = false;
    private datas: any;

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.rid = +params['rid'];
        });
        this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Agent/';
        this.getAttachmentTable();
    }

    public dataSourceAttachment = new MatTableDataSource<VWRequestAttachmentTypeAndAttachmentTypeModule>();
    public dataSourceFirstAttachment = new MatTableDataSource<VWRequestAttachmentTypeAndAttachmentTypeModule>();
    public dataSourceRequestTypeAttachment = new MatTableDataSource<VWAgentRequestAttachmentType>();

    displayedColumnsAttachment = ['num', 'attachmentTypeNameEn', 'filePath', 'actions'];


    getAttachmentTable() {

        //this.loader.open();
        this.dataSourceFirstAttachment = new MatTableDataSource<VWRequestAttachmentTypeAndAttachmentTypeModule>();
        this.servRequestAttachment.getServiceVWRequest(this.rid)
            .subscribe(res => {
                this.dataSourceFirstAttachment.data = res as VWRequestAttachmentTypeAndAttachmentTypeModule[];

                this.countAttachment = this.dataSourceFirstAttachment.data.length;
                this.fillData();




                this.loader.close();
            },
                err => {
                    this.loader.close(); this.countAttachment = 0; this.fillData();
                })


    }

    async fillData() {

        //this.loader.open();
        this.servAttachment.getServiceVW()
            .subscribe(res => {
                this.dataSourceRequestTypeAttachment.data = res as VWAgentRequestAttachmentType[];

                for (var i = 0; i < this.dataSourceRequestTypeAttachment.data.length; i++) {

                    var isAdd = true;
                    for (var l = 0; l < this.dataSourceFirstAttachment.data.length; l++) {

                        if (this.dataSourceFirstAttachment.data[l].attachmentTypeID == this.dataSourceRequestTypeAttachment.data[i].attachmentTypeID) {
                            isAdd = false;
                        }
                    }
                    if (isAdd) {
                        this.dataSourceFirstAttachment.data.push({ attachmentTypeID: this.dataSourceRequestTypeAttachment.data[i].attachmentTypeID, attachmentTypeNameAr: this.dataSourceRequestTypeAttachment.data[i].nameAr, attachmentTypeNameEn: this.dataSourceRequestTypeAttachment.data[i].nameEn, filePath: '', notes: '', requestAttachmentID: 0, requestID: this.rid, type: 'Agent Attachment', descriptionEn: this.dataSourceRequestTypeAttachment.data[i].attachDescriptionEn, descriptionAr: this.dataSourceRequestTypeAttachment.data[i].attachDescriptionAr })
                    }
                }

                this.dataSourceAttachment = this.dataSourceFirstAttachment;
                this.CheckIfDone();
                this.loader.close();
            },
                err => {
                    this.loader.close(); this.CheckIfDone();
                })
    }

    CheckIfDone() {
        if (this.dataSourceRequestTypeAttachment.data.length == this.countAttachment) {
            this.isDone = true;
        }
        else {

            this.isDone = false;
        }
    }




    data: any = {};
    openAttachmentPopUp(data: any = {}, isNew?) {
        let title = isNew ? 'add' : 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(NewAgentRequestAttachmentDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, payload: data, lid: this.rid }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                if (isNew) {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');

                } else {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');
                }
            })
    }

    GoNextBT() {

        this.loader.open();

       
        this.service.putConvertToWaiting(this.rid, "Waiting").subscribe(
            res => {
                this.loader.close();
                this.router.navigate(['request-result', { rid: this.rid }]);
            },
            err => {
                console.log(err);
                this.loader.close();
                this.router.navigate(['request-result', { rid: this.rid }]);
            }
        );



    }


   

}
