<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="3" dir="ltr" style="margin-left:-35px">
            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>

    </div>
</div>


<section class="contact-area ptb-70 bg-light">
    <div class="container">



        <div class="card recent-orders-box">
            <div class="card-body">

                <div class="blog-details-desc text-center">
                    <div class="article-content">

                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-globe'></i>
                                            <span>{{'Country' | translate }}</span>
                                            <a>{{ countryName }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>

                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-file-import'></i>
                                            <span>{{'Visa_Type' | translate }}</span>
                                            <a>{{ visaTypeName }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>

                                        <li>
                                            <i class='bx bx-calendar text-success'></i>
                                            <span>{{'Valid_from' | translate }}</span>
                                            <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>

                                        <li>
                                            <i class='bx bx-time-five'></i>
                                            <span>{{'Stay_days' | translate }}</span>
                                            <a>{{ stayDays }}</a>
                                            <br />
                                            <br />
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-calendar text-danger'></i>
                                            <span>{{'Valid_to' | translate }}</span>
                                            <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-file'></i>
                                            <span>{{'Order_No' | translate }}</span>
                                            <a>{{ orderNo }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>

                        <!--<div class="entry-meta">
        <ul>
            <li>
                <i class='bx bx-globe'></i>
                <span>{{'Country' | translate }}</span>
                <a>{{ countryName }}</a>
            </li>
            <li>
                <i class='bx bx-calendar text-success'></i>
                <span>{{'Valid_from' | translate }}</span>
                <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
            </li>
            <li>
                <i class='bx bx-time-five'></i>
                <span>{{'Stay_days' | translate }}</span>
                <a>{{ stayDays }}</a>
            </li>
            <li>
                <i class='bx bx-calendar text-danger'></i>
                <span>{{'Valid_to' | translate }}</span>
                <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
            </li>
            <li>
                <i class='bx bxs-file'></i>
                <span>{{'Order_No' | translate }}</span>
                <a>{{ orderNo }}</a>
            </li>
        </ul>
    </div>-->
                    </div>
                </div>

            </div>
        </div>


        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Review' | translate}}</h3>


                <form autocomplete="off" #userlogin="ngForm">
                    <div class="row">



                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Visa_Type' | translate }}</label>
                            <input type="text" class="form-control border" name="visaTypeNameEn" #visaTypeNameEn="ngModel" [(ngModel)]="service.VWformData.visaTypeNameEn" disabled>
                        </div>


                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Nationality' | translate }}</label>
                            <input type="text" class="form-control border" name="nationalityNameEn" #nationalityNameEn="ngModel" [(ngModel)]="service.VWformData.nationalityNameEn" disabled>
                        </div>


                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Residence_Country' | translate }}</label>
                            <input type="text" class="form-control border" name="countryNameEn" #countryNameEn="ngModel" [(ngModel)]="service.VWformData.countryNameEn" disabled>
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Applicant_Requester_Name' | translate }}</label>
                            <input type="text" class="form-control border" name="applicantRequesterName" #applicantRequesterName="ngModel" [(ngModel)]="service.VWformData.applicantRequesterName" disabled>
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Email' | translate }}</label>
                            <input type="text" class="form-control border" name="email" #email="ngModel" [(ngModel)]="service.VWformData.email" disabled>
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Phone_No' | translate }}</label>
                            <input type="text" class="form-control border" name="phoneNo" #phoneNo="ngModel" [(ngModel)]="service.VWformData.phoneNo" disabled>
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Arrival_Port' | translate }}</label>
                            <input type="text" class="form-control border" name="arrivalPort" #arrivalPort="ngModel" [(ngModel)]="service.VWformData.arrivalPort" disabled>
                        </div>

                    </div>
                </form>



                <h3>{{'Personal_Details' | translate}}</h3>

                <mat-tab-group animationDuration="500ms">
                    <mat-tab *ngFor="let item of datasourceVWOrderPerson;" label="{{item.firstName}} {{item.lastName}} {{item.fullName}}">
                        <div class="row" style="width:99%">

                            <div class="col-lg-6 col-md-12" [hidden]="!firstNameActive">
                                <div class="form-group">
                                    <label>{{'First_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.firstName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!middleNameActive">
                                <div class="form-group">
                                    <label>{{'Middle_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.middleName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!lastNameActive">
                                <div class="form-group">
                                    <label>{{'Last_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.lastName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!fullNameActive">
                                <div class="form-group">
                                    <label>{{'Full_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fullName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!genderActive">
                                <div class="form-group">
                                    <label>{{'Gender' | translate }}</label>

                                    <input type="text" class="form-control border" value="{{item.gender}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!marriedStatusActive">
                                <div class="form-group">
                                    <label>{{'Married_Status' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.marriedStatus}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!dobActive">
                                <div class="form-group">
                                    <label>{{'DOB' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.dob | date  : 'dd/MM/yyyy'}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!birthCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Birth_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.birthCountryNameEn}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!birthPlaceActive">
                                <div class="form-group">
                                    <label>{{'Birth_Place' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.birthPlace}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!religionIDActive">

                                <div class="form-group">
                                    <label>{{'Religion' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.religion}}" disabled>
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!spouseNameActive">
                                <div class="form-group">
                                    <label>{{'Spouse_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.spouseName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!firstLanguageIDActive">
                                <div class="form-group">
                                    <label>{{'First_Language' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.firstLanguageNameEn}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!secondLanguageIDActive">
                                <div class="form-group">
                                    <label>{{'Second_Language' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.secondLanguageNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!motherNameActive">
                                <div class="form-group">
                                    <label>{{'Mother_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.motherName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!motherNationalityIDActive">
                                <div class="form-group">
                                    <label>{{'Mother_Nationality' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.motherNationalityNameEn}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!motherPerviousNationalityIDActive">
                                <div class="form-group">
                                    <label>{{'Mother_Pervious_Nationality' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.motherPreviousNationalityNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!motherBirthPlaceActive">
                                <div class="form-group">
                                    <label>{{'Mother_Birth_Place' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.motherBirthPlace}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!motherBirthCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Mother_Birth_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.motherBirthCountryNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!fatherNameActive">
                                <div class="form-group">
                                    <label>{{'Father_Name' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fatherName}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!fatherNationalityIDActive">
                                <div class="form-group">
                                    <label>{{'Father_Nationality' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fatherNationalityNameEn}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!fatherPerviousNationalityIDActive">
                                <div class="form-group">
                                    <label>{{'Father_Pervious_Nationality' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fatherPreviousNationalityNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!fatherBirthPlaceActive">
                                <div class="form-group">
                                    <label>{{'Father_Birth_Place' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fatherBirthPlace}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!fatherBirthCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Father_Birth_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.fatherBirthCountryNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!jobTitleActive">
                                <div class="form-group">
                                    <label>{{'Job_Title' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.jobTitle}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!jobCategoryIDActive">
                                <div class="form-group">
                                    <label>{{'Job_Category' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.jobCategoryNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!ordinaryDocumentIDActive">
                                <div class="form-group">
                                    <label>{{'Ordinary_Document' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.ordinaryDocumentNameEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!passportNoActive">
                                <div class="form-group">
                                    <label>{{'Passport_No' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.passportNo}}" name="passportNo" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!passportIssueCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Passport_Issue_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.passportIssueCountryEn}}" name="passportIssueCountryEn" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!passportIssueDateActive">
                                <div class="form-group">
                                    <label>{{'Passport_Issue_Date' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.passportIssueDate | date  : 'dd/MM/yyyy'}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!passportExpiryDateActive">
                                <div class="form-group">
                                    <label>{{'Passport_Expiry_Date' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.passportExpiryDate | date  : 'dd/MM/yyyy'}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!phoneNumberActive">
                                <div class="form-group">
                                    <label>{{'Phone_Number' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.phoneNumber}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!emailActive">
                                <div class="form-group">
                                    <label>{{'Email' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.email}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!posteCodeActive">
                                <div class="form-group">
                                    <label>{{'Poste_Code' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.posteCode}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!previousNationalityIDActive">
                                <div class="form-group">
                                    <label>{{'Previous_Nationality' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.previousNationalityNameEn}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!isNameChangedActive">
                                <div class="form-group">
                                    <mat-slide-toggle name="isNameChanged" [checked]="item.isNameChanged" disabled>{{"Is_Name_Changed" | translate }}</mat-slide-toggle>

                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!visibleIdentificationMarkActive">
                                <div class="form-group">
                                    <label>{{'Visible_Identification_Mark' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.visibleIdentificationMark}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!educationQualificationActive">
                                <div class="form-group">
                                    <label>{{'Education_Qualification' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.educationQualification}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!acquireNationalityByActive">
                                <div class="form-group">
                                    <label>{{'Acquire_Nationality_By' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.acquireNationalityBy}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!isOtherValidPassportICActive">
                                <div class="form-group">
                                    <mat-slide-toggle name="isOtherValidPassportIC" [checked]="item.isOtherValidPassportIC" disabled>{{"Is_Other_Valid_Passport_IC" | translate }}</mat-slide-toggle>
                                </div>
                            </div>




                            <div class="col-lg-6 col-md-12" [hidden]="!otherPassportIssueCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Other_Passport_Issue_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.otherPassportIssueCountryEn}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!visitPlace1Active">
                                <div class="form-group">
                                    <label>{{'Visit_Place_1' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.visitPlace1}}" disabled>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!visitPlace2Active">
                                <div class="form-group">
                                    <label>{{'Visit_Place_2' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.visitPlace2}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!isVisitBeforeActive">
                                <div class="form-group">
                                    <mat-slide-toggle name="isVisitBefore" [checked]="item.isVisitBefore" disabled>{{"Is_Visit_Before" | translate }}</mat-slide-toggle>
                                </div>
                            </div>



                            <div class="col-lg-6 col-md-12" [hidden]="!visitedPlaceBeforeActive">
                                <div class="form-group">
                                    <label>{{'Visited_Place_Before' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.visitedPlaceBefore}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!cityVisitedBeforeActive">
                                <div class="form-group">
                                    <label>{{'City_Visited_Before' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.cityVisitedBefore}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!lastVisaNoActive">
                                <div class="form-group">
                                    <label>{{'Last_Visa_No' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.lastVisaNo}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!lastVisaTypeActive">
                                <div class="form-group">
                                    <label>{{'Last_Visa_Type' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.lastVisaType}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!lastIssuePlaceActive">
                                <div class="form-group">
                                    <label>{{'Last_Issue_Place' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.lastIssuePlace}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!lastIssueDateActive">
                                <div class="form-group">
                                    <label>{{'Last_Issue_Date' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.lastIssueDate | date  : 'dd/MM/yyyy'}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentHouseNoStreetActive">
                                <div class="form-group">
                                    <label>{{'Present_House_No_Street' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentHouseNoStreet}}" disabled>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12" [hidden]="!presentCityActive">
                                <div class="form-group">
                                    <label>{{'Present_City' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentCity}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentCountryIDActive">
                                <div class="form-group">
                                    <label>{{'Present_Country' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentCountryEn}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentStateActive">
                                <div class="form-group">
                                    <label>{{'Present_State' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentState}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentPostalCodeActive">
                                <div class="form-group">
                                    <label>{{'Present_Postal_Code' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentPostalCode}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentPhoneNoActive">
                                <div class="form-group">
                                    <label>{{'Present_Phone_No' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentPhoneNo}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentMobileNoActive">
                                <div class="form-group">
                                    <label>{{'Present_Mobile_No' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentMobileNo}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!presentEmailActive">
                                <div class="form-group">
                                    <label>{{'Present_Email' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.presentEmail}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!permanentHouseNoStreetActive">
                                <div class="form-group">
                                    <label>{{'Permanent_House_No_Street' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.permanentHouseNoStreet}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!permanentCityActive">
                                <div class="form-group">
                                    <label>{{'Permanent_City' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.permanentCity}}" disabled>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-12" [hidden]="!permanentStateActive">
                                <div class="form-group">
                                    <label>{{'Permanent_State' | translate }}</label>
                                    <input type="text" class="form-control border" value="{{item.permanentState}}" disabled>
                                </div>
                            </div>


                        </div>

                        <div class="listing-edit-delete-option">
                            <div class="d-flex align-items-start">
                                <button class="default-btnWhite btn_mobile" style="width: 160px; margin: 20px;" (click)="Edit_PersonBT(item.orderPersonID)">{{"Edit_Personal_Data" | translate }}</button>
                                <button class="default-btnBlack btn_mobile" style="width: 150px; margin: 20px" type="button" (click)="DeleteBT(item.orderPersonID)"> {{"Delete" | translate }}</button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <hr />
                <br />
                <br />
                <div class="listing-edit-delete-option">
                    <div class="d-flex justify-content-end align-items-end">
                        <button class="default-btnBlack btn_mobile" style="width: 160px; margin: 20px" type="button" (click)="Add_Extra_PersonBT()"><i class="bx bx-plus"></i> {{"Add_Extra_Person" | translate }}</button>

                        <button class="default-btnWhite btn_mobile" *ngIf="datasourceVWOrderPerson.length != undefined &&  datasourceVWOrderPerson.length != 0" style="width: 150px; margin: 20px" type="button" (click)="GoNextBT()">{{'Go_Next' | translate }}</button>
                    </div>
                    </div>

                </div>
            </div>
    </div>
</section>
