import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VisaTypeModule } from '../../../../appshared/visa/visa-type/visa-type.module';
import { VisaTypeService } from '../../../../appshared/visa/visa-type/visa-type.service';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';
import { InputInfoDialogComponent } from '../../step3-personal-details/input-info-dialog/input-info-dialog.component';

@Component({
  selector: 'app-insurance-info-dialog',
  templateUrl: './insurance-info-dialog.component.html',
  styleUrls: ['./insurance-info-dialog.component.scss']
})
export class InsuranceInfoDialogComponent implements OnInit {

    public SelectedLanguage: string = "en";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public service: VisaTypeService,
        public dialogRef: MatDialogRef<InputInfoDialogComponent>, public translate: TranslateService, private loader: AppLoaderService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit() {
        this.buildItemForm(this.data.id)
    }

    public details: string = "";
    private datas3: any;

    buildItemForm(visaTypeId: number) {

        this.loader.open();
        this.service.getServiceId(visaTypeId)
            .subscribe(res => {
                this.datas3 = res as VisaTypeModule[];

                this.service.formData = this.datas3;
               


                if (this.SelectedLanguage == "en") {


                    this.details = this.service.formData.insuranceDescriptionEn;
                }
                else {
                    this.details = this.service.formData.insuranceDescriptionAr;


                }


                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });


    }

}
