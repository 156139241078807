<section class="process-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{'Process' | translate }}</span>
            <h2>{{'See_How_It_Works' | translate }}</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-process-box">
                  
                        <img src="../../../../assets/img/icon/step1.png" width="90" />
            
                        <div class="content">
                            <br />
                            <h3>{{'Process1Title' | translate }}</h3>
                            <p>
                                {{'Process1TXT' | translate }}
                            </p>
                            <div class="number">1</div>
                        </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-process-box">

                    <img src="../../../../assets/img/icon/step2.png" width="90" />
                    <div class="content">
                        <br />
                        <h3>{{'Process2Title' | translate }}</h3>
                        <p>
                            {{'Process2TXT' | translate }}
                        </p>
                        <div class="number">2</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-process-box">

                    <img src="../../../../assets/img/icon/step3.png" width="90" />
                    <div class="content">
                        <br />
                        <h3>{{'Process3Title' | translate }}</h3>
                        <p>
                            {{'Process3TXT' | translate }}
                        </p>
                        <div class="number">3</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-process-box">

                    <img src="../../../../assets/img/icon/step4.png" width="90" />
                    <div class="content">
                        <br />
                        <h3>{{'Process4Title' | translate }}</h3>
                        <p>
                            {{'Process4TXT' | translate }}
                        </p>
                        <div class="number">4</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
