<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="1" dir="ltr" style="margin-left:-35px">
            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>

    </div>
</div>




<section class="contact-area ptb-70 bg-light">

    <!--<div class="container">
        <div class="section-title">-->
    <!--<span class="sub-title">Discover</span>-->
    <!--<h3>Insert Basic Details</h3>
            <h2></h2>
        </div>
    </div>-->

    <div class="container">

        <div class="card recent-orders-box" style="padding:15px"  *ngIf="warningAgeMessage != '' || warningGenderMessage != ''">

            <div class="card-body">
                <h3> {{"Restriction" | translate }}</h3>
                <p *ngIf="warningAgeMessage != ''" style="color:red"><i class='bx bx-info-circle text-primary'></i> {{ warningAgeMessage }}</p>
                <p *ngIf="warningGenderMessage != ''" style="color: red"><i class='bx bx-info-circle text-primary'></i> {{ warningGenderMessage }}</p>



                </div>
            </div>
                <div class="card recent-orders-box" style="padding:15px">
                    <div class="card-body">
                        <p><i class='bx bx-info-circle text-primary'></i> {{ warningMessage }}</p>
                        <div class="blog-details-desc text-center">
                            <div class="article-content">


                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="entry-meta">
                                            <ul>
                                                <li>
                                                    <i class='bx bx-globe'></i>
                                                    <span>{{'Country' | translate }}</span>
                                                    <a>{{ countryName }}</a>
                                                    <br />
                                                    <br />
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="entry-meta">
                                            <ul>

                                                <li>
                                                    <i class='bx bx-calendar text-success'></i>
                                                    <span>{{'Valid_from' | translate }}</span>
                                                    <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
                                                    <br />
                                                    <br />
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="entry-meta">
                                            <ul>

                                                <li>
                                                    <i class='bx bx-time-five'></i>
                                                    <span>{{'Stay_days' | translate }}</span>
                                                    <a>{{ stayDays }}</a>
                                                    <br />
                                                    <br />
                                                </li>


                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="entry-meta">
                                            <ul>
                                                <li>
                                                    <i class='bx bx-calendar text-danger'></i>
                                                    <span>{{'Valid_to' | translate }}</span>
                                                    <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
                                                    <br />
                                                    <br />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>




                                </div>




                            </div>
                        </div>

                    </div>
                </div>


                <div class="card recent-orders-box" style="padding:15px">
                    <div class="card-body">

                        <h3>{{'Please_Fill_Details' | translate }}</h3>

                        <form autocomplete="off" #userlogin="ngForm" (ngSubmit)="onClick(selectJobCategory.value, emails.value, confirmEmails.value, arrivalPort.value, phoneNo.value, applicantRequesterNames.value, phoneNo.value)">
                            <div class="row">



                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Applicant_Requester_Name' | translate }}</label>
                                    <input required type="text" name="applicantRequesterNames" ngModel #applicantRequesterNames class="form-control border">
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Phone_No' | translate }}</label>
                                    <div class="input-group">

                                        <input type="text" required ng2TelInput #phoneNo name="phoneNo" class="form-control border" (countryChange)="onCountryChange($event)" [(value)]="phone_number_standard" [ng2TelInputOptions]="telOptions" />

                                    </div>
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Email' | translate }}</label>
                                    <input required type="text" name="emails" ngModel #emails class="form-control border">
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Confirm_Email' | translate }}</label>
                                    <input type="text" name="confirmEmails" ngModel #confirmEmails class="form-control border">
                                </div>

                                <div class="col-xs-12 col-12 col-md-6 form-group">
                                    <label>{{'Arrival_Port' | translate }}</label>

                                    <div class="input-group mb-3">
                                        <input type="text" name="arrivalPort" ngModel #arrivalPort class="form-control border">
                                        <div class="input-group-append">
                                            <a (click)="viewDialog('ArrivalAirpotPortName')" href="javascript:void(0);" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-12" [hidden]="!isJob">
                                    <div class="form-group">
                                        <label>{{'Job_Category' | translate }}</label>
                                        <mat-select #selectJobCategory [formControl]="jobCategoryCtrl" placeholder="{{'Select_Job_Category' | translate }}" class="form-control border" style="padding-top:10px" required ngModel>
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="jobCategoryFilterCtrl" placeholderLabel="{{'Select_Job_Category' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                    <mat-icon ngxMatSelectSearchClear></mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>

                                            <mat-option *ngFor="let jobCategory of filteredJobCategories | async" [value]="jobCategory.jobCategoryID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                                </ng-template>
                                                <ng-template #other_contentAr>{{ jobCategory.nameAr }}</ng-template>
                                                <ng-template #other_contentEn>{{ jobCategory.nameEn }}</ng-template>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btnWhite" style="width:150px">{{'Save_Next' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
</section>
