import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { OrderPersonAttachmentModule, VWOrderPersonAttachmentAndAttachmentTypeModule, VWOrderPersonAttachmentModule } from './order-person-attachment.module';

@Injectable({
    providedIn: 'root'
})
export class OrderPersonAttachmentService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceOrderPerson(OrderPersonID: number): Observable<OrderPersonAttachmentModule[]> {
        return this.http.get<OrderPersonAttachmentModule[]>(environment.apiURL + 'Orders/OrderPersonAttachment/search/' + OrderPersonID);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Orders/OrderPersonAttachment/' + id).toPromise();
    }

    formData: OrderPersonAttachmentModule = new OrderPersonAttachmentModule();
    list: OrderPersonAttachmentModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Orders/OrderPersonAttachment/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Orders/OrderPersonAttachment/' + this.formData.orderPersonAttachmentID, this.formData);
    }


    postServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        const formData = new FormData();
        let fileToUpload = <File>files[0];

        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('attachmentTypeID', this.formData.attachmentTypeID.toString());
        formData.append('orderPersonID', this.formData.orderPersonID.toString());
        formData.append('type', this.formData.type.toString());
        formData.append('notes', this.formData.notes.toString());

        return this.http.post(environment.apiURL + 'Orders/OrderPersonAttachment/PostUpload', formData, { reportProgress: true, observe: 'events' });
    }

    putServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('orderPersonAttachmentID', this.formData.orderPersonAttachmentID.toString());
        formData.append('attachmentTypeID', this.formData.attachmentTypeID.toString());
        formData.append('orderPersonID', this.formData.orderPersonID.toString());
        formData.append('type', this.formData.type.toString());
        formData.append('notes', this.formData.notes.toString());
        return this.http.put(environment.apiURL + 'Orders/OrderPersonAttachment/PutUpload', formData, { reportProgress: true, observe: 'events' });
    }



    getServiceVW(): Observable<VWOrderPersonAttachmentAndAttachmentTypeModule[]> {
        return this.http.get<VWOrderPersonAttachmentAndAttachmentTypeModule[]>(environment.apiURL + 'Orders/VWOrderPersonAttachmentAndAttachmentType/');
    }
    getServiceVWVisaTypeNationality(VisaTypeNationalityID: number): Observable<VWOrderPersonAttachmentAndAttachmentTypeModule[]> {
        return this.http.get<VWOrderPersonAttachmentAndAttachmentTypeModule[]>(environment.apiURL + 'Orders/VWOrderPersonAttachmentAndAttachmentType/search/' + VisaTypeNationalityID);
    }

    getServiceVWOrderPerson(orderPersonID: number, type: string): Observable<VWOrderPersonAttachmentModule[]> {
        return this.http.get<VWOrderPersonAttachmentModule[]>(environment.apiURL + 'Orders/VWOrderPersonAttachment/searchOrderPerson/?orderPersonID=' + orderPersonID + '&type=' + type);
    }

    getServiceVWOrder(orderID: number, type: string): Observable<VWOrderPersonAttachmentModule[]> {
        return this.http.get<VWOrderPersonAttachmentModule[]>(environment.apiURL + 'Orders/VWOrderPersonAttachment/searchOrder/?orderID=' + orderID + '&type=' + type);
    }

    getServiceVWOrderNoFile(orderID: number, type: string): Observable<VWOrderPersonAttachmentModule[]> {
        return this.http.get<VWOrderPersonAttachmentModule[]>(environment.apiURL + 'Orders/VWOrderPersonAttachment/searchOrderNoFile/?orderID=' + orderID + '&type=' + type);
    }
}
