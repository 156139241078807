export class VisaTypeAttachmentTypeModule {
    visaTypeAttachmentTypeID: number = 0;
    visaTypeID: number = 0;
    attachmentTypeID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';

}

export class VWVisaTypeAttachmentTypeAndAttachmentTypeModule {
    visaTypeAttachmentTypeID: number = 0;
    visaTypeID: number = 0;
    attachmentTypeID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';
    attachmentTypeNameAr: string = '';
    attachmentTypeNameEn: string = '';
    attachDescriptionAr: string = '';
    attachDescriptionEn: string = '';

}

export class VWVisaTypeAttachmentTypeOrderPersonAttachment {
    visaTypeAttachmentTypeID: number = 0;
    visaTypeID: number = 0;
    attachmentTypeID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';
    attachmentTypeNameAr: string = '';
    attachmentTypeNameEn: string = '';
    orderPersonAttachmentID: number = 0;
    orderPersonID: number = 0;
    filePath: string = '';
    type: string = '';
    attachDescriptionAr: string = '';
    attachDescriptionEn: string = '';

}
