import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VisaCheckService } from '../../../appshared/visa/tools/visa-check.service';
import { VisaTypeModule } from '../../../appshared/visa/visa-type/visa-type.module';
import { VisaTypeService } from '../../../appshared/visa/visa-type/visa-type.service';
import { of, Observable } from 'rxjs';
import { ConfirmTermsDialogComponent } from './confirm-terms-dialog/confirm-terms-dialog.component';
import { FailedDialogComponent } from './failed-dialog/failed-dialog.component';
import { OfferDialogComponent } from './offer-dialog/offer-dialog.component';
import { SessionStoreService } from '../../../common/session-store.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { LocalStoreService } from '../../../common/local-store.service';
import { VisaCountryService } from '../../../appshared/visa/visa-country/visa-country.service';
import { VisaCountryModule } from '../../../appshared/visa/visa-country/visa-country.module';
import { VisaInfoDialogComponent } from './visa-info-dialog/visa-info-dialog.component';

@Component({
    selector: 'app-step1-visa-type',
    templateUrl: './step1-visa-type.component.html',
    styleUrls: ['./step1-visa-type.component.scss']
})
export class Step1VisaTypeComponent implements OnInit {

   visaCountryForm: VisaCountryModule = new VisaCountryModule();
    

    constructor(public servVisaCountry: VisaCountryService,
        public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private serv: VisaTypeService, public translate: TranslateService, private localS: LocalStoreService, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    public SelectedLanguage: string = "en";
    public CurrencyName: string = "";
    private sub: any;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    dates: string = "";
    warningNoVisa: boolean = false;

    public dataSource = new MatTableDataSource<VisaTypeModule>();

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.dates = params['date'];
            this.FillVisaTypes();
        });
    }

    private datas: any;

    FillVisaTypes() {
        var CurrencyRate = +this.localS.getItem("CurrencyRate");
        this.CurrencyName = this.localS.getItem("CurrencyName");
        this.loader.open();
        this.serv.getServiceVisaCountryActive(this.vid, this.nid)
            .subscribe(res => {
                this.dataSource.data = res as VisaTypeModule[];
                for (var i = 0; i < this.dataSource.data.length; i++) {
                    this.dataSource.data[i].pricePerPerson = this.dataSource.data[i].pricePerPerson * CurrencyRate
                }

                this.warningNoVisa = false;
                this.loader.close();
            },
                err => {
                    this.warningNoVisa = true;
                    this.loader.close(); console.log(err);
                });


        this.servVisaCountry.getServiceId(this.vid)
            .subscribe(res => {
                this.datas = res as VisaCountryModule[];

                this.visaCountryForm = this.datas as VisaCountryModule;

            },
                err => {
                    console.log(err);
                });
    }


    checkMessage: string = "";
    data: any = {};
    SelectedType(data2) {

        this.scrollToTop();
        this.loader.open();
        this.serv.getCheckService(data2.visaTypeID, this.vid, this.nid, this.rid, this.dates, this.SelectedLanguage)
            .subscribe(res => {
                this.checkMessage = res;


                this.loader.close();

                if (this.checkMessage == "success") {
                    if (this.SelectedLanguage == "ar") {
                        this.checkMessage = data2.termsAr;
                    }
                    else {
                        this.checkMessage = data2.termsEn;
                    }

                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(ConfirmTermsDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.data, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['step2', { vtid: data2.visaTypeID, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid }]);

                        })
                }
                else {


                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.data, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['home']);

                        })
                }

            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

    scrollToTop() {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
    }



    viewDialog(data2) {

        var CurrencyRate = +this.localS.getItem("CurrencyRate");
        this.CurrencyName = this.localS.getItem("CurrencyName");

        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(OfferDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, vid: data2.visaTypeID, CurrencyRate: CurrencyRate, visaCost: data2.pricePerPerson }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
            });
    }


    viewInfoDialog(data2) {

     
        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(VisaInfoDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, detailsAr: data2.descriptionAr, detailsEn: data2.descriptionEn }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
            });
    }

}
