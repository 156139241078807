import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-request-result',
  templateUrl: './request-result.component.html',
  styleUrls: ['./request-result.component.scss']
})
export class RequestResultComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }





    goHome() {

        this.router.navigate(['home']);

    }

}
