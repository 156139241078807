import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VWOrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { VisaTypeService } from '../../../appshared/visa/visa-type/visa-type.service';
import { SessionStoreService } from '../../../common/session-store.service';
import { ConfirmPaymentDialogComponent } from './confirm-payment-dialog/confirm-payment-dialog.component';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { FailedDialogComponent } from '../step1-visa-type/failed-dialog/failed-dialog.component';
import { LocalStoreService } from '../../../common/local-store.service';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';
import { InsuranceInfoDialogComponent } from './insurance-info-dialog/insurance-info-dialog.component';

@Component({
  selector: 'app-step6-payment',
  templateUrl: './step6-payment.component.html',
  styleUrls: ['./step6-payment.component.scss']
})
export class Step6PaymentComponent implements OnInit {
    thisID: number = 0;
    IsNew: boolean = true;
    public SelectedLanguage: string = "en";
    public CurrencyName: string = "";
    private sub: any;
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    dates: string = "";
    email: string = "";
    private datas: any;

    public itemDetails: string = "";
    public promotionCodeID: number = 0;

    public price: number = 0;
    public quantity: number = 0;
    public subtotal: number = 0;
    public total: number = 0;
    public vat: number = 0;
    public net: number = 0;
    public voucherAmount: number = 0;
    public amountPaid: number = 0;
    public insuranceAmount: number = 0;
    public groupDiscountPercentage: number = 0;
    public groupCostPerPerson: number = 0;
    public groupDiscount: number = 0;

    public isDisabledVoucher: boolean = false;

    public IsWebUseTerms: boolean = false;
    public IsServicesTerms: boolean = false;


    public insuranceAmountCurr: number = 0;
    public priceCurr: number = 0;
    public subtotalCurr: number = 0;
    public vatCurr: number = 0;
    public totalCurr: number = 0;
    public netCurr: number = 0;
    public voucherAmountCurr: number = 0;
    public groupDiscountCurr: number = 0;
    public groupDiscountPercentageCurr: number = 0;
    public groupCostPerPersonCurr: number = 0;

    constructor(public dialog: MatDialog, private router: Router, public translate: TranslateService, private route: ActivatedRoute, public service: OrderService, private servVisaType: VisaTypeService, private toastr: ToastrService, private localS: LocalStoreService, private loader: AppLoaderService, private ServSavedOrder: SavedOrderService)

    {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.vtid = +params['vtid'];
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.oid = +params['oid'];
            this.email = params['email'];
            this.dates = params['date'];
        });

        this.buildItemForm();
        this.gnCheckOrderValidity();
    }


    buildItemForm() {

        this.loader.open();
        this.service.getServiceVWId(this.oid)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];

                this.service.VWformData = this.datas;


                this.service.formData.orderID = this.datas.orderID;
                this.service.formData.orderNo = this.datas.orderNo;
                this.service.formData.orderDate = this.datas.orderDate;
                this.service.formData.status = this.datas.status;
                this.service.formData.source = this.datas.source;
                this.service.formData.visaCountryID = this.datas.visaCountryID;
                this.service.formData.residenceCountryID = this.datas.residenceCountryID;
                this.service.formData.nationalityID = this.datas.nationalityID;
                this.service.formData.visaTypeID = this.datas.visaTypeID;
                this.service.formData.isAgreeTerms = this.datas.isAgreeTerms;
                this.service.formData.email = this.datas.email;
                this.service.formData.currencyID = this.datas.currencyID;
                this.service.formData.agentID = this.datas.agentID;
                this.service.formData.userID = this.datas.userID;
                this.service.formData.dialing = this.datas.dialing;
                this.service.formData.phoneNo = this.datas.phoneNo;
                this.service.formData.applicantRequesterName = this.datas.applicantRequesterName;
                this.service.formData.arrivalDate = this.datas.arrivalDate;
                this.service.formData.leaveDate = this.datas.leaveDate;
                this.service.formData.arrivalPort = this.datas.arrivalPort;
                this.service.formData.rejectReason = this.datas.rejectReason;
                this.service.formData.modificationRequestDetails = this.datas.modificationRequestDetails;
                this.service.formData.promotionCodeID = this.datas.promotionCodeID;
                this.service.formData.stayPeriod = this.datas.stayPeriod;
                this.service.formData.validFromDate = this.datas.validFromDate;
                this.service.formData.validToDate = this.datas.validToDate;
                this.service.formData.defaultLanguage = this.datas.defaultLanguage;
                this.service.formData.pricePerPerson = this.datas.pricePerPerson;
                this.service.formData.subTotalAmount = this.datas.subTotalAmount;
                this.service.formData.voucherAmount = this.datas.voucherAmount;
                this.service.formData.groupDiscountAmount = this.datas.groupDiscountAmount;
                this.service.formData.vat = this.datas.vat;
                this.service.formData.totalAmount = this.datas.totalAmount;
                this.service.formData.insuranceAmount = this.datas.insuranceAmount;
                this.service.formData.net = this.datas.net;
                this.service.formData.pricePerPersonCurr = this.datas.pricePerPersonCurr;
                this.service.formData.subTotalAmountCurr = this.datas.subTotalAmountCurr;
                this.service.formData.voucherAmountCurr = this.datas.voucherAmountCurr;
                this.service.formData.groupDiscountAmountCurr = this.datas.groupDiscountAmountCurr;
                this.service.formData.vatCurr = this.datas.vatCurr;
                this.service.formData.totalAmountCurr = this.datas.totalAmountCurr;
                this.service.formData.insuranceAmountCurr = this.datas.insuranceAmountCurr;
                this.service.formData.netCurr = this.datas.netCurr;
                this.service.formData.totalPaid = this.datas.totalPaid;
                this.service.formData.totalPaidCurr = this.datas.totalPaidCurr;
                this.service.formData.isRefundPaidBack = this.datas.isRefundPaidBack;
                this.service.formData.refundAmount = this.datas.refundAmount;
                this.service.formData.refundRemark = this.datas.refundRemark;
                this.service.formData.insuranceRemark = this.datas.insuranceRemark;
                this.service.formData.isInsurancePaidBack = this.datas.isInsurancePaidBack;
                this.service.formData.insuranceReturnAmount = this.datas.insuranceReturnAmount;


                this.itemDetails = this.service.VWformData.visaCountryNameEn + " / " + this.service.VWformData.visaTypeNameEn;

                this.FillCost();

            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

    FillCost() {

        var CurrencyRate = +this.localS.getItem("CurrencyRate");
        this.CurrencyName = this.localS.getItem("CurrencyName");

        this.service.getNewOrderCost(this.service.VWformData.orderID)
            .subscribe(res => {
                this.loader.close();

                var splitted = res.split("_", 7);
                this.price = +splitted[0];
                this.quantity = +splitted[1];
                this.insuranceAmount = +splitted[2];
                this.groupDiscountPercentage = +splitted[3];
                this.groupCostPerPerson= +splitted[4];

                //if (this.groupCostPerPerson != 0) {
                //    this.price = this.groupCostPerPerson;
                //}

                this.subtotal = this.price * this.quantity;

                if (this.groupCostPerPerson != 0) {
                    this.groupDiscount = this.subtotal - (this.groupCostPerPerson * this.quantity);
                }

                this.groupDiscount += (this.subtotal * (this.groupDiscountPercentage /100 ));



                this.total = (this.subtotal - this.groupDiscount);
                this.vat = (this.total * 0.05);
                    this.total = (this.total + this.vat);
                this.net = this.total + this.insuranceAmount;

                this.priceCurr = this.price * CurrencyRate;
                this.subtotalCurr = this.subtotal * CurrencyRate;
                this.insuranceAmountCurr = this.insuranceAmount * CurrencyRate;
                this.totalCurr = (this.total) * CurrencyRate;
                this.groupDiscountCurr = this.groupDiscount * CurrencyRate;
                this.vatCurr = this.vat * CurrencyRate;
                this.netCurr = ( this.net) * CurrencyRate;


            },
                err => {
                    this.loader.close(); console.log(err);
                });

    }



    onClickVoucher(voucherCode) {

        var CurrencyRate = +this.localS.getItem("CurrencyRate");
        this.CurrencyName = this.localS.getItem("CurrencyName");

        this.servVisaType.getCheckVoucher(this.service.VWformData.visaTypeID, voucherCode)
            .subscribe(res => {
                if (res == "0") {
                    this.toastr.warning('Not Available', '');
                }
                else {
                    var splitted = res.split("-", 2);
                    this.voucherAmount = ((+splitted[0] / 100) * this.subtotal);



                    //////////////////////////////////////////////////////
                   /* this.total = (this.subtotal - this.voucherAmount);*/
                    this.toastr.success('Added Successfully', '');
                    this.isDisabledVoucher = true;
                    this.promotionCodeID = +splitted[1];

                    //this.totalCurr = (this.subtotal - this.voucherAmount) * CurrencyRate;


                    //this.vat = (this.total * 0.05);
                    //this.net = this.total + this.vat;

                    //this.vatCurr = this.vat * CurrencyRate;
                    //this.netCurr = this.net * CurrencyRate;
                    //////////////////////////////////////////////////////


                    this.voucherAmountCurr = ((+splitted[0] / 100) * this.subtotal) * CurrencyRate;

                    this.subtotal = this.price * this.quantity;

                    if (this.groupCostPerPerson != 0) {
                        this.groupDiscount = this.subtotal - (this.groupCostPerPerson * this.quantity);
                    }

                    this.groupDiscount += (this.subtotal * (this.groupDiscountPercentage / 100));



                    this.total = (this.subtotal - this.groupDiscount - this.voucherAmount);
                    this.vat = (this.total * 0.05);
                    this.total = (this.total + this.vat);
                    this.net = this.total + this.insuranceAmount;

                    this.priceCurr = this.price * CurrencyRate;
                    this.subtotalCurr = this.subtotal * CurrencyRate;
                    this.insuranceAmountCurr = this.insuranceAmount * CurrencyRate;
                    this.totalCurr = (this.total) * CurrencyRate;
                    this.groupDiscountCurr = this.groupDiscount * CurrencyRate;
                    this.vatCurr = this.vat * CurrencyRate;
                    this.netCurr = (this.net) * CurrencyRate;
                }
            })
    }



    onClickPaymentBT() {
        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(ConfirmPaymentDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title}
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.updateOrder();
            })
    }


    updateOrder() {

        var CurrID = +this.localS.getItem("CurrencyID");

        this.service.formData.currencyID = CurrID;
        this.service.formData.promotionCodeID = this.promotionCodeID;

        this.service.formData.pricePerPerson = this.price;
        this.service.formData.subTotalAmount = this.subtotal;
        this.service.formData.voucherAmount = this.voucherAmount;
        this.service.formData.groupDiscountAmount = this.groupDiscount;
        this.service.formData.vat = this.vat;
        this.service.formData.totalAmount = this.total;
        this.service.formData.insuranceAmount = this.insuranceAmount;
        this.service.formData.net = this.net;


        this.service.formData.pricePerPersonCurr = this.priceCurr;
        this.service.formData.subTotalAmountCurr = this.subtotalCurr;
        this.service.formData.voucherAmountCurr = this.voucherAmountCurr;
        this.service.formData.groupDiscountAmountCurr = this.groupDiscountCurr;
        this.service.formData.vatCurr = this.vatCurr;
        this.service.formData.totalAmountCurr = this.totalCurr;
        this.service.formData.insuranceAmountCurr = this.datas.insuranceAmountCurr;
        this.service.formData.netCurr = this.netCurr;




        this.service.formData.status = "Not Paid";




        this.loader.open();
        this.service.putService().subscribe(
            res => {
                this.paymentGateway();
            },
            err => { console.log(err); }
        );



    }
    paymentGateway() {


        this.ServSavedOrder.updateOrderStatus(this.vtid, this.nid, this.vid, this.rid, this.oid, this.email, this.dates,0 ,'Not Paid');

        const formTag = this.createPayOnlineFormTag();
        $('body').append(formTag);
        $('#formPayOnline').submit();

    }


    private createPayOnlineFormTag() {
        var langu = 'En'
        if (this.SelectedLanguage == 'ar') {
            langu = 'Ar'
        }

        var paymentLink = environment.apiUrlPay;

        return '<form id="formPayOnline" method="POST" action="' + paymentLink +'">'
            + '<input type="hidden" name="ReferenceNo" value="' + this.service.formData.orderNo+'">'
            + '<input type="hidden" name="Currency" value="OMR">'
            + '<input type="hidden" name="Language" value="'+langu+'">'
            + '<input type="hidden" name="Amount" value="' + this.service.formData.net.toFixed(3) +'">'
            + '<input type="hidden" name="SourceCode" value="Visa.Hekaya">'
            + '<input type="hidden" name="PostJsonURL" value="'+ environment.apiURL + 'Orders/Voucher/PaymentTransaction">'
            + '<input type="hidden" name="PostSuccessURL" value="https://visa.hekaya.co/payment-result;oid=' + this.service.formData.orderID + '">'
            + '<input type="hidden" name="PostFaildURL" value="https://visa.hekaya.co/payment-result;oid=' + this.service.formData.orderID + '">'
            + '<input type="hidden" name="Field1" value="' + this.service.formData.orderID + '">'
            + ' <input type="hidden" name="Field2" value="Order Receipt">'
            + '<input type="hidden" name="Field3" value="' + this.service.formData.currencyID + '">'
            + '<input type="hidden" name="Field4" value="' + this.service.formData.netCurr + '">'
            + '<input type="hidden" name="Field5" value="0">'//AgentID
            + ' <input type="hidden" name="Field8" value="' + environment.apiURL + 'Manage/MGUser/Login">'
        
            + ' <input type="submit" value="Pay Now">'
            + '</form>';
    }

    checkMessage: string = "";
    datasA: any = {};
    gnCheckOrderValidity() {
        this.service.getCheckOrder(this.oid, this.SelectedLanguage)
            .subscribe(res => {
                this.checkMessage = res;


                this.loader.close();

                if (this.checkMessage == "success") {

                }
                else {


                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.datasA, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['home']);

                        })
                }

            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }


    viewInsuranceDialog() {

        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(InsuranceInfoDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, id: this.vtid }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
            });
    }
}



