export class VoucherModule {
    voucherID: number = 0;
    voucherNo: number = 0;
    source: string = '';
    sourceNo: number = 0;
    sourceID: number = 0;
    agentID: number = 0;
    date: Date = new Date();
    payingway: string = '';
    amount: number = 0;
    currencyID: number = 0;
    amountMainCurr: number = 0;
    trackId: string = '';
    transId: string = '';
    paymentId: string = '';
    custId: string = '';
    refNo: string = '';
    notes: string = '';
 }
