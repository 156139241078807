<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Contact_Us' | translate }}</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>{{'DropUsALine' | translate }}</h3>
                    <p>{{'Contact_TXT' | translate }}</p>
                    <form  novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
                        <div class="row">

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{'Name' | translate }} <span>*</span></label>
                                    <input type="text" name="name" id="name" #name="ngModel" [(ngModel)]="service.formData.name" class="form-control" placeholder="" required>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{'Email' | translate }} <span>*</span></label>
                                    <input type="email" name="email" id="email" #email="ngModel" [(ngModel)]="service.formData.email" class="form-control" placeholder="" required>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>{{'Phone_Number' | translate }} <span>*</span></label>
                                    <div class="input-group">

                                        <input type="text" required ng2TelInput #phone_number name="phone_number" class="form-control" [(ngModel)]="service.formData.phoneNo" (countryChange)="onCountryChange($event)" [(value)]="phone_number_standard" [ng2TelInputOptions]="telOptions" />

                                    </div>


                                    <!--<div class="input-group" dir="ltr">
                                        <div class="input-group-prepend" style="width: 20%">
                                            <input type="text" name="dialing" #dialing="ngModel" [(ngModel)]="service.formData.dialing" class="form-control" placeholder="" required style="background-color: #e9e8e8">
                                        </div>
                                        <input type="text" name="phone_number" id="phone_number" #enquiry_PhoneNumber="ngModel" [(ngModel)]="service.formData.phoneNo" class="form-control border" placeholder="" required>
                                    </div>-->
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>{{'Enquiry_Type' | translate }} <span>*</span></label>
                                    <mat-select required #categoryID="ngModel" name="categoryID" [(ngModel)]="service.formData.categoryID" class="form-control" style="padding-top:10px">
                                        

                                        <mat-option *ngFor="let option of categoryData" [value]="option.categoryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>{{'Your_Message' | translate }} <span>*</span></label>
                                    <textarea name="message" id="message" cols="30" rows="5"  #message="ngModel" [(ngModel)]="service.formData.message" class="form-control" placeholder="" required></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btnWhite" [disabled]="form.invalid">{{'Send_Message' | translate }}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="box_style_1">
                        <h3>{{'Address' | translate }}</h3>
                        <p>{{'Address_TXT2' | translate }}</p>

                        <hr>
                        <h4> {{'Help_Center' | translate }} <span><i class="fas fa-question"></i></span></h4>
                        <p> {{'Contact_Us_At' | translate }} </p><p><a href="#">visa@hekaya.co</a></p>
                        <hr>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>

<!--<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.759347356707!2d-73.78797548425361!3d41.50948959637902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd490255c9bfa7%3A0xfe099945f43b6e47!2sWonderland%20Dr%2C%20East%20Fishkill%2C%20NY%2012533%2C%20USA!5e0!3m2!1sen!2sbd!4v1587654262668!5m2!1sen!2sbd"></iframe>
</div>-->

