<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'FrequentlyAskedQuestion' | translate }}</h2>
            <p style="color:azure">{{'FAQ_TXT' | translate }}</p>
        </div>
    </div>
</div>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">

                <li style="margin-top:10px"><a href="#"><span>{{'GeneralInformation' | translate }}</span></a></li>
                <li style="margin-top:10px"><a href="#"><span>{{'VisaInformation' | translate }}</span></a></li>
                <li style="margin-top:10px"><a href="#"><span>{{'ApplyforVISAatHekaya' | translate }}</span></a></li>
                <li style="margin-top:10px"><a href="#"><span>{{'PaymentandVisaFees' | translate }}</span></a></li>
                <li style="margin-top:10px"><a href="#"><span>{{'ApplicationStatus' | translate }}</span></a></li>
                <li style="margin-top:10px"><a href="#"><span>{{'ContactHekayaTeam' | translate }}</span></a></li>
                <!--<li *ngFor="let item of datasource"> <a href="#"><i class='bx bx-flag'></i> <span>{{ item.nameEn }}</span></a></li>-->
            </ul>
            <div class="tab-content">

                <div class="tabs-item" *ngFor="let item of datasource;">

                    <div class="faq-accordion accordion" id="faqAccordion{{item.categoryID}}" *ngFor="let faqs of datasourceFAQ">

                        <div class="accordion-item" *ngIf="faqs.categoryID === item.categoryID">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapseOne{{faqs.faqid}}" aria-expanded="true" attr.aria-controls="collapseOne{{faqs.faqid}}">

                                    <div *ngIf="SelectedLanguage == 'ar' ;else other_content">
                                        {{ faqs.titleAr }}    
                                    </div>

                                    <ng-template #other_content>{{ faqs.titleEn }}</ng-template>

                                    <i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseOne{{faqs.faqid}}" class="accordion-collapse collapse" attr.data-bs-parent="#faqAccordion{{item.categoryID}}">
                                <div class="accordion-body">

                                    <div *ngIf="SelectedLanguage == 'ar' ;else other_content2">
                                        <div [innerHtml]="faqs.detailsAr"></div>

                                    </div>

                                    <ng-template #other_content2>
                                        <div [innerHtml]="faqs.detailsEn"></div>
                                       
                                    </ng-template>
                                   
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>



        </div>
    </div>
</section>




