<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Tracking_Application' | translate }}</h2>
        </div>
    </div>
</div>


<section class="contact-area ptb-70 bg-light">



    <div class="container">


        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h4>{{'Comments' | translate}}</h4>


                <mat-table [dataSource]="dataSourceOrderUserComment" matSort>

                    <ng-container matColumnDef="details">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Details' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="details"> {{row.details}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsOrderUserComment"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsOrderUserComment;">
                    </mat-row>

                    <ng-container matColumnDef="noData">
                        <mat-footer-cell *matFooterCellDef colspan="6">
                            {{'No_data.' | translate }}
                        </mat-footer-cell>
                    </ng-container>
                    <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSourceOrderUserComment!=null && dataSourceOrderUserComment.data.length==0)}">
                    </mat-footer-row>

                </mat-table>

                


            </div>
        </div>



        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Attachment' | translate}}</h3>


                <mat-table [dataSource]="dataSourceAttachment" matSort>

                    <ng-container matColumnDef="num">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Num.' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Name' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="firstName"> {{row.firstName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachmentTypeNameEn">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Document' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="attachmentTypeNameEn"> {{row.attachmentTypeNameEn}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">

                            <button mat-icon-button class="btn btn-primary" (click)="openAttachmentPopUp(row, false)" style="font-size:20px"><i class="bx bx-plus"></i></button>

                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;">
                    </mat-row>

                    <ng-container matColumnDef="noData">
                        <mat-footer-cell *matFooterCellDef colspan="6">
                            {{'No_data.' | translate }}
                        </mat-footer-cell>
                    </ng-container>


                </mat-table>

                <div>

                    <button class="default-btnBlack" *ngIf="isDone" style="width:150px" type="button" (click)="GoNextBT()">{{'Go_Next' | translate }}</button>
                </div>


            </div>
        </div>
    </div>

</section>

