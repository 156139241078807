import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { VideoComponent } from './components/common/video/video.component';
import { ProcessComponent } from './components/common/process/process.component';
import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { InstagramComponent } from './components/common/instagram/instagram.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { ContactComponent } from './components/appviews/multipage/contact/contact.component';
//import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { WebTermsComponent } from './components/appviews/multipage/web-terms/web-terms.component';
import { ServiceTermsComponent } from './components/appviews/multipage/service-terms/service-terms.component';
import { PrivacyTermsComponent } from './components/appviews/multipage/privacy-terms/privacy-terms.component';
import { FAQComponent } from './components/appviews/multipage/faq/faq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/appviews/home/home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as $ from 'jquery';
import { Ng2TelInputModule } from 'ng2-tel-input';




import { ErrorComponent } from './components/layouts/error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HomeSearchComponent } from './components/layouts/home-search/home-search.component';
import { Step1VisaTypeComponent } from './components/appviews/applicationForm/step1-visa-type/step1-visa-type.component';
import { ConfirmTermsDialogComponent } from './components/appviews/applicationForm/step1-visa-type/confirm-terms-dialog/confirm-terms-dialog.component';
import { Step2BasicDetailsComponent } from './components/appviews/applicationForm/step2-basic-details/step2-basic-details.component';
import { Step3PersonalDetailsComponent } from './components/appviews/applicationForm/step3-personal-details/step3-personal-details.component';
import { PersonalAttachmentDialogComponent } from './components/appviews/applicationForm/step4-attachment/personal-attachment-dialog/personal-attachment-dialog.component';
import { Step4AttachmentComponent } from './components/appviews/applicationForm/step4-attachment/step4-attachment.component';
import { Step5ReviewComponent } from './components/appviews/applicationForm/step5-review/step5-review.component';
import { Step6PaymentComponent } from './components/appviews/applicationForm/step6-payment/step6-payment.component';
import { AboutUsComponent } from './components/appviews/multipage/about-us/about-us.component';
import { ServicesComponent } from './components/appviews/multipage/services/services.component';
import { FailedDialogComponent } from './components/appviews/applicationForm/step1-visa-type/failed-dialog/failed-dialog.component';
import { ConfirmPaymentDialogComponent } from './components/appviews/applicationForm/step6-payment/confirm-payment-dialog/confirm-payment-dialog.component';
import { PaymentResultComponent } from './components/appviews/applicationForm/payment-result/payment-result.component';
import { TrackingComponent } from './components/appviews/tracking/tracking/tracking.component';
import { TrackingResultComponent } from './components/appviews/tracking/tracking-result/tracking-result.component';
import { NewAgentRequestComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request.component';
import { NewAgentRequestAttachmentComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request-attachment/new-agent-request-attachment.component';
import { NewAgentRequestAttachmentDialogComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request-attachment/new-agent-request-attachment-dialog/new-agent-request-attachment-dialog.component';
import { RequestResultComponent } from './components/appviews/applicationForm/new-agent-request/request-result/request-result.component';
import { InputInfoDialogComponent } from './components/appviews/applicationForm/step3-personal-details/input-info-dialog/input-info-dialog.component';
import { ReferBackAttachmentComponent } from './components/appviews/tracking/refer-back-attachment/refer-back-attachment.component';
import { DownloadVisaComponent } from './components/appviews/tracking/download-visa/download-visa.component';
import { AppLoaderService } from './components/common/app-loader/app-loader.service';
import { AppLoaderComponent } from './components/common/app-loader/app-loader.component';
import { AppConfirmService } from './components/common/app-confirm/app-confirm.service';
import { AppComfirmComponent } from './components/common/app-confirm/app-confirm.component';
import { OfferDialogComponent } from './components/appviews/applicationForm/step1-visa-type/offer-dialog/offer-dialog.component';
import { CompleteDraftDialogComponent } from './components/appviews/home/complete-draft-dialog/complete-draft-dialog.component';
import { CompletePaymentDialogComponent } from './components/appviews/home/complete-payment-dialog/complete-payment-dialog.component';
import { InsuranceInfoDialogComponent } from './components/appviews/applicationForm/step6-payment/insurance-info-dialog/insurance-info-dialog.component';
import { RestrictionDialogComponent } from './components/appviews/applicationForm/step1-visa-type/restriction-dialog/restriction-dialog.component';
import { InfoDialogComponent } from './components/appviews/applicationForm/step2-basic-details/info-dialog/info-dialog.component';
import { VisaInfoDialogComponent } from './components/appviews/applicationForm/step1-visa-type/visa-info-dialog/visa-info-dialog.component';
import { ApplicationStatusComponent } from './components/appviews/multipage/application-status/application-status.component';
import { UnderMaintenanceComponent } from './components/appviews/under-maintenance/under-maintenance.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}


@NgModule({
    declarations: [

        AppComponent,
        ErrorComponent,
        AppLoaderComponent,
        FooterComponent,
        AppDownloadComponent,
        FeedbackComponent,
        VideoComponent,
        ProcessComponent,
        HeaderOneComponent,
        PartnerComponent,
        InstagramComponent,
        FunfactsComponent,
        ContactComponent,
        WebTermsComponent,
        ServiceTermsComponent,
        PrivacyTermsComponent,
        FAQComponent,
        HomeComponent,
        HomeSearchComponent,
        Step1VisaTypeComponent,
        ConfirmTermsDialogComponent,
        Step2BasicDetailsComponent,
        Step3PersonalDetailsComponent,
        PersonalAttachmentDialogComponent,
        Step4AttachmentComponent,
        Step5ReviewComponent,
        Step6PaymentComponent,
        AboutUsComponent,
        ServicesComponent,
        FailedDialogComponent,
        ConfirmPaymentDialogComponent,
        PaymentResultComponent,
        TrackingComponent,
        TrackingResultComponent,
        NewAgentRequestComponent,
        NewAgentRequestAttachmentComponent,
        NewAgentRequestAttachmentDialogComponent,
        RequestResultComponent,
        InputInfoDialogComponent,
        ReferBackAttachmentComponent,
        DownloadVisaComponent,
        AppComfirmComponent,
        OfferDialogComponent,
        CompleteDraftDialogComponent,
        CompletePaymentDialogComponent,
        InsuranceInfoDialogComponent,
        RestrictionDialogComponent,
        InfoDialogComponent,
        VisaInfoDialogComponent,
        ApplicationStatusComponent,
        UnderMaintenanceComponent
    ],
    exports:
        [
            MatProgressSpinnerModule
        ],

    imports: [
        FormsModule, MatInputModule,
        Ng2TelInputModule,
        MatTableModule,
        MatTabsModule,
        MatIconModule,
        MatSnackBarModule,
        AppRoutingModule,
        HttpClientModule,
        ButtonsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        AccordionModule,
        AlertModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgxTypedJsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CarouselModule,
        CollapseModule,
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot(),
        CdkStepperModule,
        NgStepperModule,
        MatDialogModule
    ],
    providers: [BsDatepickerConfig, DatePipe,
        AppConfirmService,
        AppLoaderService],
    bootstrap: [AppComponent]
})
export class AppModule { }
