export class VisaTypeModule {
    visaTypeID: number = 0;
    visaCountryID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    parentID: number = 0;
    notes: string = '';
    isVisible: string = '';
    termsAr: string = '';
    termsEn: string = '';
    pricePerPerson: number = 0;
    defaultAgentPrice: number = 0;
    insuranceGroupID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    orderPeriod: number = 0;
    isJob: boolean = false;
    visaPeriod: number = 0;
    visaValidPeriod: number = 0;
    insuranceDescriptionAr?: string = '';
    insuranceDescriptionEn?: string = '';
    entryTypeAr?: string = '';
    entryTypeEn?: string = '';
    businessDays?: number = 0;
    extensionAr?: string = '';
    extensionEn?: string = '';
}
