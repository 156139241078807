
<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'New_Agent_Request' | translate }}</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-70 bg-light">

    <!--<div class="container">
        <div class="section-title">-->
    <!--<span class="sub-title">Discover</span>-->
    <!--<h3>Insert Basic Details</h3>
            <h2></h2>
        </div>
    </div>-->

    <div class="container">

        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <p><i class='bx bx-info-circle text-primary'></i> {{ 'New_Agent_RequestTXT' | translate  }}</p>
                

            </div>
        </div>

        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Please_Fill_Details' | translate }}</h3>

                <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
                    <div class="row">



                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Company_Name' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="companyName"
                                   #companyName="ngModel" [(ngModel)]="service.formData.companyName"
                                   required [class.invalid]="companyName.invalid && companyName.touched">
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Address' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="address"
                                   #address="ngModel" [(ngModel)]="service.formData.address"
                                   required [class.invalid]="address.invalid && address.touched">
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Phone_No' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="phoneNo"
                                   #phoneNo="ngModel" [(ngModel)]="service.formData.phoneNo"
                                   required [class.invalid]="phoneNo.invalid && phoneNo.touched">
                        </div>

                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Email' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="email"
                                   #email="ngModel" [(ngModel)]="service.formData.email"
                                   required [class.invalid]="email.invalid && email.touched">
                        </div>


                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'CR_No' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="crNo"
                                   #crNo="ngModel" [(ngModel)]="service.formData.crNo"
                                   [class.invalid]="crNo.invalid && crNo.touched">
                        </div>



                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Tourist_License_No' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="touristLicenseNo"
                                   #touristLicenseNo="ngModel" [(ngModel)]="service.formData.touristLicenseNo"
                                   [class.invalid]="touristLicenseNo.invalid && touristLicenseNo.touched">
                        </div>



                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Applicant_Name' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="applicantName"
                                   #applicantName="ngModel" [(ngModel)]="service.formData.applicantName"
                                   [class.invalid]="applicantName.invalid && applicantName.touched">
                        </div>



                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Job_Title' | translate }}</label>
                            <input matInput class="form-control form-control-lg" name="jobTitle"
                                   #jobTitle="ngModel" [(ngModel)]="service.formData.jobTitle"
                                   required [class.invalid]="jobTitle.invalid && jobTitle.touched">
                        </div>


                        <div class="col-lg-12 col-md-12">
                            <button class="default-btnWhite"  style="width:150px" [disabled]="form.invalid">{{"SAVE" | translate }}</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
