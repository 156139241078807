import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { VWOrderPersonAttachmentModule } from '../../../appshared/orders/order-person-attachment/order-person-attachment.module';
import { OrderPersonAttachmentService } from '../../../appshared/orders/order-person-attachment/order-person-attachment.service';
import { OrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { PersonalAttachmentDialogComponent } from '../../applicationForm/step4-attachment/personal-attachment-dialog/personal-attachment-dialog.component';
import { OrderUserCommentService } from '../../../appshared/orders/order-user-comment/order-user-comment.service';
import { OrderUserCommentModule } from '../../../appshared/orders/order-user-comment/order-user-comment.module';
 
@Component({
  selector: 'app-refer-back-attachment',
  templateUrl: './refer-back-attachment.component.html',
  styleUrls: ['./refer-back-attachment.component.scss']
})
export class ReferBackAttachmentComponent implements OnInit {
    constructor(public service: OrderService, public dialog: MatDialog, private route: ActivatedRoute, private servOrderUserComment: OrderUserCommentService, private servAttachment: OrderPersonAttachmentService, private toastr: ToastrService, private router: Router, private loader: AppLoaderService) { }

    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    ono: number = 0;
    email: string = "";
    oid: number = 0;
    public apiURL: string = "";
    isDone: boolean = false;

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.oid = +params['oid'];
            this.ono = +params['ono'];
            this.email = params['email'];
        });
        this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Order/';
        this.getAttachmentTable();
        this.getViewOrderUserComment();
    }

    public dataSourceOrderUserComment = new MatTableDataSource<OrderUserCommentModule>();
    displayedColumnsOrderUserComment = [  'details'];

    public dataSourceAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
    displayedColumnsAttachment = ['num', 'firstName' ,'attachmentTypeNameEn', 'actions'];

    getAttachmentTable() {
        this.loader.open();
        this.dataSourceAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
        this.servAttachment.getServiceVWOrderNoFile(this.oid, 'Refer Back')
            .subscribe(res => {
                this.dataSourceAttachment.data = res as VWOrderPersonAttachmentModule[];
                this.isDone = false;
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                 this.isDone = true; })

    }


    data: any = {};
    openAttachmentPopUp(data: any = {}, isNew?) {
        let title = isNew ? 'add' : 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(PersonalAttachmentDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, payload: data, lid: data.peronOrderID }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                if (isNew) {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');

                } else {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');
                }
            })
    }

    private datas: any;

    GoNextBT() {
        this.loader.open();
        this.service.getServiceId(this.oid)
            .subscribe(res => {
                this.datas = res as OrderModule[];
                this.service.formData = this.datas;

                this.service.formData.status = 'Paid';


                this.service.putService().subscribe(
                    res => {
                        this.router.navigate(['tracking-result', { ono: this.ono, email: this.email }]);
                    },
                    err => { console.log(err); }
                );

                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }



    ////////////////////////////////////////////////



    public getViewOrderUserComment = () => {
        this.dataSourceOrderUserComment.data = null;
        this.servOrderUserComment.getServiceOrderCommentType(this.oid,'Refer Back')
            .subscribe(res => {
                this.dataSourceOrderUserComment.data = res as OrderUserCommentModule[];
            })
    }

}
