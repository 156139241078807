<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">

                    <!--<div></div>-->
                    <h3>{{'About_HEKAYA' | translate }}</h3>

                    <div class="about-the-store">
                        <p>{{'About_HEKAYA_TXT' | translate }}</p>


                    </div>
                    <div class="logo text-center mt-3">
                        <a>
                            <img src="assets/img/logo_Color.png" alt="hekaya-logo" class="hekaya-logo">
                        </a>
                    </div>
                    <div class="mt-2"><p class="credit">{{'Payment_TXT' | translate }}</p></div>
                    <div class="logo"><img src="assets/img/card.png" alt="" class="credit-img"></div>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-4">
                    <h3>{{'Quick_Links' | translate }}</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/home" (click)="scrollToTop()">{{'Home' | translate }}</a></li>
                        <li><a routerLink="/tracking" (click)="scrollToTop()">{{'Track_Application' | translate }}</a></li>
                        <li><a routerLink="/new-agent-request" (click)="scrollToTop()">{{'Agent_Registration' | translate }}</a></li>
                        <li><a href="http://visacp.hekaya.co/" (click)="scrollToTop()">{{'Agent_Login' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{'Customer_Support' | translate }}</h3>

                    <ul class="customer-support">
                        <li><a routerLink="/about-us" (click)="scrollToTop()">{{'About_Us' | translate }}</a></li>
                        <li><a routerLink="/contact" (click)="scrollToTop()">{{'Contact_Us' | translate }}</a></li>
                        <li><a routerLink="/faq" (click)="scrollToTop()">{{'FAQ' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="about-the-store">

                        <h3>{{'Contact_Us' | translate }}</h3>
                        <ul class="footer-contact-info">
                            <li><i class="bx bx-map"></i><a target="_blank">{{'Address_TXT' | translate }}</a></li>
                            <!--<li><i class="bx bx-phone-call"></i><a href="tel:+968 95682966">+968 95682966</a></li>-->
                            <li><i class="bx bx-envelope"></i><a href="visa@hekaya.co">visa@hekaya.co</a></li>
                        </ul>
                    </div>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/Hekayaco-110105420624431/?modal=admin_todo_tour" target="_blank" class="d-block"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/Hekaya_Holidays" target="_blank" class="d-block"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/hekaya.co/" rel="https://www.instagram.com/" target="_blank" class="d-block"><i class="bx bxl-instagram"></i></a></li>
                    </ul>

                    <div class="logo text-center mt-3">
                        <a>
                            <img src="assets/img/logo_Color2.png" alt="hekaya-logo" class="hekaya-logo">
                        </a>
                    </div>
                    <div class="mt-2"><p class="credit">{{'Operated_by' | translate }}</p></div>

                </div>
            </div>
        </div>
        <div  class="footer-bottom-area">
        <div  class="row align-items-center">
            <div  class="col-lg-7 col-md-7" dir="ltr">
                <p>Copyright <i class="bx bx-copyright"></i>2023 <a href="#" target="_blank">HEKAYA</a> All rights reserved |  Powered By <a href="http://d-pioneers.com/" target="_blank">Digital Pioneers</a>.</p></div>
            <div  class="col-lg-5 col-md-5">
            <ul >
                <li><a routerLink="/web-terms" (click)="scrollToTop()">{{'Web_Use_Terms' | translate }}</a></li>
                <li><a routerLink="/privacy-terms" (click)="scrollToTop()">{{'Privacy_Policy' | translate }}</a></li>
                <li><a routerLink="/service-terms" (click)="scrollToTop()">{{'Services_Terms' | translate }}</a></li>
                </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

