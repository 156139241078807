export class OrderModule {

    orderID: number = 0;
    orderNo: number = 0;
    orderDate: Date = new Date();
    status: string = '';
    source: string = '';
    visaCountryID: number = 0;
    residenceCountryID: number = 0;
    nationalityID: number = 0;
    visaTypeID: number = 0;
    isAgreeTerms: boolean = false;
    email: string = '';
    currencyID: number = 0;
    agentID: number = 0;
    userID: number = 0;
    dialing: string = '';
    phoneNo: string = '';
    applicantRequesterName: string = '';
    arrivalDate: Date = new Date();
    leaveDate: Date = new Date();
    arrivalPort: string = '';
    rejectReason: string = '';
    modificationRequestDetails: string = '';
    promotionCodeID: number = 0;
    stayPeriod: number = 0;
    validFromDate: Date = new Date();
    validToDate: Date = new Date();
    defaultLanguage: string = '';
    pricePerPerson: number = 0;
    subTotalAmount: number = 0;
    voucherAmount: number = 0;
    groupDiscountAmount: number = 0;
    vat: number = 0;
    totalAmount: number = 0;
    insuranceAmount: number = 0;
    net: number = 0;
    pricePerPersonCurr: number = 0;
    subTotalAmountCurr: number = 0;
    voucherAmountCurr: number = 0;
    groupDiscountAmountCurr: number = 0;
    vatCurr: number = 0;
    totalAmountCurr: number = 0;
    insuranceAmountCurr: number = 0;
    netCurr: number = 0;
    totalPaid: number = 0;
    totalPaidCurr: number = 0;
    isRefundPaidBack: boolean = false;
    refundAmount: number = 0;
    refundRemark: string = '';
    insuranceRemark: string = '';
    isInsurancePaidBack: boolean = false;
    insuranceReturnAmount: number = 0;

    mgUserSellerID: number = 0;
}


export class VWOrderModule {
    orderID: number = 0;
    orderNo: number = 0;
    orderDate: Date = new Date();
    status: string = '';
    source: string = '';
    visaCountryID: number = 0;
    residenceCountryID: number = 0;
    nationalityID: number = 0;
    visaTypeID: number = 0;
    isAgreeTerms: boolean = false;
    email: string = '';
    currencyID: number = 0;
    agentID: number = 0;
    userID: number = 0;
    dialing: string = '';
    phoneNo: string = '';
    applicantRequesterName: string = '';
    arrivalDate: Date = new Date();
    leaveDate: Date = new Date();
    arrivalPort: string = '';
    rejectReason: string = '';
    modificationRequestDetails: string = '';
    promotionCodeID: number = 0;
    stayPeriod: number = 0;
    validFromDate: Date = new Date();
    validToDate: Date = new Date();
    defaultLanguage: string = '';
    pricePerPerson: number = 0;
    subTotalAmount: number = 0;
    voucherAmount: number = 0;
    groupDiscountAmount: number = 0;
    vat: number = 0;
    totalAmount: number = 0;
    insuranceAmount: number = 0;
    net: number = 0;
    pricePerPersonCurr: number = 0;
    subTotalAmountCurr: number = 0;
    voucherAmountCurr: number = 0;
    groupDiscountAmountCurr: number = 0;
    vatCurr: number = 0;
    totalAmountCurr: number = 0;
    insuranceAmountCurr: number = 0;
    netCurr: number = 0;
    totalPaid: number = 0;
    totalPaidCurr: number = 0;
    isRefundPaidBack: boolean = false;
    refundAmount: number = 0;
    refundRemark: string = '';
    insuranceRemark: string = '';
    isInsurancePaidBack: boolean = false;
    insuranceReturnAmount: number = 0;

    currencyNameAr: string = '';
    currencyNameEn: string = '';
    visaTypeNameAr: string = '';
    visaTypeNameEn: string = '';
    visaCountryNameAr: string = '';
    visaCountryNameEn: string = '';
    countryNameAr: string = '';
    countryNameEn: string = '';
    nationalityNameAr: string = '';
    nationalityNameEn: string = '';

    mgUserSellerID: number = 0;

}
