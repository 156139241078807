import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { ReligionModule } from './religion.module';

@Injectable({
  providedIn: 'root'
})
export class ReligionService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<ReligionModule[]> {
        return this.http.get<ReligionModule[]>(environment.apiURL + 'Master/Religion/');
    }


    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Master/Religion/' + id).toPromise();
    }

    formData: ReligionModule = new ReligionModule();
    list: ReligionModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Master/Religion/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Master/Religion/' + this.formData.religionID, this.formData);
    }
}
