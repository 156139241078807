<div class="text-center">
    <!--<h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6>-->
    <br />
    <div mat-dialog-content>

        <img class="" src="../../../../assets/img/logo_Color.png" />
        <br />
        <br />
        <mat-spinner [style.margin]="'auto'" [diameter]="40"></mat-spinner>
    </div>
</div>




   
