<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="2" dir="ltr" style="margin-left:-35px">
            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true"  [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>

    </div>
</div>




<section class="contact-area ptb-70 bg-light">

    <!--<div class="container">
        <div class="section-title">-->
    <!--<span class="sub-title">Discover</span>-->
    <!--<h3>Insert Basic Details</h3>
            <h2></h2>
        </div>
    </div>-->

    <div class="container">




        <div class="card recent-orders-box">
            <div class="card-body">

                <div class="blog-details-desc text-center">
                    <div class="article-content">

                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-globe'></i>
                                            <span>{{'Country' | translate }}</span>
                                            <a>{{ countryName }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>

                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-file-import'></i>
                                            <span>{{'Visa_Type' | translate }}</span>
                                            <a>{{ visaTypeName }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>

                                        <li>
                                            <i class='bx bx-calendar text-success'></i>
                                            <span>{{'Valid_from' | translate }}</span>
                                            <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>

                                        <li>
                                            <i class='bx bx-time-five'></i>
                                            <span>{{'Stay_days' | translate }}</span>
                                            <a>{{ stayDays }}</a>
                                            <br />
                                            <br />
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-calendar text-danger'></i>
                                            <span>{{'Valid_to' | translate }}</span>
                                            <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-4 col-6">
                                <div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-file'></i>
                                            <span>{{'Order_No' | translate }}</span>
                                            <a>{{ orderNo }}</a>
                                            <br />
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>



                                <!--<div class="entry-meta">
                                    <ul>
                                        <li>
                                            <i class='bx bx-globe'></i>
                                            <span>{{'Country' | translate }}</span>
                                            <a>{{ countryName }}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar text-success'></i>
                                            <span>{{'Valid_from' | translate }}</span>
                                            <a>{{fromDate | date  : 'dd/MM/yyyy'}}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-time-five'></i>
                                            <span>{{'Stay_days' | translate }}</span>
                                            <a>{{ stayDays }}</a>
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar text-danger'></i>
                                            <span>{{'Valid_to' | translate }}</span>
                                            <a>{{toDate | date  : 'dd/MM/yyyy'}}</a>
                                        </li>
                                        <li>
                                            <i class='bx bxs-file'></i>
                                            <span>{{'Order_No' | translate }}</span>
                                            <a>{{ orderNo }}</a>
                                        </li>
                                    </ul>
                                </div>-->
                            </div>
                        </div>

            </div>
        </div>


        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Personal_Details' | translate }}</h3>

                <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
                    <div class="row">

                        <div class="col-lg-6 col-md-12" [hidden]="!firstNameActive">
                            <div class="form-group">
                                <label>{{'First_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="firstName" #firstName="ngModel" [(ngModel)]="service.formData.firstName" [required]="firstNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FirstName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!middleNameActive">
                            <div class="form-group">
                                <label>{{'Middle_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="middleName" #middleName="ngModel" [(ngModel)]="service.formData.middleName" [required]="middleNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MiddleName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!lastNameActive">
                            <div class="form-group">
                                <label>{{'Last_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="lastName" #lastName="ngModel" [(ngModel)]="service.formData.lastName" [required]="lastNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('LastName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!fullNameActive">
                            <div class="form-group">
                                <label>{{'Full_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="fullName" #fullName="ngModel" [(ngModel)]="service.formData.fullName" [required]="fullNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FullName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!genderActive">
                            <div class="form-group">
                                <label>{{'Gender' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #gender="ngModel" name="gender" [(ngModel)]="service.formData.gender" style="padding-top:10px" class="form-control form-control-lg" [required]="genderActive">
                                        <mat-option value="Male">{{'Male' | translate }}</mat-option>
                                        <mat-option value="Female">{{'Female' | translate }}</mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('Gender')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!marriedStatusActive">
                            <div class="form-group">


                                <label>{{'Married_Status' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #marriedStatus="ngModel" name="marriedStatus" [(ngModel)]="service.formData.marriedStatus" style="padding-top:10px" class="form-control form-control-lg" [required]="marriedStatusActive">


                                        <mat-option value="Single">{{'Single' | translate }}</mat-option>
                                        <mat-option value="Married">{{'Married' | translate }}</mat-option>
                                        <mat-option value="Divorced">{{'Divorced' | translate }}</mat-option>
                                        <mat-option value="Widowed">{{'Widowed' | translate }}</mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MarriedStatus')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!dobActive">
                            <div class="form-group">
                                <label>{{'DOB' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="dob" #dob="ngModel" [(ngModel)]="service.formData.dob" [required]="dobActive"
                                           bsDatepicker [bsValue]="bsValue" [daysDisabled]="[6,0]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('DOB')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!birthCountryIDActive">
                            <div class="form-group">
                                <label>{{'Birth_Country' | translate }}</label>


                                <div class="input-group mb-3">
                                    <mat-select #birthCountryID="ngModel" name="birthCountryID" [(ngModel)]="service.formData.birthCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="birthCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('BirthCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>



                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!birthPlaceActive">
                            <div class="form-group">
                                <label>{{'Birth_Place' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="birthPlace" #birthPlace="ngModel" [(ngModel)]="service.formData.birthPlace" [required]="birthPlaceActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('BirthPlace')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!religionIDActive">

                            <div class="form-group">
                                <label>{{'Religion' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #religionID="ngModel" name="religionID" [(ngModel)]="service.formData.religionID" placeholder="{{'Select_Religion' | translate }}" class="form-control border" style="padding-top:10px" [required]="religionIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="religionFilterCtrl" placeholderLabel="{{'Select_Religion' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredReligions | async" [value]="option.religionID">

                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('Religion')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!spouseNameActive">
                            <div class="form-group">
                                <label>{{'Spouse_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="spouseName" #spouseName="ngModel" [(ngModel)]="service.formData.spouseName" [required]="spouseNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('SpouseName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!firstLanguageIDActive">
                            <div class="form-group">
                                <label>{{'First_Language' | translate }}</label>


                                <div class="input-group mb-3">
                                    <mat-select #firstLanguageID="ngModel" name="firstLanguageID" [(ngModel)]="service.formData.firstLanguageID" placeholder="{{'Select_Language' | translate }}" class="form-control border" style="padding-top:10px" [required]="firstLanguageIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="languageFilterCtrl" placeholderLabel="{{'Select_Language' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredlanguages | async" [value]="option.languageID">

                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FirstLanguage')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!secondLanguageIDActive">
                            <div class="form-group">
                                <label>{{'Second_Language' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #secondLanguageID="ngModel" name="secondLanguageID" [(ngModel)]="service.formData.secondLanguageID" placeholder="{{'Select_Language' | translate }}" class="form-control border" style="padding-top:10px" [required]="secondLanguageIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="languageFilterCtrl" placeholderLabel="{{'Select_Language' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredlanguages | async" [value]="option.languageID">

                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('SecondLanguage')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!motherNameActive">
                            <div class="form-group">
                                <label>{{'Mother_Name' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="motherName" #motherName="ngModel" [(ngModel)]="service.formData.motherName" [required]="motherNameActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MotherName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!motherNationalityIDActive">
                            <div class="form-group">
                                <label>{{'Mother_Nationality' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #motherNationalityID="ngModel" name="motherNationalityID" [(ngModel)]="service.formData.motherNationalityID" placeholder="{{'Select_Nationality' | translate }}" class="form-control border" style="padding-top:10px" [required]="motherNationalityIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MotherNationality')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!motherPerviousNationalityIDActive">
                            <div class="form-group">
                                <label>{{'Mother_Pervious_Nationality' | translate }}</label>


                                <div class="input-group mb-3">
                                    <mat-select #motherPerviousNationalityID="ngModel" name="motherPerviousNationalityID" [(ngModel)]="service.formData.motherPerviousNationalityID" placeholder="{{'Select_Nationality' | translate }}" class="form-control border" style="padding-top:10px" [required]="motherPerviousNationalityIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MotherPerviousNationality')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!motherBirthPlaceActive">
                            <div class="form-group">
                                <label>{{'Mother_Birth_Place' | translate }}</label>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="motherBirthPlace" #motherBirthPlace="ngModel" [(ngModel)]="service.formData.motherBirthPlace" [required]="motherBirthPlaceActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MotherBirthPlace')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!motherBirthCountryIDActive">
                            <div class="form-group">
                                <label>{{'Mother_Birth_Country' | translate }}</label>


                                <div class="input-group mb-3">
                                    <mat-select #motherBirthCountryID="ngModel" name="motherBirthCountryID" [(ngModel)]="service.formData.motherBirthCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="motherBirthCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('MotherBirthCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!fatherNameActive">
                            <div class="form-group">
                                <label>{{'Father_Name' | translate }}</label>

                                <div class="input-group mb-3">

                                    <input type="text" class="form-control border" value="" name="fatherName" #fatherName="ngModel" [(ngModel)]="service.formData.fatherName" [required]="fatherNameActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FatherName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!fatherNationalityIDActive">
                            <div class="form-group">
                                <label>{{'Father_Nationality' | translate }}</label>


                                <div class="input-group mb-3">
                                    <mat-select #fatherNationalityID="ngModel" name="fatherNationalityID" [(ngModel)]="service.formData.fatherNationalityID" placeholder="{{'Select_Nationality' | translate }}" class="form-control border" style="padding-top:10px" [required]="fatherNationalityIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FatherNationality')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!fatherPerviousNationalityIDActive">
                            <div class="form-group">
                                <label>{{'Father_Pervious_Nationality' | translate }}</label>



                                <div class="input-group mb-3">
                                    <mat-select #fatherPerviousNationalityID="ngModel" name="fatherPerviousNationalityID" [(ngModel)]="service.formData.fatherPerviousNationalityID" placeholder="{{'Select_Nationality' | translate }}" class="form-control border" style="padding-top:10px" [required]="fatherPerviousNationalityIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FatherPerviousNationality')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!fatherBirthPlaceActive">
                            <div class="form-group">
                                <label>{{'Father_Birth_Place' | translate }}</label>

                                <div class="input-group mb-3">

                                    <input type="text" class="form-control border" value="" name="fatherBirthPlace" #fatherBirthPlace="ngModel" [(ngModel)]="service.formData.fatherBirthPlace" [required]="fatherBirthPlaceActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FatherBirthPlace')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!fatherBirthCountryIDActive">
                            <div class="form-group">
                                <label>{{'Father_Birth_Country' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #fatherBirthCountryID="ngModel" name="fatherBirthCountryID" [(ngModel)]="service.formData.fatherBirthCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="fatherBirthCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('FatherBirthCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!jobTitleActive">
                            <div class="form-group">
                                <label>{{'Job_Title' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="jobTitle" #jobTitle="ngModel" [(ngModel)]="service.formData.jobTitle" [required]="jobTitleActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('JobTitle')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!jobCategoryIDActive">
                            <div class="form-group">
                                <label>{{'Job_Category' | translate }}</label>

                                <div class="input-group mb-3">
                                    <mat-select #jobCategoryID="ngModel" name="jobCategoryID" [(ngModel)]="service.formData.jobCategoryID" placeholder="{{'Select_Job_Category' | translate }}" class="form-control border" style="padding-top:10px" [required]="jobCategoryIDActive">


                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="jobCategoryFilterCtrl" placeholderLabel="{{'Select_Job_Category' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let jobCategory of filteredJobCategories | async" [value]="jobCategory.jobCategoryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ jobCategory.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ jobCategory.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('JobCategory')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!(service.formData.jobCategoryID == 124)">
                            <div class="form-group">
                                <label>{{'Other_Job' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="jobCategoryOther" #jobCategoryOther="ngModel" [(ngModel)]="service.formData.jobCategoryOther" [required]="jobCategoryIDActive && service.formData.jobCategoryID == 124">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('JobCategoryOther')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!ordinaryDocumentIDActive">
                            <div class="form-group">
                                <label>{{'Ordinary_Document' | translate }}</label>
                                <div class="input-group mb-3">
                                    <mat-select #ordinaryDocumentID="ngModel" name="ordinaryDocumentID" [(ngModel)]="service.formData.ordinaryDocumentID" style="padding-top:10px" class="form-control form-control-lg" [required]="ordinaryDocumentIDActive">



                                        <mat-option *ngFor="let option of ordinaryDocumentData" [value]="option.ordinaryDocumentID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other1_contentAr  ; else other1_contentEn">
                                            </ng-template>
                                            <ng-template #other1_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other1_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('OrdinaryDocument')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!employerActive">
                            <div class="form-group">
                                <label>{{'Employer' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="employer" #employer="ngModel" [(ngModel)]="service.formData.employer" [required]="employerActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('Employer')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!arrivalAirpotPortNameActive">
                            <div class="form-group">
                                <label>{{'Arrival_Airpot_Port_Name' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="arrivalAirpotPortName" #arrivalAirpotPortName="ngModel" [(ngModel)]="service.formData.arrivalAirpotPortName" [required]="arrivalAirpotPortNameActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('ArrivalAirpotPortName')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!passportNoActive">
                            <div class="form-group">
                                <label>{{'Passport_No' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="passportNo" #passportNo="ngModel" [(ngModel)]="service.formData.passportNo" [required]="passportNoActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PassportNo')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div class="col-lg-6 col-md-12" [hidden]="!passportIssueDateActive">
                            <div class="form-group">
                                <label>{{'Passport_Issue_Date' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="passportIssueDate" #passportIssueDate="ngModel" [(ngModel)]="service.formData.passportIssueDate" [required]="passportIssueDateActive"
                                           bsDatepicker [bsValue]="bsValue" [daysDisabled]="[6,0]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PassportIssueDate')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!passportExpiryDateActive">
                            <div class="form-group">
                                <label>{{'Passport_Expiry_Date' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" name="passportExpiryDate" #passportExpiryDate [required]="passportExpiryDateActive"
                                           bsDatepicker [(bsValue)]="service.formData.passportExpiryDate"
                                           [minDate]="minDate" [daysDisabled]="[6,0]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PassportExpiryDate')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div class="col-lg-6 col-md-12" [hidden]="!passportIssueCountryIDActive">
                            <div class="form-group">
                                <label>{{'Passport_Issue_Country' | translate }}</label>
                                <div class="input-group mb-3">
                                    <mat-select #passportIssueCountryID="ngModel" name="passportIssueCountryID" [(ngModel)]="service.formData.passportIssueCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="passportIssueCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PassportIssueCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!phoneNumberActive">
                            <div class="form-group">
                                <label>{{'Phone_Number' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="phoneNumber" #phoneNumber="ngModel" [(ngModel)]="service.formData.phoneNumber" [required]="phoneNumberActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PhoneNumber')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!emailActive">
                            <div class="form-group">
                                <label>{{'Email' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="email" #email="ngModel" [(ngModel)]="service.formData.email" [required]="emailActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('Email')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!posteCodeActive">
                            <div class="form-group">
                                <label>{{'Poste_Code' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="posteCode" #posteCode="ngModel" [(ngModel)]="service.formData.posteCode" [required]="posteCodeActive">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PosteCode')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!previousNationalityIDActive">
                            <div class="form-group">
                                <label>{{'Previous_Nationality' | translate }}</label>
                                <div class="input-group mb-3">
                                    <mat-select #previousNationalityID="ngModel" name="previousNationalityID" [(ngModel)]="service.formData.previousNationalityID" placeholder="{{'Select_Nationality' | translate }}" class="form-control border" style="padding-top:10px" [required]="previousNationalityIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PreviousNationality')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!isNameChangedActive">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <mat-slide-toggle #isNameChanged="ngModel" name="isNameChanged" [(ngModel)]="service.formData.isNameChanged" [checked]="service.formData.isNameChanged">{{"Is_Name_Changed" | translate }} &nbsp; &nbsp;</mat-slide-toggle>

                                    <div class="input-group-sm">
                                        <a href="javascript:void(0);" (click)="viewDialog('IsNameChanged')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!visibleIdentificationMarkActive">
                            <div class="form-group">
                                <label>{{'Visible_Identification_Mark' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="visibleIdentificationMark" #visibleIdentificationMark="ngModel" [(ngModel)]="service.formData.visibleIdentificationMark" [required]="visibleIdentificationMarkActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('VisibleIdentificationMark')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!educationQualificationActive">
                            <div class="form-group">
                                <label>{{'Education_Qualification' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="educationQualification" #educationQualification="ngModel" [(ngModel)]="service.formData.educationQualification" [required]="educationQualificationActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('EducationQualification')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!acquireNationalityByActive">
                            <div class="form-group">
                                <label>{{'Acquire_Nationality_By' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="acquireNationalityBy" #acquireNationalityBy="ngModel" [(ngModel)]="service.formData.acquireNationalityBy" [required]="acquireNationalityByActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('AcquireNationalityBy')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!isOtherValidPassportICActive">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <mat-slide-toggle #isOtherValidPassportIC="ngModel" name="isOtherValidPassportIC" [(ngModel)]="service.formData.isOtherValidPassportIC" [checked]="service.formData.isOtherValidPassportIC">{{"Is_Other_Valid_Passport_IC" | translate }} &nbsp; &nbsp;</mat-slide-toggle>

                                    <div class="input-group-sm">
                                        <a href="javascript:void(0);" (click)="viewDialog('IsOtherValidPassportIC')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>




                        <div class="col-lg-6 col-md-12" [hidden]="!otherPassportIssueCountryIDActive">
                            <div class="form-group">
                                <label>{{'Other_Passport_Issue_Country' | translate }}</label>
                                <div class="input-group mb-3">
                                    <mat-select #otherPassportIssueCountryID="ngModel" name="otherPassportIssueCountryID" [(ngModel)]="service.formData.otherPassportIssueCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="otherPassportIssueCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('OtherPassportIssueCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!visitPlace1Active">
                            <div class="form-group">
                                <label>{{'Visit_Place_1' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="visitPlace1" #visitPlace1="ngModel" [(ngModel)]="service.formData.visitPlace1" [required]="visitPlace1Active">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('VisitPlace1')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!visitPlace2Active">
                            <div class="form-group">
                                <label>{{'Visit_Place_2' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="visitPlace2" #visitPlace2="ngModel" [(ngModel)]="service.formData.visitPlace2" [required]="visitPlace2Active">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('VisitPlace2')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!isVisitBeforeActive">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <mat-slide-toggle #isVisitBefore="ngModel" name="isVisitBefore" [(ngModel)]="service.formData.isVisitBefore" [checked]="service.formData.isVisitBefore">{{"Is_Visit_Before" | translate }} &nbsp; &nbsp;</mat-slide-toggle>

                                    <div class="input-group-sm">
                                        <a href="javascript:void(0);" (click)="viewDialog('IsVisitBefore')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-lg-6 col-md-12" [hidden]="!visitedPlaceBeforeActive">
                            <div class="form-group">
                                <label>{{'Visited_Place_Before' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="visitedPlaceBefore" #visitedPlaceBefore="ngModel" [(ngModel)]="service.formData.visitedPlaceBefore" [required]="visitedPlaceBeforeActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('VisitedPlaceBefore')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!cityVisitedBeforeActive">
                            <div class="form-group">
                                <label>{{'City_Visited_Before' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="cityVisitedBefore" #cityVisitedBefore="ngModel" [(ngModel)]="service.formData.cityVisitedBefore" [required]="cityVisitedBeforeActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('CityVisitedBefore')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!lastVisaNoActive">
                            <div class="form-group">
                                <label>{{'Last_Visa_No' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="lastVisaNo" #lastVisaNo="ngModel" [(ngModel)]="service.formData.lastVisaNo" [required]="lastVisaNoActive" [disabled]="!service.formData.isVisitBefore">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('LastVisaNo')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!lastVisaTypeActive">
                            <div class="form-group">
                                <label>{{'Last_Visa_Type' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="lastVisaType" #lastVisaType="ngModel" [(ngModel)]="service.formData.lastVisaType" [required]="lastVisaTypeActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('LastVisaType')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!lastIssuePlaceActive">
                            <div class="form-group">
                                <label>{{'Last_Issue_Place' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="lastIssuePlace" #lastIssuePlace="ngModel" [(ngModel)]="service.formData.lastIssuePlace" [required]="lastIssuePlaceActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('LastIssuePlace')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!lastIssueDateActive">
                            <div class="form-group">
                                <label>{{'Last_Issue_Date' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="lastIssueDate" #lastIssueDate="ngModel" [(ngModel)]="service.formData.lastIssueDate" [required]="lastIssueDateActive"
                                           bsDatepicker [bsValue]="bsValue" [daysDisabled]="[6,0]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('LastIssueDate')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentHouseNoStreetActive">
                            <div class="form-group">
                                <label>{{'Present_House_No_Street' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentHouseNoStreet" #presentHouseNoStreet="ngModel" [(ngModel)]="service.formData.presentHouseNoStreet" [required]="presentHouseNoStreetActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentHouseNoStreet')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12" [hidden]="!presentCityActive">
                            <div class="form-group">
                                <label>{{'Present_City' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentCity" #presentCity="ngModel" [(ngModel)]="service.formData.presentCity" [required]="presentCityActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentCity')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentCountryIDActive">
                            <div class="form-group">
                                <label>{{'Present_Country' | translate }}</label>
                                <div class="input-group mb-3">
                                    <mat-select #presentCountryID="ngModel" name="presentCountryID" [(ngModel)]="service.formData.presentCountryID" placeholder="{{'Select_Country' | translate }}" class="form-control border" style="padding-top:10px" [required]="presentCountryIDActive">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>


                                        <mat-option *ngFor="let option of filteredCountries | async" [value]="option.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ option.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentCountry')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentStateActive">
                            <div class="form-group">
                                <label>{{'Present_State' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentState" #presentState="ngModel" [(ngModel)]="service.formData.presentState" [required]="presentStateActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentState')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentPostalCodeActive">
                            <div class="form-group">
                                <label>{{'Present_Postal_Code' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentPostalCode" #presentPostalCode="ngModel" [(ngModel)]="service.formData.presentPostalCode" [required]="presentPostalCodeActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentPostalCode')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentPhoneNoActive">
                            <div class="form-group">
                                <label>{{'Present_Phone_No' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentPhoneNo" #presentPhoneNo="ngModel" [(ngModel)]="service.formData.presentPhoneNo" [required]="presentPhoneNoActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentPhoneNo')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentMobileNoActive">
                            <div class="form-group">
                                <label>{{'Present_Mobile_No' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentMobileNo" #presentMobileNo="ngModel" [(ngModel)]="service.formData.presentMobileNo" [required]="presentMobileNoActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentMobileNo')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!presentEmailActive">
                            <div class="form-group">
                                <label>{{'Present_Email' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="presentEmail" #presentEmail="ngModel" [(ngModel)]="service.formData.presentEmail" [required]="presentEmailActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PresentEmail')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!permanentHouseNoStreetActive">
                            <div class="form-group">
                                <label>{{'Permanent_House_No_Street' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="permanentHouseNoStreet" #permanentHouseNoStreet="ngModel" [(ngModel)]="service.formData.permanentHouseNoStreet" [required]="permanentHouseNoStreetActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PermanentHouseNoStreet')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!permanentCityActive">
                            <div class="form-group">
                                <label>{{'Permanent_City' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="permanentCity" #permanentCity="ngModel" [(ngModel)]="service.formData.permanentCity" [required]="permanentCityActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PermanentCity')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12" [hidden]="!permanentStateActive">
                            <div class="form-group">
                                <label>{{'Permanent_State' | translate }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control border" value="" name="permanentState" #permanentState="ngModel" [(ngModel)]="service.formData.permanentState" [required]="permanentStateActive">

                                    <div class="input-group-append">
                                        <a href="javascript:void(0);" (click)="viewDialog('PermanentState')" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="listing-edit-delete-option">
                        <div class="d-flex justify-content-between align-items-center">
                            <button class="default-btnBlack"  style="width:150px;" type="submit" (click)="CancelBT()"> {{"Cancel" | translate }}</button>
                            <button class="default-btnWhite"  style="width:150px" [disabled]="form.invalid">  {{"Save_Next" | translate }}</button>

                        </div>
                    </div>


                </form>
            </div>
        </div>
    </div>

</section>
