import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyModule } from '../../appshared/masters/currency/currency.module';
import { CurrencyService } from '../../appshared/masters/currency/currency.service';
import { AppLoaderService } from '../../common/app-loader/app-loader.service';
import { LocalStoreService } from '../../common/local-store.service';
import { SessionStoreService } from '../../common/session-store.service';
import { StyleDirectionService } from '../style-direction/style-direction.service';

@Component({
    selector: 'app-header-one',
    templateUrl: './header-one.component.html',
    styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
    //    public availableLangs = [{
    //    name: 'EN',
    //    code: 'en',
    //    flag: 'flag-icon-us'
    //}, {
    //    name: 'AR',
    //    code: 'ar',
    //    flag: 'flag-icon-om'
    //    }];
    CurrentLangSrc: string = "assets/img/us-flag.jpg";
    CurrentLangName: string = "Eng";
    CurrentLangCode: string = "en";

    SecondLangSrc: string = "assets/img/arab-flag.jpg";
    SecondLangName: string = "عربي";
    SecondLangCode: string = "ar";
    currentLang: string = "en";


    CurrentCurrencyName: string = "OMR";
    CurrentCurrencyCode: string = "1";

    SecondCurrencyName: string = "$";
    SecondCurrencyCode: string = "2";
    currentCurrency: string = "1";

    constructor(
        private localS: LocalStoreService, public translate: TranslateService, private styleDir: StyleDirectionService, private service: CurrencyService,  private loader: AppLoaderService) { }

    ngOnInit(): void {
        if (this.localS.getItem('Lang') == 'ar') {
            this.currentLang = "ar";
        }

        if (this.currentLang !== 'ar') {

            this.CurrentLangSrc = "assets/img/us-flag.jpg";
            this.CurrentLangName = "Eng";
            this.CurrentLangCode = "en";

            this.SecondLangSrc = "assets/img/arab-flag.jpg";
            this.SecondLangName = "عربي";
            this.SecondLangCode = "ar";
        }
        else if (this.currentLang === 'ar') {
            this.SecondLangSrc = "assets/img/us-flag.jpg";
            this.SecondLangName = "Eng";
            this.SecondLangCode = "en";

            this.CurrentLangSrc = "assets/img/arab-flag.jpg";
            this.CurrentLangName = "عربي";
            this.CurrentLangCode = "ar";
        }
        var curID = this.localS.getItem('CurrencyID');
        if (curID == null) {

            this.currentCurrency = "2";
            this.setCurrency(false);
            this.toggleClassCurr();
        }
        else {
            this.currentCurrency = curID;
            if (this.currentCurrency == '1') {

                this.CurrentCurrencyName = "OMR";
                this.CurrentCurrencyCode = "1";

                this.SecondCurrencyName = "$";
                this.SecondCurrencyCode = "2";

            }
            else {

                this.CurrentCurrencyName = "$";
                this.CurrentCurrencyCode = "2";

                this.SecondCurrencyName = "OMR";
                this.SecondCurrencyCode = "1";
            }
        }

        //if (this.localS.getItem('CurrencyID') == "2") {
        //    this.currentCurrency = "1";
        //}
        //else {
        //    this.currentCurrency = "2";
        //}
        //this.setCurrency(false);
    }

    classApplied = false;
    classAppliedCurr = false;

    toggleClassLang() {
        this.classApplied = !this.classApplied;
    }

    toggleClassCurr() {
        this.classAppliedCurr = !this.classAppliedCurr;
    }

    private datas: any;
    setCurrency(isRefresh: boolean) {
 


        if (this.currentCurrency !== '1') {

            this.CurrentCurrencyName = "OMR";
            this.CurrentCurrencyCode = "1";

            this.SecondCurrencyName = "$";
            this.SecondCurrencyCode = "2";

        }
        else
        {

            this.CurrentCurrencyName = "$";
            this.CurrentCurrencyCode = "2";

            this.SecondCurrencyName = "OMR";
            this.SecondCurrencyCode = "1";
        }

        if (this.currentCurrency === "2") {
            this.currentCurrency = "1";

            //Save
            this.localS.setItem('CurrencyID', this.currentCurrency);
            this.localS.setItem('CurrencyRate', "1");
            this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
            this.toggleClassCurr();
            if (isRefresh) {
                window.location.reload();
            }
        }
        else {
            this.currentCurrency = "2";

            this.loader.open();
            this.service.getServiceId(2)
                .subscribe(res => {
                    this.datas = res as CurrencyModule[];

                    this.service.formData.currencyID = this.datas.currencyID;
                    this.service.formData.currFormat = this.datas.currFormat;
                    this.service.formData.isVisible = this.datas.isVisible;
                    this.service.formData.nameAr = this.datas.nameAr;
                    this.service.formData.nameEn = this.datas.nameEn;
                    this.service.formData.notes = this.datas.notes;
                    this.service.formData.rate = this.datas.rate;


                    this.localS.setItem('CurrencyID', this.currentCurrency);
                    this.localS.setItem('CurrencyRate', this.service.formData.rate);
                    this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
                    this.toggleClassCurr();
                    if (isRefresh) {
                        window.location.reload();
                    }
                    this.loader.close();
                },
                    err => {
                        this.loader.close(); console.log(err);


                        this.localS.setItem('CurrencyID', this.currentCurrency);
                        this.localS.setItem('CurrencyRate', "0.385");
                        this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
                        this.toggleClassCurr();
                        if (isRefresh) {
                            window.location.reload();
                        }
                    });

            //Save

        }
     

        //let dir = 'ltr';
        //if (this.currentLang.code == 'ar') {
        //    dir = 'rtl';

        //}
        //this.layout.publishLayoutChange({ dir: dir });
        //    localStorage.setItem('lang', language);
    }


    setLang() {
        if (this.currentLang === "ar") {
            this.currentLang = "en";
           // this.styleDir.setDirMode(false);
        }
        else {
            this.currentLang = "ar";
        
        }

        //this.translate.use(this.currentLang);

        //if (this.currentLang !== 'ar' && document.getElementsByTagName('html')[0].hasAttribute('dir')) {
        //    document.getElementsByTagName('html')[0].removeAttribute('dir');
        //} else if (this.currentLang === 'ar' && !document.getElementsByTagName('html')[0].hasAttribute('dir')) {
        //    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        //}

        //if (this.currentLang !== 'ar') {

        //    this.CurrentLangSrc = "assets/img/us-flag.jpg";
        //    this.CurrentLangName= "Eng";
        //    this.CurrentLangCode= "en";

        //    this.SecondLangSrc = "assets/img/arab-flag.jpg";
        //    this.SecondLangName= "عربي";
        //    this.SecondLangCode= "ar";
        //}
        //else if (this.currentLang === 'ar') {
        //    this.SecondLangSrc= "assets/img/us-flag.jpg";
        //    this.SecondLangName= "Eng";
        //    this.SecondLangCode= "en";

        //    this.CurrentLangSrc = "assets/img/arab-flag.jpg";
        //    this.CurrentLangName= "عربي";
        //    this.CurrentLangCode= "ar";
        //}

        this.localS.setItem('Lang', this.currentLang);
        this.toggleClassLang();
        //let dir = 'ltr';
        //if (this.currentLang.code == 'ar') {
        //    dir = 'rtl';

        //}
        //this.layout.publishLayoutChange({ dir: dir });
    //    localStorage.setItem('lang', language);


        window.location.reload();
    }

}
