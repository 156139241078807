

    <h2> {{"Visa_Terms" | translate }}</h2>

    <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
        <div class="payment-box pre-scrollable">

            <div [innerHtml]="PageContentHTML"></div>

        </div>

        <div class="listing-edit-delete-option">
            <div class="d-flex justify-content-between align-items-center">
                <button class="default-btnWhite" style="margin:20px; width:150px" [disabled]="form.invalid">{{"Accept" | translate }}</button>

                <button class="default-btnBlack" type="button" style="width:150px" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>
            </div>
            </div>
    </form>


