import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { VisaTypeInputTypesModule, VWVisaTypeInputTypesAndInputTypesModule } from './visa-type-input-types.module';

@Injectable({
    providedIn: 'root'
})
export class VisaTypeInputTypesService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceVisaType(VisaTypeID: number): Observable<VisaTypeInputTypesModule[]> {
        return this.http.get<VisaTypeInputTypesModule[]>(environment.apiURL + 'Visa/VisaTypeInputTypes/search/' + VisaTypeID);
    }


    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Visa/VisaTypeInputTypes/' + id).toPromise();
    }

    formData: VisaTypeInputTypesModule = new VisaTypeInputTypesModule();
    list: VisaTypeInputTypesModule[];
    formDataVW: VWVisaTypeInputTypesAndInputTypesModule = new VWVisaTypeInputTypesAndInputTypesModule();

    postService() {
        return this.http.post(environment.apiURL + 'Visa/VisaTypeInputTypes/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Visa/VisaTypeInputTypes/' + this.formData.visaTypeInputTypesID, this.formData);
    }


    getServiceVW(): Observable<VWVisaTypeInputTypesAndInputTypesModule[]> {
        return this.http.get<VWVisaTypeInputTypesAndInputTypesModule[]>(environment.apiURL + 'Visa/VWVisaTypeInputTypesAndInputTypes/');
    }
    getServiceVWVisaType(VisaTypeID: number): Observable<VWVisaTypeInputTypesAndInputTypesModule[]> {
        return this.http.get<VWVisaTypeInputTypesAndInputTypesModule[]>(environment.apiURL + 'Visa/VWVisaTypeInputTypesAndInputTypes/search/' + VisaTypeID);
    }

    getServiceVWVisaTypeCode(VisaTypeID: number, Code: string): Observable<VWVisaTypeInputTypesAndInputTypesModule[]> {
        return this.http.get<VWVisaTypeInputTypesAndInputTypesModule[]>(environment.apiURL + 'Visa/VWVisaTypeInputTypesAndInputTypes/searchByCode/?visaTypeID=' + VisaTypeID + '&code=' + Code);
    }
}
