import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { FeedbackModule } from './feedback.module';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getService(): Observable<FeedbackModule[]> {
        return this.http.get<FeedbackModule[]>(environment.apiURL + 'WebTools/Feedback/');
    }


    getServiceId(id: number): Observable<FeedbackModule[]> {
        return this.http.get<FeedbackModule[]>(environment.apiURL + 'WebTools/Feedback/' + id);
    }


    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'WebTools/Feedback/' + id).toPromise();
    }

    formData: FeedbackModule = new FeedbackModule();
    list: FeedbackModule[];

    postService() {
        return this.http.post(environment.apiURL + 'WebTools/Feedback/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'WebTools/Feedback/' + this.formData.feedbackID, this.formData);
    }


}
