

<h2> {{"Offers" | translate }}</h2>

<form novalidate autocomplete="off" #form="ngForm">
    <div class="payment-box pre-scrollable">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">{{'From_Quantity' | translate }}</th>
                    <th scope="col">{{'To_Quantity' | translate }}</th>
                    <th scope="col">{{'Price_Per_Person' | translate }}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of dataSource.data">
                    <td class="product-name">
                        <span class="unit-amount">{{ item.fromNo }}</span>
                    </td>

                    <td class="product-price">
                        <span class="unit-amount">{{ item.toNo }}</span>
                    </td>

                    <td class="product-quantity">
                        <span class="unit-amount">{{ item.costPerPerson | number:'1.3-3' }}</span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="listing-edit-delete-option">
        <div class="d-flex justify-content-between align-items-center">
           

            <button class="default-btnBlack" type="button" style="margin:20px; width:150px" (click)="dialogRef.close()">{{'Close' | translate }}</button>
        </div>
    </div>
</form>


