import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-payment-dialog',
  templateUrl: './confirm-payment-dialog.component.html',
  styleUrls: ['./confirm-payment-dialog.component.scss']
})
export class ConfirmPaymentDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmPaymentDialogComponent>) { }

  ngOnInit(): void {
  }


    onClickContinueBT() {

        this.dialogRef.close("Continue");

    }
}
