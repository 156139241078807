
<h2> {{"Attachment" | translate }}</h2>
<div class="payment-box">
    <form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form,Image)">



        <div class="row">

            <div class="col-lg-6 col-md-12">
                <div class="form-group">
                    <label style="font-weight:bold;">{{'Document_Type' | translate }}</label>
                    <p>{{ this.servAttachment.formData2.nameEn }}</p>
                    <!--<input matInput class="form-control" placeholder="{{'Document_Type' | translate }}" name="attachmentTypeNameEn" disabled
    #attachmentTypeNameEn="ngModel" [(ngModel)]="this.servAttachment.formData2.attachmentTypeNameEn"
    required [class.invalid]="attachmentTypeNameEn.invalid && attachmentTypeNameEn.touched">-->
                    <hr />
                    <label style="font-weight:bold;">{{'Description' | translate }}</label>
                    <p style="text-align: justify;">{{ servAttachment.formData2.descriptionEn }}</p>

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="form-group">
                    <img [src]="imageUrl" style="width:250px;height:200px">
                    <input type="file" #Image accept="image/*,application/pdf" (change)="handleFileInput($event.target.files)">
                    <!--<a (click)="goToLink()" [ngClass]="{'hide':(this.servAttachment.formData2.agentRequestAttachmentTypeID==0)}">{{"View" | translate }}</a>-->

                </div>
            </div>

        </div>
        <div class="listing-edit-delete-option">
            <div class="d-flex justify-content-between align-items-center">
                <button class="default-btnWhite" style="margin:20px; width:150px" [disabled]="form.invalid">{{"Upload" | translate }}</button>

                <button class="default-btnBlack" type="button"  style="width:150px" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>
            </div>
            </div>
</form>

</div>
