export class AgentRequestAttachmentTypeModule {
    agentRequestAttachmentTypeID: number = 0;
    attachmentTypeID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';
}

export class VWAgentRequestAttachmentType {
    agentRequestAttachmentTypeID: number = 0;
    attachmentTypeID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';
    attachDescriptionEn: string = '';
    attachDescriptionAr: string = '';
}

export class VWAgentRequestAttachmentTypeAgentRequestAttachment {
    agentRequestAttachmentTypeID: number = 0;
    attachmentTypeID: number = 0;
    descriptionAr: string = '';
    descriptionEn: string = '';
    notes: string = '';
    nameAr: string = '';
    nameEn: string = '';
    requestAttachmentID: number = 0;
    requestID: number = 0;
    filePath: string = '';
    type: string = '';
    attachDescriptionEn: string = '';
    attachDescriptionAr: string = '';

}

