<div class="modal-xl" style="overflow-y: auto; overflow-x: hidden; max-height: 550px; position: relative; margin:-10px">


    <div class="modal-header">
        <h4 class="modal-title">
            {{"OnlinePaymentGateway" | translate }}
        </h4>
    </div>
    <div class="modal-body">
        <p>
            {{"OnlinePaymentTXT" | translate }}
        </p>
        <div class="row">
            <div class="col-md-4 text-center">
                <img src="../../../../../../assets/img/icon/insurance.png" width="75" />
                <h3 style="font-size: 20px">
                    {{"PaymentT1" | translate }}
                </h3>
                <p style="font-size: 16px">
                    {{"PaymentTXT1" | translate }}
                </p>
                <img src="../assets/img/icon/payments.png" alt="">
            </div>
            <div class="col-md-4 text-center">
                <img src="../../../../../../assets/img/icon/payment.png" alt="" width="75">
                <h3 style="font-size: 20px">
                    {{"PaymentT2" | translate }}
                </h3>
                <p style="font-size: 16px">
                    {{"PaymentTXT2" | translate }}
                </p>
            </div>
            <div class="col-md-4 text-center">
                <img src="../../../../../../assets/img/icon/security.png" alt="" width="75">
                <h3 style="font-size: 20px">
                    {{"PaymentT3" | translate }}
                </h3>
                <p style="font-size: 16px">
                    {{"PaymentTXT3" | translate }}
                </p>
            </div>
        </div>
    </div>
    <div class="listing-edit-delete-option">
        <div class="d-flex justify-content-between align-items-center">
            <button class="default-btnWhite" style=" width: 130px" (click)="onClickContinueBT()">{{"Continue" | translate }}</button>

            <button class="default-btnBlack" type="button" style=" width: 130px" (click)="dialogRef.close()">{{'Back' | translate }}</button>

        </div>
    </div>
</div>





<!--<div class="payment-box pre-scrollable">



    <div class="modal">

        <div style="overflow-y: auto; overflow-x: hidden; max-height: 550px; position: relative;" class="modal-dialog-scrollable modal-dialog-centered modal-xl">-->








