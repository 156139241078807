import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VisaTypeService } from '../../../../appshared/visa/visa-type/visa-type.service';

@Component({
  selector: 'app-failed-dialog',
  templateUrl: './failed-dialog.component.html',
  styleUrls: ['./failed-dialog.component.scss']
})
export class FailedDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public service: VisaTypeService, private router: Router,
        public dialogRef: MatDialogRef<FailedDialogComponent>) { }
    PageContentHTML: string = "";
    ngOnInit(): void {
        this.buildItemForm(this.data.messageTXT)
    }

    buildItemForm(messageTXT) {
        this.PageContentHTML = messageTXT;
    }


    onSubmit(form: NgForm) {

        this.dialogRef.close(form);

  }

}
