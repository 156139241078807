import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../../appshared/masters/category/category.service';
import { CategoryModule } from '../../../appshared/masters/category/category.module';
import { TranslateService } from '@ngx-translate/core';
import { FAQService } from '../../../appshared/webTools/faq/faq.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

    public SelectedLanguage: string = "en";
    datasource: any = {
        data: []
    }
    datasourceFAQ: any = {
        data: []
    }

    constructor(private service: CategoryService, private serviceFAQ: FAQService, public translate: TranslateService, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {
        this.getAllService();
       

    }



    public getAllService = () => {

        this.serviceFAQ.getService()
            .subscribe(res => {
                this.datasourceFAQ = res;
            });

        //this.loader.open();

        this.service.getCategory("FAQ")
            .subscribe(res => {
                this.datasource = res;

                //this.service.formData.contentAr = this.datas.contentAr;
                //this.service.formData.contentEn = this.datas.contentEn;
                //this.service.formData.isVisible = this.datas.isVisible;
                //this.service.formData.multipageID = this.datas.multipageID;
                //this.service.formData.titleAr = this.datas.titleAr;
                //this.service.formData.titleEn = this.datas.titleEn;
                //this.service.formData.type = this.datas.type;
                //if (this.translate.currentLang == "ar") {

                //    this.PageContentHTML = this.service.formData.contentAr;
                //    this.PageTitle = this.service.formData.titleAr;
                //}
                //else {

                //    this.PageContentHTML = this.service.formData.contentEn;
                //    this.PageTitle = this.service.formData.titleEn;
                //}
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });

        /*  this.isLoading = false;*/
    }
}
