import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restriction-dialog',
  templateUrl: './restriction-dialog.component.html',
  styleUrls: ['./restriction-dialog.component.scss']
})
export class RestrictionDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        public dialogRef: MatDialogRef<RestrictionDialogComponent>) { }

    PageContentHTML: string = "";
    ngOnInit(): void {
        this.buildItemForm(this.data.messageTXT)
    }

    buildItemForm(messageTXT) {
        this.PageContentHTML = messageTXT;
    }



    onSubmit(form: NgForm) {

        this.dialogRef.close(form);

    }
}
