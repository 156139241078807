import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AddressIPsService } from './components/appshared/webTools/address-ips/address-ips.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SessionStoreService } from './components/common/session-store.service';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StyleDirectionService } from './components/layouts/style-direction/style-direction.service';
import { LocalStoreService } from './components/common/local-store.service';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    currentCurrency: string = "1";
    constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private styleDir: StyleDirectionService,
        public service: AddressIPsService, private http: HttpClient, private toastr: ToastrService, private localS: LocalStoreService, public translate: TranslateService) {
        this.getIPAddress();
    }

    ngOnInit(){
        this.recallJsFuntions();


        if (this.localS.getItem('Lang') == 'en')
        {
            this.switchLang('en');
        }
        else if (this.localS.getItem('Lang') == 'ar')
        {
            this.switchLang('ar');
        }
        else
        {
            this.translate.use('en');

            // Save
            this.localS.setItem('CurrencyID', this.currentCurrency);
            this.localS.setItem('CurrencyRate', "1");
            this.localS.setItem('CurrencyName', "OMR");
            this.localS.setItem('Lang', "en");
            this.switchLang('en');
        }
    }


    switchLang(lang: string) {
       

        let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
        htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
        htmlTag.lang = lang === "ar" ? "ar" : "en";
        this.translate.setDefaultLang(lang);

        if (lang === "ar") {
            this.styleDir.fillData(true);
        }
        else {
            this.styleDir.fillData(false);

        }
        this.translate.use(lang);
    }
   






    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }


    getIPAddress() {

        this.http.get("https://geolocation-db.com/json/").subscribe((res: any) => {
            this.service.formData.ip = res.IPv4;
            this.service.formData.countryName = res.country_name;
            this.service.formData.countryCode = res.country_code;
            this.service.postService().subscribe(
                data => {
                

                },
                err => {
                    this.toastr.warning('welcome to Hekaya', 'Warning');
                }
            );


        });


    }

}
