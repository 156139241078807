<div class="container">
    <div class="main-banner-content-box" style="margin-top:30px">
        <ul id="myTab" role="tablist" class="nav nav-tabs row no-gutters">
            <li class="nav-item col first_tab">
                <a id="rentACar-tab" data-toggle="tab" href="#oman_TabContent" role="tab" aria-controls="home" aria-selected="true" class="nav-link active"><span class="icon_size">{{'Oman_Visa' | translate }}</span><br><small></small></a>
            </li>
            <li class="nav-item col last_tab"><a id="contact-tab" data-toggle="tab" href="#others_TabContent" role="tab" aria-controls="contact" aria-selected="false" class="nav-link"><span class="icon_size">{{'Other_Country_Visa' | translate }}</span><br><small></small></a></li>
        </ul>
        <div id="myTabContent" class="tab-content">
            <div id="oman_TabContent" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade show active">
                <div class="search_box">
                    <!--onClickSubmit(userlogin.value)-->
                    <form autocomplete="off" #userlogin="ngForm" (ngSubmit)="onClickOmanSubmit(selectNationality.value, selectResidenceCountry.value, arrivalDateDT.value)">

                        <div class="row">
                            <div class="col-xs-12 col-12 col-md-6 form-group">
                                <label>{{'Arrival_Date' | translate }}</label>
                                <input type="text" placeholder="DD/MM/YYYY" name="arrivalDateDT" ngModel #arrivalDateDT
                                       class="form-control form-control2"
                                       bsDatepicker
                                       [bsValue]="bsValue"
                                       [minDate]="minDate"
                                       [maxDate]="maxDate"
                                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                            </div>
                            <div class="col-xs-12 col-12 col-md-6">
                                <div class="form-group">

                                    <label>{{'Residence_Country' | translate }}</label>
                                    <mat-select #selectResidenceCountry [formControl]="countryCtrl" placeholder="{{'Select_Country' | translate }}" class="form-control form-control2" required style="padding-top:10px" name="ResidenceCountryC" ngModel>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredresidenceCountries | async" [value]="country.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ country.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ country.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>

                                </div>

                            </div>
                            <div class="col-xs-12 col-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Nationality' | translate }}</label>
                                    <mat-select #selectNationality [formControl]="nationalityCtrl" placeholder="{{'Select_Nationality' | translate }}" #singleSelect2 class="form-control form-control2" required style="padding-top:10px" ngModel>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">{{'Apply_For_Visa' | translate }}</button>
                            </div>
                        </div>


                    </form>

                </div>
            </div>

            <div id="others_TabContent" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade">
                <div class="search_box">



                    <form autocomplete="off" #userlogin="ngForm" (ngSubmit)="onClickOtherSubmit(selectVisaCountryO.value,selectNationalityO.value, selectResidenceCountryO.value, arrivalDateODT.value)">

                        <div class="row">
                            <div class="col-xs-12 col-12 col-md-6 form-group">
                                <label>{{'Arrival_Date' | translate }}</label>
                                <input type="text" placeholder="DD/MM/YYYY" name="arrivalDateODT" ngModel #arrivalDateODT
                                       class="form-control form-control2"
                                       bsDatepicker
                                       [bsValue]="bsValue"
                                       [minDate]="minDate"
                                       [maxDate]="maxDate"
                                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">
                            </div>
                            <div class="col-xs-12 col-12 col-md-6">
                                <div class="form-group">

                                    <label>{{'Residence_Country' | translate }}</label>
                                    <mat-select #selectResidenceCountryO [formControl]="countryCtrl" placeholder="{{'Select_Country' | translate }}" class="form-control form-control2" required style="padding-top:10px" ngModel>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredresidenceCountries | async" [value]="country.countryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ country.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ country.nameEn }}</ng-template>

                                          
                                        </mat-option>
                                    </mat-select>

                                </div>

                            </div>
                            <div class="col-xs-12 col-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Nationality' | translate }}</label>
                                    <mat-select #selectNationalityO [formControl]="nationalityCtrl" placeholder="{{'Select_Nationality' | translate }}" #singleSelect2 class="form-control form-control2" required style="padding-top:10px" ngModel>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl" placeholderLabel="{{'Select_Nationality' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationalityID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ nationality.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ nationality.nameEn }}</ng-template>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="col-xs-12 col-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Visit_Country' | translate }}</label>
                                    <mat-select #selectVisaCountryO [formControl]="visaCountryCtrl" placeholder="{{'Select_Country' | translate }}" #singleSelect2 class="form-control form-control2" required style="padding-top:10px" ngModel>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="visaCountryFilterCtrl" placeholderLabel="{{'Select_Country' | translate }}" noEntriesFoundLabel="{{'No_Data_Found' | translate }}">
                                                <mat-icon ngxMatSelectSearchClear></mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let visaCountry of filteredVisaCountries | async" [value]="visaCountry.visaCountryID">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                            </ng-template>
                                            <ng-template #other_contentAr>{{ visaCountry.nameAr }}</ng-template>
                                            <ng-template #other_contentEn>{{ visaCountry.nameEn }}</ng-template>

                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">{{'Apply_For_Visa' | translate }}</button>
                            </div>
                        </div>


                    </form>








                </div>
            </div>
        </div>
    </div>
</div>




