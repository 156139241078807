import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


import { CountryModule, Country } from '../../appshared/masters/country/country.module';
import { CountryService } from '../../appshared/masters/country/country.service';
import { Nationality, NationalityModule } from '../../appshared/masters/nationality/nationality.module';
import { NationalityService } from '../../appshared/masters/nationality/nationality.service';
import { ToastrService } from 'ngx-toastr';
import { VisaCountry, VisaCountryModule } from '../../appshared/visa/visa-country/visa-country.module';

import { Bank, BANKS } from '../../appviews/demo-data';
import { VisaCountryService } from '../../appshared/visa/visa-country/visa-country.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {

    bsValue = new Date();
    maxDate = new Date();
    minDate = new Date();


   // @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

    protected _onDestroy = new Subject<void>();



    public SelectedLanguage: string = "en";


    constructor(private snackBar: MatSnackBar, public servCountry: CountryService, private router: Router, public servNationality: NationalityService, public servVisaCountry: VisaCountryService, private toastr: ToastrService, public translate: TranslateService,
        private loader: AppLoaderService) {

        this.minDate.setDate(this.minDate.getDate() + 2);
        this.maxDate.setDate(this.maxDate.getDate() + 180);
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }

    }

    ngOnInit(): void {
        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterresidenceCountries();
            });


        this.nationalityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filternationalities();
            });

        this.visaCountryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filtervisaCountries();
            });

        this.getAllResidenceCountry();
        this.getAllnationality();
        this.getAllvisaCountry();
    }

    ngAfterViewInit() {
      //  this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // *******************************************************************************************************************************************


    public residenceCountries: Country[];

    public countryCtrl: UntypedFormControl = new UntypedFormControl();
    public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredresidenceCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

    protected filterresidenceCountries() {
        if (!this.residenceCountries) {
            return;
        }
        // get the search keyword
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredresidenceCountries.next(this.residenceCountries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the residenceCountries
        if (this.SelectedLanguage == "en") {
            this.filteredresidenceCountries.next(
                this.residenceCountries.filter(Country => Country.nameEn.toLowerCase().indexOf(search) > -1)
            );
        }
        else {
            this.filteredresidenceCountries.next(
                this.residenceCountries.filter(Country => Country.nameAr.toLowerCase().indexOf(search) > -1)
            );
        }


     
    }


    public getAllResidenceCountry = () =>
    {

       // this.loader.open();
        this.servCountry.getService2()
            .subscribe(res => {
                //this.residenceCountries = res as CountryModule[];
                this.residenceCountries = res as Country[];

                this.loader.close();
        this.filteredresidenceCountries.next(this.residenceCountries.slice());

            })
    }

    // *******************************************************************************************************************************************
    
    public nationalities: Nationality[];

   

    public nationalityCtrl: UntypedFormControl = new UntypedFormControl();
    public nationalityFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredNationalities: ReplaySubject<Nationality[]> = new ReplaySubject<Nationality[]>(1);

    protected filternationalities() {
        if (!this.nationalities) {
            return;
        }
        // get the search keyword
        let search = this.nationalityFilterCtrl.value;
        if (!search) {
            this.filteredNationalities.next(this.nationalities.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the nationalities
        if (this.SelectedLanguage == "en") {

            this.filteredNationalities.next(
                this.nationalities.filter(Nationality => Nationality.nameEn.toLowerCase().indexOf(search) > -1)
            );
        }
        else {
            this.filteredNationalities.next(
                this.nationalities.filter(Nationality => Nationality.nameAr.toLowerCase().indexOf(search) > -1)
            );
        }
       
    }


    public getAllnationality = () => {
        this.loader.open();
        this.servNationality.getService()
            .subscribe(res => {
                //this.nationalities = res as nationalityModule[];
                this.nationalities = res as Nationality[];

                this.filteredNationalities.next(this.nationalities.slice());
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
            
    }

    // *******************************************************************************************************************************************


    public visaCountries: VisaCountry[];



    public visaCountryCtrl: UntypedFormControl = new UntypedFormControl();
    public visaCountryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredVisaCountries: ReplaySubject<VisaCountry[]> = new ReplaySubject<VisaCountry[]>(1);

    protected filtervisaCountries() {
        if (!this.visaCountries) {
            return;
        }
        // get the search keyword
        let search = this.visaCountryFilterCtrl.value;
        if (!search) {
            this.filteredVisaCountries.next(this.visaCountries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the visaCountries
        if (this.SelectedLanguage == "en") {
            this.filteredVisaCountries.next(
                this.visaCountries.filter(VisaCountry => VisaCountry.nameEn.toLowerCase().indexOf(search) > -1)
            );
        }
        else {
            this.filteredVisaCountries.next(
                this.visaCountries.filter(VisaCountry => VisaCountry.nameAr.toLowerCase().indexOf(search) > -1)
            );
        }

      
    }


    public getAllvisaCountry = () => {

        this.servVisaCountry.getServiceWithouOman()
            .subscribe(res => {
                //this.visaCountries = res as visaCountryModule[];
                this.visaCountries = res as VisaCountry[];

                this.filteredVisaCountries.next(this.visaCountries.slice());

            })
    }

    // *******************************************************************************************************************************************


    onClickOmanSubmit(nationalitySelect, residenceCountrySelect, DTArrivalDate) {
        if (nationalitySelect == "" || residenceCountrySelect == "" || DTArrivalDate == "")
        {
            this.toastr.error('Please Fill All Data', '');
            return;
        }
        this.router.navigate(['step1', { nid: nationalitySelect, vid: 1, rid: residenceCountrySelect, date: DTArrivalDate }]);
    }

    onClickOtherSubmit(visaCountrySelect, nationalitySelect, residenceCountrySelect, DTArrivalDate) {
        if (nationalitySelect == "" || residenceCountrySelect == "" || DTArrivalDate == "" || visaCountrySelect == "") {
            this.toastr.error('Please Fill All Data', '');
            return;
        }
       
        this.router.navigate(['step1', { nid: nationalitySelect, rid: residenceCountrySelect, date: DTArrivalDate, vid: visaCountrySelect }]);
    }
}
