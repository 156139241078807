import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/layouts/error/error.component';


import { WebTermsComponent } from './components/appviews/multipage/web-terms/web-terms.component';
import { ServiceTermsComponent } from './components/appviews/multipage/service-terms/service-terms.component';
import { PrivacyTermsComponent } from './components/appviews/multipage/privacy-terms/privacy-terms.component';
import { FAQComponent } from './components/appviews/multipage/faq/faq.component';
import { ContactComponent } from './components/appviews/multipage/contact/contact.component';
import { HomeComponent } from './components/appviews/home/home.component';
import { Step1VisaTypeComponent } from './components/appviews/applicationForm/step1-visa-type/step1-visa-type.component';
import { Step2BasicDetailsComponent } from './components/appviews/applicationForm/step2-basic-details/step2-basic-details.component';
import { Step3PersonalDetailsComponent } from './components/appviews/applicationForm/step3-personal-details/step3-personal-details.component';
import { Step4AttachmentComponent } from './components/appviews/applicationForm/step4-attachment/step4-attachment.component';
import { Step5ReviewComponent } from './components/appviews/applicationForm/step5-review/step5-review.component';
import { Step6PaymentComponent } from './components/appviews/applicationForm/step6-payment/step6-payment.component';
import { AboutUsComponent } from './components/appviews/multipage/about-us/about-us.component';
import { ServicesComponent } from './components/appviews/multipage/services/services.component';
import { PaymentResultComponent } from './components/appviews/applicationForm/payment-result/payment-result.component';
import { TrackingComponent } from './components/appviews/tracking/tracking/tracking.component';
import { TrackingResultComponent } from './components/appviews/tracking/tracking-result/tracking-result.component';
import { NewAgentRequestComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request.component';
import { NewAgentRequestAttachmentComponent } from './components/appviews/applicationForm/new-agent-request/new-agent-request-attachment/new-agent-request-attachment.component';
import { ReferBackAttachmentComponent } from './components/appviews/tracking/refer-back-attachment/refer-back-attachment.component';
import { DownloadVisaComponent } from './components/appviews/tracking/download-visa/download-visa.component';
import { RequestResultComponent } from './components/appviews/applicationForm/new-agent-request/request-result/request-result.component';
import { ApplicationStatusComponent } from './components/appviews/multipage/application-status/application-status.component';
import { UnderMaintenanceComponent } from './components/appviews/under-maintenance/under-maintenance.component';


const routes: Routes = [
    { path: '', component: UnderMaintenanceComponent },

	{path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)
    ],
	exports: [RouterModule]
})
export class AppRoutingModule {}
