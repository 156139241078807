export class VisaCountryModule {
    visaCountryID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    isVisible: boolean = true;
    notes: string = '';
    notificationAr: string = '';
    notificationEn: string = '';
    isNotification: boolean = true;

}
export interface VisaCountry {
    visaCountryID: number;
    nameAr: string;
    nameEn: string;
    isVisible: boolean;
    notes: string;
    notificationAr: string;
    notificationEn: string;
    isNotification: boolean;

}
