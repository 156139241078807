import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../appshared/orders/order/order.service';

@Component({
  selector: 'app-funfacts',
  templateUrl: './funfacts.component.html',
  styleUrls: ['./funfacts.component.scss']
})
export class FunfactsComponent implements OnInit {

    constructor(private service: OrderService) { }

    ngOnInit(): void {
        this.getAllService();
  }

    public OmanOrders: number = 0;
    public InternationalOrders: number = 0;
    public CountriesCovered: number = 0;
    public YearsExperience: number = 1;
    isLoading = true;
    public getAllService = () => {
        this.isLoading = true;

        this.service.getServiceHome()
            .subscribe(res => {
                var splitted = res.split("-", 4);
                this.OmanOrders = +splitted[0];
                this.InternationalOrders = +splitted[1];
                this.CountriesCovered = +splitted[2];
                this.YearsExperience = +splitted[3];

                this.isLoading = false;
            },
                (err) => {
                    this.isLoading = false;

                })

    }

}
