<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="2" dir="ltr" style="margin-left:-35px">
            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>

    </div>
</div>




<section class="contact-area ptb-70 bg-light">



    <div class="container">



        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Attachment' | translate}}</h3>

                <mat-table [dataSource]="dataSourceAttachment" matSort>

                    <ng-container matColumnDef="num">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Num.' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachmentTypeNameEn">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Document' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="attachmentTypeNameEn"> {{row.attachmentTypeNameEn}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachmentTypeNameAr">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Document' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="attachmentTypeNameAr"> {{row.attachmentTypeNameAr}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="filePath">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Picture' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="filePath"> <img  class="product-thumbnail" [src]="apiURL + row.filePath" style="max-height: 80px; max-width: 80px; margin: 3px; width: 100%;" onerror="this.onerror=null; this.src='https://images.assetsdelivery.com/compings_v2/yehorlisnyi/yehorlisnyi2104/yehorlisnyi210400016.jpg'" /> </mat-cell>

                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div class="text-success" *ngIf="row.filePath != ''"><i class="bx bx-check" style="font-size:40px"></i></div>
                            <button mat-icon-button class="btn btn-primary" (click)="openAttachmentPopUp(row, false)" style="font-size:20px"><i class="bx bx-plus"></i></button>
                            <!--*ngIf="row.filePath == ''"-->
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;">
                    </mat-row>

                    <ng-container matColumnDef="noData">
                        <mat-footer-cell *matFooterCellDef colspan="6">
                            {{'No_data.' | translate }}
                        </mat-footer-cell>
                    </ng-container>


                </mat-table>


                <div class="listing-edit-delete-option">
                    <div class="d-flex justify-content-between align-items-center">

                        <button class="default-btnBlack btn_mobile" *ngIf="isDone" style="width: 160px; margin: 20px" type="button" (click)="Add_Extra_PersonBT()"><i class="bx bx-plus"></i> {{"Add_Extra_Person" | translate }}</button>

                        <button class="default-btnWhite btn_mobile" *ngIf="isDone" style="width: 160px; margin: 20px" type="button" (click)="GoNextBT()">{{'Go_Next' | translate }}</button>

                    </div>
                    </div>


                </div>
            </div>
    </div>

</section>
