import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {

    constructor(private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

    onClickFindTrack(orderNoTX, emailTX) {
        if (orderNoTX == "" || emailTX == "") {
            this.toastr.error('Please Fill All Data', '');
            return;
        }
        this.router.navigate(['tracking-result', { ono: orderNoTX, email: emailTX }]);
    }
}
