import { Component, OnInit, Inject } from '@angular/core';
import { MultipageModule } from '../../../appshared/webTools/multipage/multipage.module';
import { MultipageService } from '../../../appshared/webTools/multipage/multipage.service';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-service-terms',
  templateUrl: './service-terms.component.html',
  styleUrls: ['./service-terms.component.scss']
})
export class ServiceTermsComponent implements OnInit {

    public PageContentHTML: string = "";
    public PageTitle: string = "";
    private datas: any;


    constructor(private service: MultipageService, public translate: TranslateService, private loader: AppLoaderService) { }

    ngOnInit(): void {
        this.getAllService();

    }



    public getAllService = () => {
        /*    this.isLoading = true;*/
        //this.loader.open();
        this.service.getServiceType("Services Terms")
            .subscribe(res => {
                this.datas = res as MultipageModule[];

                this.service.formData.contentAr = this.datas.contentAr;
                this.service.formData.contentEn = this.datas.contentEn;
                this.service.formData.isVisible = this.datas.isVisible;
                this.service.formData.multipageID = this.datas.multipageID;
                this.service.formData.titleAr = this.datas.titleAr;
                this.service.formData.titleEn = this.datas.titleEn;
                this.service.formData.type = this.datas.type;
                if (this.translate.currentLang == "ar") {

                    this.PageContentHTML = this.service.formData.contentAr;
                    this.PageTitle = this.service.formData.titleAr;
                }
                else {

                    this.PageContentHTML = this.service.formData.contentEn;
                    this.PageTitle = this.service.formData.titleEn;
                }
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });

        /*  this.isLoading = false;*/
    }
}
