import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { AddressIPsModule } from './address-ips.module';

@Injectable({
  providedIn: 'root'
})
export class AddressIPsService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'WebTools/AddressIPs/' + id).toPromise();
    }

    formData: AddressIPsModule = new AddressIPsModule();
    list: AddressIPsModule[];

    postService() {
        return this.http.post(environment.apiURL + 'WebTools/AddressIPs/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'WebTools/AddressIPs/' + this.formData.addressIPsID, this.formData);
    }
}
