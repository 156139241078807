import { Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { Country, CountryModule } from '../../../appshared/masters/country/country.module';
import { CountryService } from '../../../appshared/masters/country/country.service';
import { JobCategory, JobCategoryModule } from '../../../appshared/masters/job-category/job-category.module';
import { JobCategoryService } from '../../../appshared/masters/job-category/job-category.service';
import { Language, LanguageModule } from '../../../appshared/masters/language/language.module';
import { LanguageService } from '../../../appshared/masters/language/language.service';
import { Nationality, NationalityModule } from '../../../appshared/masters/nationality/nationality.module';
import { NationalityService } from '../../../appshared/masters/nationality/nationality.service';
import { OrdinaryDocumentModule } from '../../../appshared/masters/ordinary-document/ordinary-document.module';
import { OrdinaryDocumentService } from '../../../appshared/masters/ordinary-document/ordinary-document.service';
import { Religion, ReligionModule } from '../../../appshared/masters/religion/religion.module';
import { ReligionService } from '../../../appshared/masters/religion/religion.service';
import { OrderPersonModule } from '../../../appshared/orders/order-person/order-person.module';
import { OrderPersonService } from '../../../appshared/orders/order-person/order-person.service';
import { OrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { VisaCountryModule } from '../../../appshared/visa/visa-country/visa-country.module';
import { VisaCountryService } from '../../../appshared/visa/visa-country/visa-country.service';
import { VWVisaTypeInputTypesAndInputTypesModule } from '../../../appshared/visa/visa-type-input-types/visa-type-input-types.module';
import { VisaTypeInputTypesService } from '../../../appshared/visa/visa-type-input-types/visa-type-input-types.service';
import { VisaTypeModule } from '../../../appshared/visa/visa-type/visa-type.module';
import { VisaTypeService } from '../../../appshared/visa/visa-type/visa-type.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { FailedDialogComponent } from '../step1-visa-type/failed-dialog/failed-dialog.component';
import { InputInfoDialogComponent } from './input-info-dialog/input-info-dialog.component';
import { RestrictionDialogComponent } from '../step1-visa-type/restriction-dialog/restriction-dialog.component';
import { formatDate } from '@angular/common';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';

@Component({
  selector: 'app-step3-personal-details',
  templateUrl: './step3-personal-details.component.html',
  styleUrls: ['./step3-personal-details.component.scss']
})
export class Step3PersonalDetailsComponent implements OnInit {


    bsValue = new Date();
    maxDate = new Date();
    minDate = new Date();


    public countryData: CountryModule[];
    public nationalityData: NationalityModule[];
    public ordinaryDocumentData: OrdinaryDocumentModule[];
    public jobCategoryData: JobCategoryModule[];
    public languageData: LanguageModule[];
    public religionData: ReligionModule[];


    thisID: number = 0;
    IsNew: boolean = true;
    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    dates: string = "";
    email: string = "";
    private datas: any;

    dialingCode: string = "+968";
    countryName: string = "";
    visaTypeName: string = "";
    stayDays: string = "";
    orderNo: string = "";
    fromDate = new Date();
    toDate = new Date();

    protected _onDestroy = new Subject<void>();

    constructor(public dialog: MatDialog, private router: Router, private route: ActivatedRoute, public service: OrderPersonService, private servVisaTypeInputTypes: VisaTypeInputTypesService, public servVisaCountry: VisaCountryService, private servVisaType: VisaTypeService, public servOrder: OrderService,
        private servCountry: CountryService, private servNationality: NationalityService, private servOrdinaryDocument: OrdinaryDocumentService, private servJobCategory: JobCategoryService, private servLanguage: LanguageService, private servReligion: ReligionService, private toastr: ToastrService, public translate: TranslateService, private loader: AppLoaderService, private ServSavedOrder: SavedOrderService ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }
    ngOnInit(): void {

        this.service.formData = new OrderPersonModule();


        this.sub = this.route.params.subscribe(params => {
            this.vtid = +params['vtid'];
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.oid = +params['oid'];
            this.email = params['email'];
            this.dates = params['date'];
        });


        //this.minDate.setDate(this.minDate.getDate() - 1);
        //this.maxDate.setDate(this.maxDate.getDate() + 7);


        this.gnCheckOrderValidity();

        this.getAllcountry();
        this.getAllnationality();
        this.getAllOrdinaryDocument();
        this.getAlljobCategory();
        this.getAlllanguage();
        this.getAllReligion();
        this.FillVisaTypes();
        this.FillOrder();

        this.minDate.setDate(new Date().getDate() + 180);

        this.sub = this.route.params.subscribe(params => {
            this.thisID = +params['id'];
        });
        if (this.thisID.toString() != "NaN") {
            this.IsNew = false;
            this.buildItemForm();
        }
        else {
            this.service.formData.orderID = this.oid;
            this.service.formData.passportExpiryDate.setDate( new Date().getDate() + 180);


        }
        this.ActiveInput();
      //  ActiveInput


        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filtercountries();
            });
    }

    checkMessage: string = "";
    data: any = {};
    gnCheckOrderValidity() {
        this.servOrder.getCheckOrder(this.oid, this.SelectedLanguage)
            .subscribe(res => {
                this.checkMessage = res;


                this.loader.close();

                if (this.checkMessage == "success") {

                }
                else {


                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.data, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['home']);

                        })
                }

            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    firstNameActive: boolean = false;
    middleNameActive: boolean = false;
    lastNameActive: boolean = false;
    fullNameActive: boolean = false;
    genderActive: boolean = false;
    marriedStatusActive: boolean = false;
    dobActive: boolean = false;
    birthCountryIDActive: boolean = false;
    birthPlaceActive: boolean = false;
    religionIDActive: boolean = false;
    spouseNameActive: boolean = false;
    firstLanguageIDActive: boolean = false;
    secondLanguageIDActive: boolean = false;
    motherNameActive: boolean = false;
    motherNationalityIDActive: boolean = false;
    motherPerviousNationalityIDActive: boolean = false;
    motherBirthPlaceActive: boolean = false;
    motherBirthCountryIDActive: boolean = false;
    fatherNameActive: boolean = false;
    fatherNationalityIDActive: boolean = false;
    fatherPerviousNationalityIDActive: boolean = false;
    fatherBirthPlaceActive: boolean = false;
    fatherBirthCountryIDActive: boolean = false;
    jobTitleActive: boolean = false;
    jobCategoryIDActive: boolean = false;
    employerActive: boolean = false;
    arrivalAirpotPortNameActive: boolean = false;
    ordinaryDocumentIDActive: boolean = false;
    passportNoActive: boolean = false;
    passportIssueDateActive: boolean = false;
    passportExpiryDateActive: boolean = false;
    passportIssueCountryIDActive: boolean = false;
    phoneNumberActive: boolean = false;
    emailActive: boolean = false;
    posteCodeActive: boolean = false;
    previousNationalityIDActive: boolean = false;
    isNameChangedActive: boolean = false;
    visibleIdentificationMarkActive: boolean = false;
    educationQualificationActive: boolean = false;
    acquireNationalityByActive: boolean = false;
    isOtherValidPassportICActive: boolean = false;
    otherPassportIssueCountryIDActive: boolean = false;
    visitPlace1Active: boolean = false;
    visitPlace2Active: boolean = false;
    isVisitBeforeActive: boolean = false;
    visitedPlaceBeforeActive: boolean = false;
    cityVisitedBeforeActive: boolean = false;
    lastVisaNoActive: boolean = false;
    lastVisaTypeActive: boolean = false;
    lastIssuePlaceActive: boolean = false;
    lastIssueDateActive: boolean = false;
    presentHouseNoStreetActive: boolean = false;
    presentCityActive: boolean = false;
    presentCountryIDActive: boolean = false;
    presentStateActive: boolean = false;
    presentPostalCodeActive: boolean = false;
    presentPhoneNoActive: boolean = false;
    presentMobileNoActive: boolean = false;
    presentEmailActive: boolean = false;
    permanentHouseNoStreetActive: boolean = false;
    permanentCityActive: boolean = false;
    permanentStateActive: boolean = false;


    public dataSourceInputTypes = new MatTableDataSource<VWVisaTypeInputTypesAndInputTypesModule>();
    public ActiveInput = () => {

        this.loader.open();
        this.servVisaTypeInputTypes.getServiceVWVisaType(this.vtid)
            .subscribe(res => {
                this.dataSourceInputTypes.data = res as VWVisaTypeInputTypesAndInputTypesModule[];

                for (let rows of this.dataSourceInputTypes.data) {
                    if (rows.code == "FirstName") {
                        this.firstNameActive = true;
                    }
                    else if (rows.code == "MiddleName") {
                        this.middleNameActive = true;
                    }
                    else if (rows.code == "LastName") {
                        this.lastNameActive = true;
                    }
                    else if (rows.code == "FullName") {
                        this.fullNameActive = true;
                    }
                    else if (rows.code == "Gender") {
                        this.genderActive = true;
                    }
                    else if (rows.code == "DOB") {
                        this.dobActive = true;
                    }
                    else if (rows.code == "MarriedStatus") {
                        this.marriedStatusActive = true;
                    }
                    else if (rows.code == "BirthCountry") {
                        this.birthCountryIDActive = true;
                    }
                    else if (rows.code == "BirthPlace") {
                        this.birthPlaceActive = true;
                    }
                    else if (rows.code == "Religion") {
                        this.religionIDActive = true;
                    }
                    else if (rows.code == "SpouseName") {
                        this.spouseNameActive = true;
                    }
                    else if (rows.code == "FirstLanguage") {
                        this.firstLanguageIDActive = true;
                    }
                    else if (rows.code == "SecondLanguage") {
                        this.secondLanguageIDActive = true;
                    }
                    else if (rows.code == "MotherName") {
                        this.motherNameActive = true;
                    }
                    else if (rows.code == "MotherNationality") {
                        this.motherNationalityIDActive = true;
                    }
                    else if (rows.code == "MotherPerviousNationality") {
                        this.motherPerviousNationalityIDActive = true;
                    }
                    else if (rows.code == "MotherBirthPlace") {
                        this.motherBirthPlaceActive = true;
                    }
                    else if (rows.code == "MotherBirthCountry") {
                        this.motherBirthCountryIDActive = true;
                    }
                    else if (rows.code == "FatherName") {
                        this.fatherNameActive = true;
                    }
                    else if (rows.code == "FatherNationality") {
                        this.fatherNationalityIDActive = true;
                    }
                    else if (rows.code == "FatherPerviousNationality") {
                        this.fatherPerviousNationalityIDActive = true;
                    }
                    else if (rows.code == "FatherBirthPlace") {
                        this.fatherBirthPlaceActive = true;
                    }
                    else if (rows.code == "FatherBirthCountry") {
                        this.fatherBirthCountryIDActive = true;
                    }
                    else if (rows.code == "JobTitle") {
                        this.jobTitleActive = true;
                    }
                    else if (rows.code == "JobCategory") {
                        this.jobCategoryIDActive = true;
                    }
                    else if (rows.code == "Employer") {
                        this.employerActive = true;
                    }
                    else if (rows.code == "ArrivalAirpotPortName") {
                        this.arrivalAirpotPortNameActive = true;
                    }
                    else if (rows.code == "OrdinaryDocument") {
                        this.ordinaryDocumentIDActive = true;
                    }
                    else if (rows.code == "PassportNo") {
                        this.passportNoActive = true;
                    }
                    else if (rows.code == "PassportIssueDate") {
                        this.passportIssueDateActive = true;
                    }
                    else if (rows.code == "PassportExpiryDate") {
                        this.passportExpiryDateActive = true;
                    }
                    else if (rows.code == "PassportIssueCountry") {
                        this.passportIssueCountryIDActive = true;
                    }
                    else if (rows.code == "PhoneNumber") {
                        this.phoneNumberActive = true;
                    }
                    else if (rows.code == "Email") {
                        this.emailActive = true;
                    }
                    else if (rows.code == "PosteCode") {
                        this.posteCodeActive = true;
                    }
                    else if (rows.code == "PreviousNationality") {
                        this.previousNationalityIDActive = true;
                    }
                    else if (rows.code == "IsNameChanged") {
                        this.isNameChangedActive = true;
                    }
                    else if (rows.code == "VisibleIdentificationMark") {
                        this.visibleIdentificationMarkActive = true;
                    }
                    else if (rows.code == "EducationQualification") {
                        this.educationQualificationActive = true;
                    }
                    else if (rows.code == "AcquireNationalityBy") {
                        this.acquireNationalityByActive = true;
                    }
                    else if (rows.code == "IsOtherValidPassportIC") {
                        this.isOtherValidPassportICActive = true;
                    }
                    else if (rows.code == "OtherPassportIssueCountry") {
                        this.otherPassportIssueCountryIDActive = true;
                    }
                    else if (rows.code == "VisitPlace1") {
                        this.visitPlace1Active = true;
                    }
                    else if (rows.code == "VisitPlace2") {
                        this.visitPlace2Active = true;
                    }
                    else if (rows.code == "IsVisitBefore") {
                        this.isVisitBeforeActive = true;
                    }
                    else if (rows.code == "VisitedPlaceBefore") {
                        this.visitedPlaceBeforeActive = true;
                    }
                    else if (rows.code == "CityVisitedBefore") {
                        this.cityVisitedBeforeActive = true;
                    }
                    else if (rows.code == "LastVisaNo") {
                        this.lastVisaNoActive = true;
                    }
                    else if (rows.code == "LastVisaType") {
                        this.lastVisaTypeActive = true;
                    }
                    else if (rows.code == "LastIssuePlace") {
                        this.lastIssuePlaceActive = true;
                    }
                    else if (rows.code == "LastIssueDate") {
                        this.lastIssueDateActive = true;
                    }
                    else if (rows.code == "PresentHouseNoStreet") {
                        this.presentHouseNoStreetActive = true;
                    }
                    else if (rows.code == "PresentCity") {
                        this.presentCityActive = true;
                    }
                    else if (rows.code == "PresentCountry") {
                        this.presentCountryIDActive = true;
                    }
                    else if (rows.code == "PresentState") {
                        this.presentStateActive = true;
                    }
                    else if (rows.code == "PresentPostalCode") {
                        this.presentPostalCodeActive = true;
                    }
                    else if (rows.code == "PresentPhoneNo") {
                        this.presentPhoneNoActive = true;
                    }
                    else if (rows.code == "PresentMobileNo") {
                        this.presentMobileNoActive = true;
                    }
                    else if (rows.code == "PresentEmail") {
                        this.presentEmailActive = true;
                    }
                    else if (rows.code == "PermanentHouseNoStreet") {
                        this.permanentHouseNoStreetActive = true;
                    }
                    else if (rows.code == "PermanentCity") {
                        this.permanentCityActive = true;
                    }
                    else if (rows.code == "PermanentState") {
                        this.permanentStateActive = true;
                    }
                   
                }

                this.clearSelect();
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });

    }


    clearSelect() {
        if (this.thisID.toString() != "NaN") {

        }
        else {


            if (this.genderActive) {
                this.service.formData.gender = undefined;
            }
             if (this.marriedStatusActive) {
                this.service.formData.marriedStatus = undefined;
            }
             if (this.birthCountryIDActive) {
                this.service.formData.birthCountryID = undefined;
            }
             if (this.religionIDActive) {
                this.service.formData.religionID = undefined;
            }
             if (this.firstLanguageIDActive) {
                this.service.formData.firstLanguageID = undefined;
            }
             if (this.secondLanguageIDActive) {
                this.service.formData.secondLanguageID = undefined;
            }
             if (this.motherNationalityIDActive) {
                this.service.formData.motherNationalityID = undefined;
            }
             if (this.motherPerviousNationalityIDActive) {
                this.service.formData.motherPerviousNationalityID = undefined;
            }
             if (this.motherBirthCountryIDActive) {
                this.service.formData.motherBirthCountryID = undefined;
            }
             if (this.fatherNationalityIDActive) {
                this.service.formData.fatherNationalityID = undefined;
            }
             if (this.fatherPerviousNationalityIDActive) {
                this.service.formData.fatherPerviousNationalityID = undefined;
            }
             if (this.fatherBirthCountryIDActive) {
                this.service.formData.fatherBirthCountryID = undefined;
            }
             if (this.jobCategoryIDActive) {
                this.service.formData.jobCategoryID = undefined;
            }
             if (this.ordinaryDocumentIDActive) {
                this.service.formData.ordinaryDocumentID = undefined;
            }
             if (this.passportIssueCountryIDActive) {
                this.service.formData.passportIssueCountryID = undefined;
            }
             if (this.previousNationalityIDActive) {
                this.service.formData.previousNationalityID = undefined;
            }
             if (this.otherPassportIssueCountryIDActive) {
                this.service.formData.otherPassportIssueCountryID = undefined;
            }
             if (this.presentCountryIDActive) {
                this.service.formData.presentCountryID = undefined;
            }
        }

    }


    buildItemForm() {

        this.service.getServiceId(this.thisID)
            .subscribe(res => {
                this.datas = res as OrderPersonModule[];

                this.service.formData.orderPersonID = this.datas.orderPersonID;
                this.service.formData.orderID = this.datas.orderID;
                this.service.formData.firstName = this.datas.firstName;
                this.service.formData.middleName = this.datas.middleName;
                this.service.formData.lastName = this.datas.lastName;
                this.service.formData.fullName = this.datas.fullName;
                this.service.formData.gender = this.datas.gender;
                this.service.formData.marriedStatus = this.datas.marriedStatus;
                this.service.formData.dob = this.datas.dob;
                this.service.formData.birthCountryID = this.datas.birthCountryID;
                this.service.formData.birthPlace = this.datas.birthPlace;
                this.service.formData.email = this.datas.email;
                this.service.formData.religionID = this.datas.religionID;
                this.service.formData.spouseName = this.datas.spouseName;
                this.service.formData.firstLanguageID = this.datas.firstLanguageID;
                this.service.formData.secondLanguageID = this.datas.secondLanguageID;

                this.service.formData.motherName = this.datas.motherName;
                this.service.formData.motherNationalityID = this.datas.motherNationalityID;
                this.service.formData.motherPerviousNationalityID = this.datas.motherPerviousNationalityID;
                this.service.formData.motherBirthPlace = this.datas.motherBirthPlace;
                this.service.formData.motherBirthCountryID = this.datas.motherBirthCountryID;
                this.service.formData.fatherName = this.datas.fatherName;
                this.service.formData.fatherNationalityID = this.datas.fatherNationalityID;
                this.service.formData.fatherPerviousNationalityID = this.datas.fatherPerviousNationalityID;
                this.service.formData.fatherBirthPlace = this.datas.fatherBirthPlace;
                this.service.formData.fatherBirthCountryID = this.datas.fatherBirthCountryID;
                this.service.formData.jobCategoryID = this.datas.jobCategoryID;
                this.service.formData.jobCategoryOther = this.datas.jobCategoryOther;
                this.service.formData.jobTitle = this.datas.jobTitle;
                this.service.formData.employer = this.datas.employer;
                this.service.formData.arrivalAirpotPortName = this.datas.arrivalAirpotPortName;
                this.service.formData.ordinaryDocumentID = this.datas.ordinaryDocumentID;
                this.service.formData.passportNo = this.datas.passportNo;
                this.service.formData.passportIssueDate = this.datas.passportIssueDate; // moment(this.datas.passportIssueDate).toDate();
                this.service.formData.passportExpiryDate = this.datas.passportExpiryDate;
                this.service.formData.passportIssueCountryID = this.datas.passportIssueCountryID;

                this.service.formData.phoneNumber = this.datas.phoneNumber;
                this.service.formData.posteCode = this.datas.posteCode;
                this.service.formData.previousNationalityID = this.datas.previousNationalityID;
                this.service.formData.isNameChanged = this.datas.isNameChanged;
                this.service.formData.visibleIdentificationMark = this.datas.visibleIdentificationMark;
                this.service.formData.educationQualification = this.datas.educationQualification;
                this.service.formData.acquireNationalityBy = this.datas.acquireNationalityBy;
                this.service.formData.otherPassportIssueCountryID = this.datas.otherPassportIssueCountryID;
                this.service.formData.isOtherValidPassportIC = this.datas.isOtherValidPassportIC;
                this.service.formData.visitPlace1 = this.datas.visitPlace1;
                this.service.formData.visitPlace2 = this.datas.visitPlace2;
                this.service.formData.isVisitBefore = this.datas.isVisitBefore;
                this.service.formData.visitedPlaceBefore = this.datas.visitedPlaceBefore;
                this.service.formData.cityVisitedBefore = this.datas.cityVisitedBefore;


                this.service.formData.lastVisaNo = this.datas.lastVisaNo;
                this.service.formData.lastVisaType = this.datas.lastVisaType;
                this.service.formData.lastIssuePlace = this.datas.lastIssuePlace;
                this.service.formData.lastIssueDate = this.datas.lastIssueDate;
                this.service.formData.presentHouseNoStreet = this.datas.presentHouseNoStreet;
                this.service.formData.presentCity = this.datas.presentCity;
                this.service.formData.presentCountryID = this.datas.presentCountryID;
                this.service.formData.presentState = this.datas.presentState;
                this.service.formData.presentPostalCode = this.datas.presentPostalCode;
                this.service.formData.presentPhoneNo = this.datas.presentPhoneNo;
                this.service.formData.presentMobileNo = this.datas.presentMobileNo;
                this.service.formData.presentEmail = this.datas.presentEmail;
                this.service.formData.permanentHouseNoStreet = this.datas.permanentHouseNoStreet;
                this.service.formData.permanentCity = this.datas.permanentCity;
                this.service.formData.permanentState = this.datas.permanentState;

            });
    }


    //public getAllCountry = () => {
    //    this.servCountry.getService()
    //        .subscribe(res => {
    //            this.countryData = res as CountryModule[];
    //        })
    //}

    //public getAllNationality = () => {
    //    this.servNationality.getService()
    //        .subscribe(res => {
    //            this.nationalityData = res as NationalityModule[];
    //        })
    //}

    public getAllOrdinaryDocument = () => {
        this.servOrdinaryDocument.getService()
            .subscribe(res => {
                this.ordinaryDocumentData = res as OrdinaryDocumentModule[];
            })
    }


    //public getAllLanguage = () => {
    //    this.servLanguage.getService()
    //        .subscribe(res => {
    //            this.languageData = res as LanguageModule[];
    //        })
    //}

    //public getAllReligion = () => {
    //    this.servReligion.getService()
    //        .subscribe(res => {
    //            this.religionData = res as ReligionModule[];
    //        })
    //}




    serviceData2: OrderPersonModule = new OrderPersonModule();

    onSubmit(form: NgForm) {

        this.scrollToTop();

        if (this.dobActive || this.genderActive) {
            this.loader.open();

            var dates = formatDate(this.service.formData.dob, 'dd/MM/yyyy', 'en');

            this.servVisaType.getCheckWarnings(this.dobActive, this.genderActive, this.vid, this.nid, this.service.formData.gender, dates, this.SelectedLanguage)
                .subscribe(res => {
                    this.loader.close();
                    if (res == "") {
                        if (this.service.formData.orderPersonID == 0)
                            this.insertRecord(form);
                        else
                            this.updateRecord(form);
                    }
                    else {
                        let title = 'create';
                        let dialogRef: MatDialogRef<any> = this.dialog.open(RestrictionDialogComponent, {
                            width: '720px',
                            disableClose: true,
                            data: { title: title, payload: this.data, messageTXT: res }
                        })
                        dialogRef.afterClosed()
                            .subscribe(res => {
                                if (!res) {
                                    return;
                                }


                            })
                    }
                }, err => {
                    this.loader.close(); console.log(err);
                });


            
        }
        else {
            if (this.service.formData.orderPersonID == 0)
                this.insertRecord(form);
            else
                this.updateRecord(form);
        }


    }

    insertRecord(form: NgForm) {

   /*     this.loader.open();*/
        this.service.postService().subscribe(
            res => {
                this.serviceData2 = res as OrderPersonModule;
                this.toastr.success('Saved Successfully', '');

                this.ServSavedOrder.updateOrderStatus(this.vtid, this.nid, this.vid, this.rid, this.oid, this.email, this.dates, this.serviceData2.orderPersonID ,'Draft');

                this.router.navigate(['step4', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email, pid: this.serviceData2.orderPersonID }]);

                this.IsNew = false;
            },
            err => { console.log(err); }
        );
      //  this.loader.close();
    }



    updateRecord(form: NgForm) {
       // this.loader.open();
        this.service.putService().subscribe(
            res => {
                this.toastr.success('Saved Successfully', '');
                this.router.navigate(['step4', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email, pid: this.service.formData.orderPersonID }]);
            },
            err => { console.log(err); }
        );
     //   this.loader.close();
    }


    // *******************************************************************************************************************************************

    public countries: Country[];



    public countryCtrl: UntypedFormControl = new UntypedFormControl();
    public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

    protected filtercountries() {
        if (!this.countries) {
            return;
        }
        // get the search keyword
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the nobCategories
        this.filteredCountries.next(
            this.countries.filter(Country => Country.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAllcountry = () => {

        this.servCountry.getService()
            .subscribe(res => {
                //this.nobCategories = res as CountryModule[];
                this.countries = res as Country[];

                this.filteredCountries.next(this.countries.slice());

            })
    }


    // *******************************************************************************************************************************************


    public religions: Religion[];



    public religionCtrl: UntypedFormControl = new UntypedFormControl();
    public religionFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredReligions: ReplaySubject<Religion[]> = new ReplaySubject<Religion[]>(1);

    protected filterreligions() {
        if (!this.religions) {
            return;
        }
        // get the search keyword
        let search = this.religionFilterCtrl.value;
        if (!search) {
            this.filteredReligions.next(this.religions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the nobCategories
        this.filteredReligions.next(
            this.religions.filter(Religion => Religion.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }
    //Religion
    //religions
    //religion
    //Religions

    public getAllReligion = () => {

        this.servReligion.getService()
            .subscribe(res => {
                this.religions = res as Religion[];

                this.filteredReligions.next(this.religions.slice());

            })
    }

    // *******************************************************************************************************************************************

    public jobCategories: JobCategory[];



    public jobCategoryCtrl: UntypedFormControl = new UntypedFormControl();
    public jobCategoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredJobCategories: ReplaySubject<JobCategory[]> = new ReplaySubject<JobCategory[]>(1);

    protected filterjobCategories() {
        if (!this.jobCategories) {
            return;
        }
        let search = this.jobCategoryFilterCtrl.value;
        if (!search) {
            this.filteredJobCategories.next(this.jobCategories.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredJobCategories.next(
            this.jobCategories.filter(JobCategory => JobCategory.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAlljobCategory = () => {

        this.servJobCategory.getService()
            .subscribe(res => {
                this.jobCategories = res as JobCategory[];

                this.filteredJobCategories.next(this.jobCategories.slice());

            })
    }

    // *******************************************************************************************************************************************


    public languages: Language[];



    public languageCtrl: UntypedFormControl = new UntypedFormControl();
    public languageFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredlanguages: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);

    protected filterlanguages() {
        if (!this.languages) {
            return;
        }
        let search = this.languageFilterCtrl.value;
        if (!search) {
            this.filteredlanguages.next(this.languages.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredlanguages.next(
            this.languages.filter(Language => Language.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAlllanguage = () => {

        this.servLanguage.getService()
            .subscribe(res => {
                this.languages = res as Language[];

                this.filteredlanguages.next(this.languages.slice());

            })
    }


    // *******************************************************************************************************************************************

    public nationalities: Nationality[];



    public nationalityCtrl: UntypedFormControl = new UntypedFormControl();
    public nationalityFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public filteredNationalities: ReplaySubject<Nationality[]> = new ReplaySubject<Nationality[]>(1);

    protected filternationalities() {
        if (!this.nationalities) {
            return;
        }
        // get the search keyword
        let search = this.nationalityFilterCtrl.value;
        if (!search) {
            this.filteredNationalities.next(this.nationalities.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the nationalities
        this.filteredNationalities.next(
            this.nationalities.filter(Nationality => Nationality.nameEn.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAllnationality = () => {

        this.servNationality.getService()
            .subscribe(res => {
                //this.nationalities = res as nationalityModule[];
                this.nationalities = res as Nationality[];

                this.filteredNationalities.next(this.nationalities.slice());

            })
    }

    // *******************************************************************************************************************************************

    private datas3: any;
    FillVisaTypes() {

        this.servVisaType.getServiceId(this.vtid)
            .subscribe(res => {
                this.datas3 = res as VisaTypeModule[];



                this.servVisaType.formData.visaTypeID = this.datas3.visaTypeID;
                this.servVisaType.formData.nameAr = this.datas3.nameAr;
                this.servVisaType.formData.nameEn = this.datas3.nameEn;
                this.servVisaType.formData.notes = this.datas3.notes;
                this.servVisaType.formData.isVisible = this.datas3.isVisible;
                this.servVisaType.formData.visaCountryID = this.datas3.visaCountryID;
                this.servVisaType.formData.isJob = this.datas3.isJob;
                this.servVisaType.formData.orderPeriod = this.datas3.orderPeriod;
          
                this.getVisaCountry(this.servVisaType.formData.visaCountryID);

                this.servVisaType.formData.visaPeriod = this.datas3.visaPeriod;
                this.servVisaType.formData.visaValidPeriod = this.datas3.visaValidPeriod;

                this.stayDays = this.servVisaType.formData.visaPeriod.toString();



                const [day, month, year] = this.dates.split('/');

                this.fromDate = new Date(+year, +month - 1, +day);
                this.toDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth(), this.fromDate.getDate())

                var perd = (this.servVisaType.formData.visaPeriod - 1);
                this.toDate.setDate(this.toDate.getDate() + perd);

                if (this.SelectedLanguage == "en") {
                    this.visaTypeName = this.servVisaType.formData.nameEn;
                }
                else {
                    this.visaTypeName = this.servVisaType.formData.nameAr;
                }

             
            });
    }




    private datas2: any;
    getVisaCountry(countryID: number) {

        this.servVisaCountry.getServiceId(countryID)
            .subscribe(res => {
                this.datas2 = res as VisaCountryModule[];
                if (this.SelectedLanguage == "en") {
                    this.countryName = this.datas2.nameEn;
                }
                else {
                    this.countryName = this.datas2.nameAr;
                }

            })


        
        // this.countryName
    }


    private datas4: any;
    FillOrder() {

        this.servOrder.getServiceId(this.oid)
            .subscribe(res => {
                this.datas4 = res as OrderModule[];

                this.orderNo = this.datas4.orderNo;

            })

        // this.countryName
    }

    viewDialog(stringValue)
    {

        let title = 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(InputInfoDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, id: this.vtid, code: stringValue }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
            });
    }

    CancelBT() {
        this.router.navigate(['step5', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
    }

    scrollToTop() {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
    }
}
