<h2> {{"Restriction" | translate }}</h2>

<div class="payment-box">
    <form novalidate autocomplete="off" #form="ngForm">

        <div class="payment-box">

            <div [innerHtml]="PageContentHTML"></div>
           
            <br />
            <br />
        </div>
        <div>
            <button class="default-btnBlack" type="button" style="margin:20px; width:150px" (click)="dialogRef.close()">{{'Close' | translate }}</button>
        </div>

    </form>
</div>
