<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo">
                    <img src="../../../../assets/img/logo_Color.png" alt="image">
                </a>
                <h3>
                    مرحبا بكم في موقع حكاية لتأجير السيارات
                </h3>
                <h4>
                    موقعنا حاليا تحت الصيانة ولفترة مؤقتة
                </h4>
                <br />
                <h3>
                    Welcome to Hekaya Car Rental
                </h3>

                <h4>
                    Our website is under maintenance
                </h4>

                <br />
                <p>
                    يرجي التواصل معنا بشكل مباشر من خلال الأرقام التالية
                    <br />
                    Please contact us at
                </p>

                <a dir="ltr" href="tel:+96890996418">+968 90996418</a><br />
                <a dir="ltr" href="tel:+96898509742">+968 98509742</a>

                <p>
                    لحجز السيارات والاستفسارات الأخرى،   مستعدون لتلبية طلابتكم في خريف صلالة 2024
                    <br />
                    For your car rental request and inquiries.  Check out our service in khareef Salalah 2024

                </p>

                <p>

                    Hekaya and Route Teams
                </p>



            </div>
        </div>
    </div>
</div>
