export class RequestAttachmentModule {
    requestAttachmentID: number = 0;
    requestID: number = 0;
    attachmentTypeID: number = 0;
    filePath: string = '';
    type: string = '';
    notes: string = '';
}
export class VWRequestAttachmentTypeAndAttachmentTypeModule {
    requestAttachmentID: number = 0;
    requestID: number = 0;
    attachmentTypeID: number = 0;
    filePath: string = '';
    type: string = '';
    notes: string = '';
    attachmentTypeNameAr: string = '';
    attachmentTypeNameEn: string = '';
    descriptionAr: string = '';
    descriptionEn: string = '';
}
