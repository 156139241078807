import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { OrderPersonModule } from './order-person.module';

@Injectable({
  providedIn: 'root'
})
export class OrderPersonService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceOrder(OrderID: number): Observable<OrderPersonModule[]> {
        return this.http.get<OrderPersonModule[]>(environment.apiURL + 'Orders/OrderPerson/search/' + OrderID);
    }

    getServiceId(id: number): Observable<OrderPersonModule[]> {
        return this.http.get<OrderPersonModule[]>(environment.apiURL + 'Orders/OrderPerson/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Orders/OrderPerson/DeletePerson/?id=' + id).toPromise();
    }
     
    formData: OrderPersonModule = new OrderPersonModule();
    list: OrderPersonModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Orders/OrderPerson/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Orders/OrderPerson/' + this.formData.orderPersonID, this.formData);
    }


    getServiceVWOrder(OrderID: number): Observable<OrderPersonModule[]> {
        return this.http.get<OrderPersonModule[]>(environment.apiURL + 'Orders/VWOrderPerson/SearchCountry/?orderID=' + OrderID);
    }
}
