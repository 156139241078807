import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { RequestModule } from './request.module';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceId(id: number): Observable<RequestModule[]> {
        return this.http.get<RequestModule[]>(environment.apiURL + 'Agents/Request/' + id);
    }

    getService(): Observable<RequestModule[]> {
        return this.http.get<RequestModule[]>(environment.apiURL + 'Agents/Request/');
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Agents/Request/' + id).toPromise();
    }

    formData: RequestModule = new RequestModule();
    list: RequestModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Agents/Request/', this.formData);
    }

    putConvertToWaiting(requestID: number, status: string) {
        return this.http.get(environment.apiURL + "Agents/Request/ConvertToWaiting/?requestID=" + requestID + "&status=" + status , { responseType: 'text' });
    }

}
