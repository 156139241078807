export class NationalityModule {
    nationalityID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    notes: string = '';
}

export interface Nationality {
    nationalityID: number ;
    nameAr: string;
    nameEn: string;
    notes: string;
}
