import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { VWOrderPersonAttachmentModule } from '../../../appshared/orders/order-person-attachment/order-person-attachment.module';
import { OrderPersonAttachmentService } from '../../../appshared/orders/order-person-attachment/order-person-attachment.service';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';
import { VWVisaTypeAttachmentTypeAndAttachmentTypeModule, VWVisaTypeAttachmentTypeOrderPersonAttachment } from '../../../appshared/visa/visa-type-attachment-type/visa-type-attachment-type.module';
import { VisaTypeAttachmentTypeService } from '../../../appshared/visa/visa-type-attachment-type/visa-type-attachment-type.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { FailedDialogComponent } from '../step1-visa-type/failed-dialog/failed-dialog.component';
import { PersonalAttachmentDialogComponent } from './personal-attachment-dialog/personal-attachment-dialog.component';

@Component({
  selector: 'app-step4-attachment',
  templateUrl: './step4-attachment.component.html',
  styleUrls: ['./step4-attachment.component.scss']
})
export class Step4AttachmentComponent implements OnInit {

    constructor(public dialog: MatDialog, public translate: TranslateService, private route: ActivatedRoute, private servPersonAttachment: OrderPersonAttachmentService, private servAttachment: VisaTypeAttachmentTypeService, private toastr: ToastrService, private router: Router, private loader: AppLoaderService, public servOrder: OrderService, private ServSavedOrder: SavedOrderService)
    {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
        if (this.SelectedLanguage == "ar") {
            this.displayedColumnsAttachment = ['num', 'attachmentTypeNameAr', 'filePath', 'actions'];
        }
    }

    public SelectedLanguage: string = "En";
    public SelectedCurrID: number = 1;
    private sub: any;
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    pid: number = 0;
    countAttachment: number = 0;
    dates: string = "";
    email: string = "";
    public apiURL: string = "";
    isDone: boolean = false;

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.vtid = +params['vtid'];
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.oid = +params['oid'];
            this.pid = +params['pid'];
            this.email = params['email'];
            this.dates = params['date'];
        });
        this.apiURL = environment.apiUrl2 + 'Resources/Attachment/Order/';
        this.getAttachmentTable();
        this.gnCheckOrderValidity();
    }


    public dataSourceAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
    public dataSourceFirstAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
    public dataSourceVisaTypeAttachment = new MatTableDataSource<VWVisaTypeAttachmentTypeAndAttachmentTypeModule>();
    //public dataSourcePersonAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
    displayedColumnsAttachment = ['num', 'attachmentTypeNameEn', 'filePath', 'actions'];

    getAttachmentTable() {

        this.loader.open();
        this.dataSourceFirstAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
        this.servPersonAttachment.getServiceVWOrderPerson(this.pid, 'Client Attachment')
            .subscribe(res => {
                this.dataSourceFirstAttachment.data = res as VWOrderPersonAttachmentModule[];
            
                this.countAttachment = this.dataSourceFirstAttachment.data.length;
                this.fillData();

              


                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                this.countAttachment = 0;  this.fillData();})


    }




    async fillData() {
        this.dataSourceVisaTypeAttachment = new MatTableDataSource<VWVisaTypeAttachmentTypeAndAttachmentTypeModule>();
        this.dataSourceAttachment = new MatTableDataSource<VWOrderPersonAttachmentModule>();
        this.servAttachment.getServiceVWVisaType(this.vtid)
            .subscribe(res => {
                this.dataSourceVisaTypeAttachment.data = res as VWVisaTypeAttachmentTypeAndAttachmentTypeModule[];

                for (var i = 0; i < this.dataSourceVisaTypeAttachment.data.length; i++) {

                    var isAdd = true;
                    for (var l = 0; l < this.dataSourceFirstAttachment.data.length; l++) {

                        if (this.dataSourceFirstAttachment.data[l].attachmentTypeID == this.dataSourceVisaTypeAttachment.data[i].attachmentTypeID) {
                            isAdd = false;
                        }
                    }
                    if (isAdd) {
                        this.dataSourceFirstAttachment.data.push({ attachmentTypeID: this.dataSourceVisaTypeAttachment.data[i].attachmentTypeID, attachmentTypeNameAr: this.dataSourceVisaTypeAttachment.data[i].attachmentTypeNameAr, attachmentTypeNameEn: this.dataSourceVisaTypeAttachment.data[i].attachmentTypeNameEn, filePath: '', firstName: '', fullName: '', lastName: '', notes: '', orderID: this.oid, orderPersonAttachmentID: 0, orderPersonID: this.pid, type: 'Client Attachment', descriptionAr: this.dataSourceVisaTypeAttachment.data[i].attachDescriptionAr, descriptionEn: this.dataSourceVisaTypeAttachment.data[i].attachDescriptionEn })
                    }
                }


                this.dataSourceAttachment.data = this.dataSourceFirstAttachment.data;
                this.CheckIfDone();

            },
                err => { this.CheckIfDone(); })
    }

    CheckIfDone() {
        if (this.dataSourceVisaTypeAttachment.data.length <= this.countAttachment) {
            this.isDone = true;
            this.ServSavedOrder.updateOrderStatus(this.vtid, this.nid, this.vid, this.rid, this.oid, this.email, this.dates, 0, 'Draft');
        }
        else {

            this.isDone = false;
        }
    }


    data: any = {};
    openAttachmentPopUp(data: any = {}, isNew?) {
        let title = isNew ? 'add' : 'create';
        let dialogRef: MatDialogRef<any> = this.dialog.open(PersonalAttachmentDialogComponent, {
            width: '720px',
            disableClose: true,
            data: { title: title, payload: data, lid: this.pid  }
        })
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                if (isNew) {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');

                } else {
                    this.getAttachmentTable();
                    //this.loader.close();
                    this.toastr.success('Saved Successfully', '');
                }
            })
    }


    Add_Extra_PersonBT() {
        this.router.navigate(['step3', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
    }

    GoNextBT() {
        this.router.navigate(['step5', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
    }


    checkMessage: string = "";
    datas: any = {};
    gnCheckOrderValidity() {
        this.servOrder.getCheckOrder(this.oid, this.SelectedLanguage)
            .subscribe(res => {
                this.checkMessage = res;


                this.loader.close();

                if (this.checkMessage == "success") {

                }
                else {


                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.datas, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return;
                            }

                            this.router.navigate(['home']);

                        })
                }

            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }
}
