import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RequestModule } from '../../../appshared/agent/request/request.module';
import { RequestService } from '../../../appshared/agent/request/request.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-new-agent-request',
  templateUrl: './new-agent-request.component.html',
  styleUrls: ['./new-agent-request.component.scss']
})
export class NewAgentRequestComponent implements OnInit {
    serviceData2: RequestModule = new RequestModule();


    constructor(public service: RequestService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private toastr: ToastrService, private loader: AppLoaderService) { }

    ngOnInit(): void {
        this.service.formData = new RequestModule();
  }
    onSubmit(form: NgForm) {
        if (!this.validateEmail(this.service.formData.email)) {
            return;
        }

        if (this.service.formData.requestID == 0)
            this.service.formData.status = "Draft";
            this.insertRecord(form);
    }

    insertRecord(form: NgForm) {

        this.loader.open();
        this.service.postService().subscribe(
            res => {
                this.serviceData2 = res as RequestModule;
                this.toastr.success('Saved Successfully', '');
                this.router.navigate(['new-agent-request-attachment', { rid: this.serviceData2.requestID }]);

                this.loader.close();
            },
            err => {
                this.loader.close(); console.log(err); }
        );
    }
    validateEmail(email) {
        var emailTest = true;
        if (email != '') {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(String(email).toLowerCase()))
            emailTest = re.test(String(email).toLowerCase())
            if (emailTest != true) {
                this.toastr.warning("Please enter valid email")

            }

        }
        return emailTest;
    }

}
