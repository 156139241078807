import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { VisaTypeAttachmentTypeModule, VWVisaTypeAttachmentTypeAndAttachmentTypeModule, VWVisaTypeAttachmentTypeOrderPersonAttachment } from './visa-type-attachment-type.module';

@Injectable({
  providedIn: 'root'
})
export class VisaTypeAttachmentTypeService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }
     

    getServiceVisaType(VisaTypeID: number): Observable<VisaTypeAttachmentTypeModule[]> {
        return this.http.get<VisaTypeAttachmentTypeModule[]>(environment.apiURL + 'Visa/VisaTypeAttachmentType/search/' + VisaTypeID);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Visa/VisaTypeAttachmentType/' + id).toPromise();
    }

    formData: VisaTypeAttachmentTypeModule = new VisaTypeAttachmentTypeModule();

    formData2: VWVisaTypeAttachmentTypeOrderPersonAttachment = new VWVisaTypeAttachmentTypeOrderPersonAttachment();
    list: VisaTypeAttachmentTypeModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Visa/VisaTypeAttachmentType/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Visa/VisaTypeAttachmentType/' + this.formData.visaTypeAttachmentTypeID, this.formData);
    }


    getServiceVW(): Observable<VWVisaTypeAttachmentTypeAndAttachmentTypeModule[]> {
        return this.http.get<VWVisaTypeAttachmentTypeAndAttachmentTypeModule[]>(environment.apiURL + 'Visa/VWVisaTypeAttachmentTypeAndAttachmentType/');
    }


    getServiceVWVisaType(VisaTypeID: number): Observable<VWVisaTypeAttachmentTypeAndAttachmentTypeModule[]> {
        return this.http.get<VWVisaTypeAttachmentTypeAndAttachmentTypeModule[]>(environment.apiURL + 'Visa/VWVisaTypeAttachmentTypeAndAttachmentType/search/' + VisaTypeID);
    }



    GetAttachmentVWVisaType(VisaTypeID: number, OrderPersonID: number): Observable<VWVisaTypeAttachmentTypeOrderPersonAttachment[]> {
        return this.http.get<VWVisaTypeAttachmentTypeOrderPersonAttachment[]>(environment.apiURL + 'Visa/VWVisaTypeAttachmentTypeOrderPersonAttachment/GetAttachment/?visaTypeID=' + VisaTypeID + '&orderPersonID=' + OrderPersonID );
    }
}
