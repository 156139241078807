<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="0" dir="ltr" style="margin-left:-35px">
            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false" disable>
                <ng-template cdkStepLabel disable>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>
    </div>
</div>




<section class="listing-area ptb-100">

    <div class="container">
        <div class="section-title">
            <!--<span class="sub-title">Discover</span>-->
            <h3>{{'Select_your_Visa_Type' | translate }}</h3>
            <h2></h2>
        </div>
    </div>

    <div class="container">

        <div class="card recent-orders-box" style="padding:15px" *ngIf="visaCountryForm.isNotification">
            <div class="card-body">
                <div class="blog-details-desc text-center" style="margin-top:-20px">
                    <div class="article-content">


                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-info-circle text-primary'></i>
                                    <span>
                                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then notificationAr  ; else notificationEn">
                                        </ng-template>
                                        <ng-template #notificationAr>{{ visaCountryForm.notificationAr }}</ng-template>
                                        <ng-template #notificationEn>{{ visaCountryForm.notificationEn }}</ng-template>
                                    </span>

                                </li>
                            </ul>

                        </div>
                    </div>
                </div>




            </div>
        </div>

        <br />
        <br />



        <div class="card recent-orders-box" style="padding:15px" *ngIf="warningNoVisa">
            <div class="card-body">
                <div class="blog-details-desc text-center" style="margin-top:-20px">
                    <div class="article-content">


                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-info-circle text-danger'></i>
                                    <span class="text-danger'" style="color: red">
                                        {{'VisaTypeNotFindTXT' | translate }}
                                    </span>

                                </li>
                            </ul>

                        </div>
                    </div>
                </div>




            </div>
        </div>

        <div class="row">


            <!--<div class="card recent-orders-box" style="padding:15px" *ngIf="warningNoVisa">

                <div class="card-body">-->
                    <!--<h3> {{"Restriction" | translate }}</h3>-->
                    <!--<p style="color: red"><i class='bx bx-info-circle text-primary'></i> {{'VisaTypeNotFindTXT' | translate }}</p>



                </div>
            </div>-->




            <div class="col-lg-4 col-sm-12 col-md-6" *ngFor="let item of dataSource.data">

                <div class="single-listing-item" style="min-height: 300px; border: 1px solid black; border-top-left-radius: 50px; border-top-right-radius: 50px; border-bottom-left-radius: 50px; border-bottom-right-radius: 50px; ">
                    <div class="listing-content">

                        <div class="d-flex align-items-center justify-content-between">
                            <h3 style="font-size:17px">
                                <a href="javascript:void(0);" (click)="SelectedType(item)" class="d-inline-block">

                                    <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                    </ng-template>
                                    <ng-template #other_contentAr>{{ item.nameAr }}</ng-template>
                                    <ng-template #other_contentEn>{{ item.nameEn }}</ng-template>


                                </a>


                            </h3>
                            <a (click)="viewInfoDialog(item)" href="javascript:void(0);" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>

                        </div>

                        <hr />
                        <!--<span style="font-size:13px">
                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_description_contentAr  ; else other_description_contentEn">
                        </ng-template>
                        <ng-template #other_description_contentAr>{{ item.descriptionAr }}</ng-template>
                        <ng-template #other_description_contentEn>{{ item.descriptionEn }}</ng-template>
                    </span>-->


                        <h3 style="font-size:15px">







                            {{'Visa_Details' | translate }}
                        </h3>

                        <div class="listing-sidebar-widget" style="margin-left:0px;">
                            <div class="listing-opening-hours">
                                <ul style="margin-left:0px;">
                                    <li style="margin-bottom: 0px; padding-bottom: 0px; color: black">
                                        <span style=" color: dimgray"><i class='bx bx-time' style=" color: dimgray"></i>{{'Duration_of_stay' | translate }}</span>
                                        {{ item.visaPeriod }}
                                        {{'Days' | translate }}
                                    </li>
                                    <li style="margin-bottom: 0px; padding-bottom: 0px; color: black ">
                                        <span style=" color: dimgray"><i class='bx bx-calendar-check' style=" color: dimgray"></i>{{'Entry_type' | translate }}</span>
                                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then entry_typeAr  ; else entry_typeEn">
                                        </ng-template>
                                        <ng-template #entry_typeAr>{{ item.entryTypeAr }}</ng-template>
                                        <ng-template #entry_typeEn>{{ item.entryTypeEn }}</ng-template>
                                    </li>
                                    <li style="margin-bottom: 0px; padding-bottom: 0px; color: black ">
                                        <span style=" color: dimgray"><i class='bx bx-check-circle' style=" color: dimgray"></i>{{'Validity' | translate }}</span>
                                        {{ item.visaValidPeriod }}

                                        {{'Days' | translate }}
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <hr style="margin-top:-10px" />


                        <h3 style="font-size:15px">
                            {{'Processing_details' | translate }}
                        </h3>

                        <div class="listing-sidebar-widget" style="margin-left:0px;">
                            <div class="listing-opening-hours">

                                <ul style="margin-left:0px;">
                                    <li style="margin-bottom: 0px; padding-bottom: 0px; color: black">
                                        <span style=" color: dimgray"><i class='bx bx-cog' style=" color: dimgray"></i>{{'As_fast_as' | translate }}</span>
                                        {{ item.businessDays }}
                                        {{'Business_Days' | translate }}
                                    </li>
                                    <li style="margin-bottom: 0px; padding-bottom: 0px; color: black ">
                                        <span style=" color: dimgray"><i class='bx bx-calendar-plus' style=" color: dimgray"></i>{{'Extension' | translate }}</span>
                                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then extension_Ar  ; else extension_En">
                                        </ng-template>
                                        <ng-template #extension_Ar>{{ item.extensionAr }}</ng-template>
                                        <ng-template #extension_En>{{ item.extensionEn }}</ng-template>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <hr style="margin-top:-10px" />
                        <div class="listing-sidebar-widget" style="margin-top:0px;">
                            <div class="d-flex align-items-center justify-content-between">
                                <a href="javascript:void(0);" (click)="SelectedType(item)" class="price">

                                    <span data-toggle="tooltip" data-placement="top" style="color: black">
                                        {{'Price' | translate }} :  {{item.pricePerPerson | number:'1.3-3' }} {{ CurrencyName }} {{'Per_Person' | translate }}
                                    </span>


                                </a>

                                <a (click)="viewDialog(item)" href="javascript:void(0);" class="input-group-text"> <i class='bx bx-info-circle'></i> </a>


                            </div>
                        </div>

                        <br />
                        <br />
                        <br />
                    </div>
                    <div class="listing-box-footer" style=" position: absolute; bottom: 5px; width: 100%;">
                        <div class="d-flex align-items-center justify-content-between">


                            <button class="default-btnWhite btn_mobile" style="width: 100%;" type="button" (click)="SelectedType(item)">{{'Apply_Now' | translate }}</button>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>

</section>
