export class VisaTypePriceGroupModule {
    visaTypePriceGroupID: number = 0;
    visaTypeID: number = 0;
    fromNo: number = 0;
    toNo: number = 0;
    discountPercentage: number = 0;
    costPerPerson: number = 0;
    isClient: boolean = true;
    isAgent: boolean = true;
    isValid: boolean = true;
    validFromDate: Date = new Date();
    validToDate: Date = new Date();
 }
