<app-header-one></app-header-one>

<!--<div class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>-->
    <div  class="main-banner-img main-banner banner-bg1 jarallax">

        <app-home-search></app-home-search>

        <div class="main-banner-content2">
            <h1>{{'Nakhal_Fort' | translate }}</h1>


        </div>
    </div>

    <section class="about-area">
        <div class="container">
            <div class="about-inner-area">
                <div>
                    <div class="welcome-text">
                        <h2>{{'Want_to' | translate }} <span class="title-color">{{'Visit_Oman_and_Concern_about_your_Visa' | translate }} </span></h2>
                        <p>{{'Apply_for_Oman_Visa_with_us' | translate }} </p>
                        <br />
                    </div>


                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-4">
                            <div class="single-listing-category">
                                <a>
                                    <i class='bx bx-trip'></i>
                                    {{'Tourist_Visa' | translate }}
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-md-4">
                            <div class="single-listing-category">
                                <a>
                                    <i class='bx bx-walk'></i>
                                    {{'Sponsored_Visa_Visit_Tourist_work' | translate }}
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-md-4">
                            <div class="single-listing-category">
                                <a>
                                    <i class='bx bxs-plane-alt'></i>
                                    {{'Other_Visas' | translate }}

                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>

    <!--<section class="listing-area extra-pt pb-md-1">


        <div class="container">
            <div class="row bot-margin">
                <div class="text-justify">

                    <h3 class="main-text"> Want to Visit Oman and Concern about your Oman?</h3>
                        <p> Apply for Oman Visa with us: </p>


                </div>
            </div>



        </div>
    </section>-->
    <!--<section class="listing-area extra-pt pb-md-1">
        <div class="container">


            <div class="row bot-margin">
                <div class="col-md-6 col-lg-6 col-sm-12 text-justify">

                    <h1 class="main-text">Want to Visit Oman and Concern about your Oman? </h1>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 text-justify">
                    <h3>Apply for Oman Visa with us:</h3>
                    <div class="blog-details-desc">
                        <div class="article-content">
                            <ul class="features-list">
                                <li><i class='bx bx-badge-check'></i> Tourist Visa</li>
                                <li><i class='bx bx-badge-check'></i> Sponsored Visa (Visit, Tourist, work) </li>
                                <li><i class='bx bx-badge-check'></i> Other Visas </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </section>-->


    <app-funfacts></app-funfacts>
    <app-process></app-process>



    <section class="listing-area extra-pt pb-100">

        <div class="container">
            <div class="row welcome_margin_top">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-8 intro row_mar">
                    <h2> {{'Why_Hekaya_Visa' | translate }}</h2>

                    <!--<p class="intro-text">
                        Are you thinking about a long weekend getaway, a 2-week trip, extended travel, or something in between? Depending on where you live and the amount of time you have to travel, certain destinations will make more sense than others.
                    </p>-->
                </div>
                <div class="col-lg-2"></div>
            </div>
            <div class="row welcome_margin_top">
                <div class="col-lg-5" dir="rtl">
                    <div class="list_div">
                        <h4>{{'All_Online' | translate }}</h4>
                        <p>
                            {{'You_can_apply_from_your_home' | translate }}
                        </p>
                        <br />
                        <!--<i aria-hidden="true" class="ic_float bx bxs-user ic_siz1"></i>
                        <h6 class="list_welcome">Our Customers always 100 % <br>Satisfied</h6>-->
                    </div>
                    <div class="list_div">
                        <!--<i aria-hidden="true" class="ic_float bx bxs-note ic_siz1"></i>
                        <h6 class="list_welcome">We Provide easier &amp; faster <br> Bookings </h6>-->
                        <div class="content" dir="rtl">
                            <h4>{{'All_what_is_required' | translate }} </h4>
                            <p>
                                {{'All_what_is_required_TXT' | translate }}
                            </p><br />
                        </div>
                    </div>
                    <div class="list_div">
                        <div class="content" dir="rtl">
                            <h4>{{'Easy_to_apply' | translate }} </h4>
                            <p>
                                {{'Easy_to_apply_TX' | translate }}

                            </p><br />
                        </div>
                    </div>
                    <div class="list_div">
                        <div class="content" dir="rtl">
                            <h4>{{'Easy_to_track' | translate }} </h4>
                            <p>
                                {{'Easy_to_track_TXT' | translate }}

                            </p><br />
                        </div>
                    </div>
                    <div class="list_div" dir="rtl">
                        <div class="content" dir="rtl">
                            <h4>{{'Trust_and_Support' | translate }}  </h4>
                            <p>
                                {{'Trust_and_Support_TXT' | translate }}


                            </p><br />
                        </div>
                    </div>
                </div>
                <div class="col-lg-7"><img src="assets/img/Home_Why.jpg"></div>
            </div>
        </div>









        <!--<div class="container">
            <div class="section-title">
                <span class="sub-title">{{'Discover' | translate }}</span>
                <h2>{{'Why_Hekaya_Visa' | translate }}</h2>
            </div>


            <div class="row">
                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-tap"></i>
        </div>-->
        <!--<div class="content">
                    <h3>{{'All_Online' | translate }}</h3>
                    <p>
                        {{'You_can_apply_from_your_home' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6 col-md-6">
            <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-find"></i>
        </div>-->
        <!--<div class="content">
                    <h3>{{'All_what_is_required' | translate }} </h3>
                    <p>
                        {{'All_what_is_required_TXT' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-explore"></i>
        </div>-->
        <!--<div class="content">
                    <h3>{{'Easy_to_apply' | translate }} </h3>
                    <p>
                        {{'Easy_to_apply_TX' | translate }}

                    </p>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-explore"></i>
        </div>-->
        <!--<div class="content">
                    <h3>{{'Easy_to_track' | translate }} </h3>
                    <p>
                        {{'Easy_to_track_TXT' | translate }}

                    </p>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-explore"></i>
        </div>-->
        <!--<div class="content">
                    <h3>{{'Trust_and_Support' | translate }}  </h3>
                    <p>
                        {{'Trust_and_Support_TXT' | translate }}


                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6 col-md-6">
            <div class="single-process-box">-->
        <!--<div class="icon">
            <i class="flaticon-tap"></i>
        </div>-->
        <!--<div class="content">
                            <h3>{{'All_Online' | translate }}</h3>
                            <p>
                                {{'You_can_apply_from_your_home' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>-->


    </section>

    <div class="main-banner banner-bghomefooter1 jarallax" data-jarallax='{"speed": 0.3}'>

        <div class="main-banner-content2">
            <h1>{{'Jebel_Akhdar' | translate }}</h1>


        </div>
    </div>




