<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>{{'Payment' | translate }}</h2>
            <p style="color:azure"></p>
        </div>
    </div>
</div>




<section class="contact-area ptb-70 bg-light">
    <div class="container">


        <div class="cart-totals" [hidden]="!isSuccess">


            <h3 class="text-center text-success">{{'Payment_Successful' | translate }}</h3>
            <h1 class="text-center text-success"><i class='bx bx-check-circle'></i></h1>
            <p class="text-center">{{'Thank_you_for_Payment' | translate }}</p>
            <ul>
                <li>{{'Order_No' | translate }} <span>{{ orderNo }}</span></li>
                <li>{{'Details' | translate }} <span>{{ itemDetails }}</span></li>

                <li>{{'Amount_Paid' | translate }} <span>{{ amountPaid | number:'1.3-3' }}</span></li>
            </ul>

            <button class="default-btnBlack" style="margin:20px" (click)="goHome()">{{"Go_to_Home" | translate }}</button>
        </div>




        <div class="cart-totals" [hidden]="!isFaild">


            <h3 class="text-center text-danger">{{'Faild_Payment' | translate }}</h3>
            <h1 class="text-center text-danger"><i class='bx bxs-x-circle'></i></h1>
            <p class="text-center">{{'Please_Try_Again' | translate }}</p>
            <!--<ul>
                <li>{{'Order_No' | translate }} <span>{{ orderNo }}</span></li>
                <li>{{'Details' | translate }} <span>{{ itemDetails }}</span></li>

                <li>{{'Amount_Paid' | translate }} <span>{{ amountPaid }}</span></li>
            </ul>-->

            <button class="default-btnBlack" style="margin:20px; width:150px" (click)="goHome()">{{"Go_to_Home" | translate }}</button>
        </div>

    </div>
</section>
