import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { Country, CountryModule } from './country.module';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<CountryModule[]> {
        return this.http.get<CountryModule[]>(environment.apiURL + 'Master/Country/CountryWeb/');
    }


    getService2(): Observable<Country[]> {
        return this.http.get<Country[]>(environment.apiURL + 'Master/Country/CountryWeb/');
    }

  

    formData: CountryModule = new CountryModule();
    list: CountryModule[];

   
}
