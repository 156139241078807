import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { VisaCountryModule } from './visa-country.module';

@Injectable({
  providedIn: 'root'
})
export class VisaCountryService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceWithouOman(): Observable<VisaCountryModule[]> {
        return this.http.get<VisaCountryModule[]>(environment.apiURL + 'Visa/VisaCountry/WhithoutOman/');
    }

    getService(): Observable<VisaCountryModule[]> {
        return this.http.get<VisaCountryModule[]>(environment.apiURL + 'Visa/VisaCountry/');
    }


    getServiceId(id: number): Observable<VisaCountryModule[]> {
        return this.http.get<VisaCountryModule[]>(environment.apiURL + 'Visa/VisaCountry/' + id);
    }

  
    formData: VisaCountryModule = new VisaCountryModule();
    list: VisaCountryModule[];

  
}
