import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

import { AgentRequestAttachmentTypeModule, VWAgentRequestAttachmentType, VWAgentRequestAttachmentTypeAgentRequestAttachment } from './agent-request-attachment-type.module';

@Injectable({
  providedIn: 'root'
})
export class AgentRequestAttachmentTypeService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Agents/AgentRequestAttachmentType/' + id).toPromise();
    }

    formData: AgentRequestAttachmentTypeModule = new AgentRequestAttachmentTypeModule();
    list: AgentRequestAttachmentTypeModule[];

    formData2: VWAgentRequestAttachmentTypeAgentRequestAttachment = new VWAgentRequestAttachmentTypeAgentRequestAttachment();
    postService() {
        return this.http.post(environment.apiURL + 'Agents/AgentRequestAttachmentType/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Agents/AgentRequestAttachmentType/' + this.formData.agentRequestAttachmentTypeID, this.formData);
    }


    getServiceVW(): Observable<VWAgentRequestAttachmentType[]> {
        return this.http.get<VWAgentRequestAttachmentType[]>(environment.apiURL + 'Agents/VWAgentRequestAttachmentType/');
    }



    GetAttachmentVWVisaType(requestID: number): Observable<VWAgentRequestAttachmentTypeAgentRequestAttachment[]> {
        return this.http.get<VWAgentRequestAttachmentTypeAgentRequestAttachment[]>(environment.apiURL + 'Agents/VWVisaTypeAttachmentTypeOrderPersonAttachment/GetAttachment/?requestID=' + requestID);
    }
}
