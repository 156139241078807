import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-complete-draft-dialog',
  templateUrl: './complete-draft-dialog.component.html',
  styleUrls: ['./complete-draft-dialog.component.scss']
})
export class CompleteDraftDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        public dialogRef: MatDialogRef<CompleteDraftDialogComponent>, private service: OrderService, private ServSavedOrder: SavedOrderService, public translate: TranslateService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    public SelectedLanguage: string = "en";
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    pid: number = 0;
    dates: string = "";
    email: string = "";



    orderNo: number = 0;
    arrivalDate: Date = new Date();
    visitCountry: string = "";
    visaType: string = "";
    nationality: string = "";

    ngOnInit(): void {

        this.vtid = this.data.vtid;
        this.nid = this.data.nid;
        this.vid = this.data.vid;
        this.rid = +this.data.rid;
        this.oid = +this.data.oid;
        this.pid = +this.data.pid;
        this.email = this.data.email;
        this.dates = this.data.dates;
        this.service.VWformData = this.data.vwOrder;

        this.arrivalDate = this.service.VWformData.arrivalDate;
        this.orderNo = this.service.VWformData.orderNo;

        if (this.SelectedLanguage == "ar") {
            this.visaType = this.service.VWformData.visaTypeNameAr;
            this.visitCountry = this.service.VWformData.visaCountryNameAr;
            this.nationality = this.service.VWformData.nationalityNameAr;
        }
        else {
            this.visaType = this.service.VWformData.visaTypeNameEn;
            this.visitCountry = this.service.VWformData.visaCountryNameEn;
            this.nationality = this.service.VWformData.nationalityNameEn;
        }

    }


    Continue() {
        if (this.pid == 0) {
            this.router.navigate(['step5', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
        }
        else {
            this.router.navigate(['step4', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email, pid: this.pid}]);
        }
        this.dialogRef.close();
    }


    Forget() {

        this.ServSavedOrder.removeOrder(this.service.VWformData.orderID);

        this.dialogRef.close();
    }


}
