import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-visa-info-dialog',
  templateUrl: './visa-info-dialog.component.html',
  styleUrls: ['./visa-info-dialog.component.scss']
})
export class VisaInfoDialogComponent implements OnInit {
    public SelectedLanguage: string = "en";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,

        public dialogRef: MatDialogRef<VisaInfoDialogComponent>, public translate: TranslateService, private loader: AppLoaderService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit() {
        this.buildItemForm(this.data.id, this.data.code)
    }

    public details: string = "";
    private datas3: any;

    buildItemForm(visaTypeId: number, code: string) {
        if (this.SelectedLanguage == "en") {
            this.details = this.data.detailsEn;
        }
        else {
            this.details = this.data.detailsAr;


        }


    }

}
