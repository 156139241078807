<app-header-one></app-header-one>


<div class="page-title-area page-title-bg1">
    <div class="container">

        <ng-stepper #cdkStepper [linear]="true" selectedIndex="4" dir="ltr" style="margin-left:-35px">
            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title" style="color:white">{{'Visa_Type' | translate }} </div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title" style="color:white">{{'Basic_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">3</div>
                    <div class="step-title" style="color:white">{{'Personal_Details' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [completed]="true" [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">4</div>
                    <div class="step-title" style="color:white">{{'Review' | translate }}</div>
                </ng-template>
            </cdk-step>

            <cdk-step [editable]="false">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">5</div>
                    <div class="step-title" style="color:white">{{'Payment' | translate }}</div>
                </ng-template>
            </cdk-step>
        </ng-stepper>

    </div>
</div>



<section class="contact-area ptb-70 bg-light">
    <div class="container">
        <div class="card recent-orders-box" style="padding:15px">
            <div class="card-body">

                <h3>{{'Payment' | translate}}</h3>
                <br />
                <form autocomplete="off" #userlogin="ngForm">
                    <div class="row">


                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Order_No' | translate }}</label>
                            <input type="text" class="form-control border" name="orderNo" #orderNo="ngModel" [(ngModel)]="service.VWformData.orderNo" disabled>
                        </div>


                        <div class="col-xs-12 col-12 col-md-6 form-group">
                            <label>{{'Arrival_Date' | translate }}</label>
                            <input type="text" class="form-control border" name="arrivalDate" value=" {{service.VWformData.arrivalDate | date  : 'dd/MM/yyyy'}}" disabled>
                        </div>

                    </div>
                </form>
                <br />


                <form #detailsForm="ngForm">
                    <div class="cart-table table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" style="padding: 5px">{{'Details' | translate }}</th>
                                    <th scope="col" style="padding: 5px">{{'Price' | translate }}</th>
                                    <th scope="col" style="padding: 5px">{{'Quantity' | translate }}</th>
                                    <th scope="col" style="padding: 5px">{{'Total' | translate }}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td class="product-name" style="padding: 5px">
                                        <span class="unit-amount">{{ itemDetails }}</span>
                                    </td>

                                    <td class="product-price" style="padding: 5px">
                                        <span class="unit-amount">{{ priceCurr | number:'1.3-3' }}</span>
                                    </td>

                                    <td class="product-quantity" style="padding: 5px">
                                        <span class="unit-amount">{{ quantity }}</span>
                                    </td>

                                    <td class="product-subtotal" style="padding: 5px">
                                        <span class="unit-amount">{{ subtotalCurr | number:'1.3-3' }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </form>
                <br />
                <div class="row">
                    <div class="col-lg-4 col-sm-7 col-md-4">
                        <fieldset [disabled]="isDisabledVoucher">
                            <p style="text-align: justify; font-weight:bold">{{ 'SaveOnYourApplication' | translate }}</p>

                        </fieldset>
                    </div>
                </div>
                        <div class="row">
                            <div class="col-lg-4 col-sm-7 col-md-4">
                                <fieldset class="main-search-wrap2" style="border: solid 2px; border-color: dimgray; border-radius: 15px; " [disabled]="isDisabledVoucher">
                                    <form (ngSubmit)="onClickVoucher(voucherCode.value)">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label><i class='bx bxs-discount'></i></label>
                                                    <input type="text" placeholder="{{'Voucher_Code' | translate }}" name="voucherCode" ngModel #voucherCode>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="main-search-btn2">
                                            <button type="submit">{{'Submit' | translate }}</button>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>


                        <div class="cart-totals">
                            <h3>{{'Totals' | translate }}</h3>

                            <ul>
                                <li>{{'Subtotal' | translate }} <span>{{ subtotalCurr | number:'1.3-3' }}</span></li>
                                <li>{{'Group_Discount' | translate }} <span>{{ groupDiscountCurr | number:'1.3-3' }}</span></li>
                                <li>{{'Voucher' | translate }} <span>{{ voucherAmountCurr | number:'1.3-3' }}</span></li>
                                <li>{{'VAT' | translate }} <span>{{ vatCurr | number:'1.3-3' }}</span></li>
                                <li [ngClass]="{'hide':(insuranceAmount == 0)}">{{'Insurance' | translate }}  <a (click)="viewInsuranceDialog()" href="javascript:void(0);" class="input-group-text" style="width: 40px; display: inline-block; padding:1px; margin:1px"> <i class='bx bx-info-circle'></i> </a><span>{{ insuranceAmountCurr | number:'1.3-3' }}</span></li>
                                <li>{{'Net' | translate }} <span>{{ netCurr | number:'1.3-3' }}</span></li>
                            </ul>


                            <div class="logo text-center mt-3">
                                <a>
                                    <img src="assets/img/logo_Color2.png" alt="hekaya-logo" class="hekaya-logo">
                                </a>
                                <div class="mt-2"><p class="credit">{{'Operated_by' | translate }}</p></div>
                            </div>
                            <br />
                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="WebUseTerms" [(ngModel)]="IsWebUseTerms">
                                    <label class="form-check-label" for="WebUseTerms">{{'IAccept' | translate }} </label> <a routerLink="/web-terms" style="color: #ae8f47;" target="_blank">{{'Web_Use_Terms' | translate }}</a> {{ 'and' | translate }} <a routerLink="/privacy-terms" style="color: #ae8f47;" target="_blank">{{'Privacy_Policy' | translate }}</a>
                                </div>
                            </div>
                            <br />

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ServicesTerms" [(ngModel)]="IsServicesTerms">
                                    <label class="form-check-label" for="ServicesTerms">{{'IAccept' | translate }} </label> <a routerLink="/web-terms" style="color: #ae8f47;" target="_blank">{{'Services_Terms' | translate }}</a>
                                </div>
                            </div>
                            <br />
                            <button class="default-btnWhite" type="button" (click)="onClickPaymentBT()" [disabled]="(!IsWebUseTerms || !IsServicesTerms)">{{'Proceed_to_Payment' | translate }}</button>
                        </div>

                    </div>
                </div>
            </div>
</section>
