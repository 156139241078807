<div class="listing-edit-delete-option">
    <div class="d-flex justify-content-between align-items-center">

        <div class="row">
            <div class="listing-sidebar-widget">
                <div class="listing-contact-info">
                    <h3>{{'Do_you_want_to_complete_your_draft_visa' | translate }}</h3>

                    <ul>
                        <li><span>{{'Order_No' | translate }}:</span> {{ orderNo }} </li>
                        <li><span>{{'Arrival_Date' | translate }}:</span> {{arrivalDate | date  : 'dd/MM/yyyy'}} </li>
                        <li><span>{{'Visit_Country' | translate }}:</span> {{ visitCountry }} </li>
                        <li><span>{{'Visa_Type' | translate }}:</span> {{ visaType }} </li>
                        <li><span>{{'Nationality' | translate }}:</span> {{ nationality }} </li>
                    </ul>
                </div>
            </div>
        </div>



    </div>

    <div class="listing-edit-delete-option">
        <div class="d-flex justify-content-between align-items-center">
            <button class="default-btnWhite" type="button" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>
            <button class="default-btnBlack" type="button" (click)="Continue()">{{'Continue' | translate }}</button>
            <button class="default-btnWhite" type="button" (click)="Forget()">{{'Forget' | translate }}</button>
        </div>

    </div>
    </div>
