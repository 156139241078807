import {  Component,  OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { VWOrderModule } from '../../appshared/orders/order/order.module';
import { OrderService } from '../../appshared/orders/order/order.service';
import { CompleteDraftDialogComponent } from './complete-draft-dialog/complete-draft-dialog.component';
import { CompletePaymentDialogComponent } from './complete-payment-dialog/complete-payment-dialog.component';
import { SavedOrderService } from '../../appshared/tools/saved-order/saved-order.service';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


    constructor(private service: OrderService, private router: Router, public dialog: MatDialog, private ServSavedOrder: SavedOrderService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //scroll to top
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit(): void {
        this.findDraft();
        this.findPayment();
    }

    public newNo: number = 0;
    public acceptNo: number = 0;
    public rejectNo: number = 0;
    public feedbakNo: number = 0;
    public visitorNo: number = 0;
    isLoading = true;

    checkMessage: string = "";
    data: any = {};
    private datas: any;
    findDraft() {

        var JSONDatas = this.ServSavedOrder.getOrdersStatus("Draft");

        if (JSONDatas.length > 0) {

            for (var i = 0; i < JSONDatas.length; i++) {
              
                this.CheckDraft(JSONDatas, i);
                
            }

        }

       

    }

    CheckDraft(JSONDatas: any, i: number) {

        this.service.getServiceVWId(JSONDatas[i].oid)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];

                this.service.VWformData = this.datas;

                let title = 'create';
                let dialogRef: MatDialogRef<any> = this.dialog.open(CompleteDraftDialogComponent, {
                    width: '420px',
                    panelClass: "complete-panel",
                    disableClose: true,
                    position: { bottom: '20px', left: '20px' },
                    data: { title: title, payload: this.data, messageTXT: this.checkMessage, vtid: JSONDatas[i].vtid, nid: JSONDatas[i].nid, rid: JSONDatas[i].rid, dates: JSONDatas[i].date, vid: JSONDatas[i].vid, oid: JSONDatas[i].oid, email: JSONDatas[i].email, pid: JSONDatas[i].pid, vwOrder: this.service.VWformData }
                })
                dialogRef.afterClosed()
                    .subscribe(res => {
                        if (!res) {
                            return;
                        }
                    })



            },
                err => {

                });

    }


    findPayment() {

        var JSONDatas = this.ServSavedOrder.getOrdersStatus("Not Paid");

        if (JSONDatas.length > 0) {

            for (var i = 0; i < JSONDatas.length; i++) {

                this.CheckPayment(JSONDatas, i);

            }

        }



    }

    CheckPayment(JSONDatas: any, i: number) {

        this.CheckDraft(JSONDatas, i);
        this.service.getServiceVWId(JSONDatas[i].oid)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];

                this.service.VWformData = this.datas;

                let title = 'create';
                let dialogRef: MatDialogRef<any> = this.dialog.open(CompletePaymentDialogComponent, {
                    width: '420px',
                    disableClose: true,
                    panelClass: "complete-panel",
                    position: { bottom: '20px', left: '20px' },
                    data: { title: title, payload: this.data, messageTXT: this.checkMessage, vtid: JSONDatas[i].vtid, nid: JSONDatas[i].nid, rid: JSONDatas[i].rid, date: JSONDatas[i].dates, vid: JSONDatas[i].vid, oid: JSONDatas[i].oid, email: JSONDatas[i].email, vwOrder: this.service.VWformData }
                })
                dialogRef.afterClosed()
                    .subscribe(res => {
                        if (!res) {
                            return;
                        }
                    })



            },
                err => {

                });

    }

}
