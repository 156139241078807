<h2> {{"Info." | translate }}</h2>

<div class="payment-box">
    <form novalidate autocomplete="off" #form="ngForm">

        <div class="payment-box">

            <p>{{ details }}</p>
            <br />
            <br />
        </div>
        <div>
            <button class="default-btnBlack" type="button" style="margin:20px; width:150px" (click)="dialogRef.close()">{{'Close' | translate }}</button>
        </div>

    </form>
</div>
