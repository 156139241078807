import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
    public SelectedLanguage: string = "en";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
     
        public dialogRef: MatDialogRef<InfoDialogComponent>, public translate: TranslateService, private loader: AppLoaderService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit() {
        this.buildItemForm(this.data.id, this.data.code)
    }

    public details: string = "";
    private datas3: any;

    buildItemForm(visaTypeId: number, code: string) {
        if (this.SelectedLanguage == "en") {
            this.details = "Please specify your arrival point of entry to this destination (airport, port, or border name) or otherwise write not specified if it is not.";
        }
        else {
            this.details ="يرجي تحديد نقطة الدخول لهذه الوجهة (المطار أو الميناء أو الحدود البرية) أو يرجى كتابة '' غير محدد'' في حالة عدم وجود نقطة محددة للدخول.";


        }


    }

}
