import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VWOrderPersonModule } from '../../../appshared/orders/order-person/order-person.module';
import { OrderPersonService } from '../../../appshared/orders/order-person/order-person.service';
import { OrderModule, VWOrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { VWVisaTypeInputTypesAndInputTypesModule } from '../../../appshared/visa/visa-type-input-types/visa-type-input-types.module';
import { VisaTypeInputTypesService } from '../../../appshared/visa/visa-type-input-types/visa-type-input-types.service';
import { AppConfirmService } from '../../../common/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { FailedDialogComponent } from '../step1-visa-type/failed-dialog/failed-dialog.component';

@Component({
  selector: 'app-step5-review',
  templateUrl: './step5-review.component.html',
  styleUrls: ['./step5-review.component.scss']
})
export class Step5ReviewComponent implements OnInit {

    titleConfirm: "";
    messageConfirm: "";

    thisID: number = 0;
    IsNew: boolean = true;
    public SelectedLanguage: string = "en";
    public SelectedCurrID: number = 1;
    private sub: any;
    vtid: number = 0;
    nid: number = 0;
    vid: number = 0;
    rid: number = 0;
    oid: number = 0;
    dates: string = "";
    email: string = "";
    private datas: any;


    countryName: string = "";
    visaTypeName: string = "";
    stayDays: string = "";
    orderNo: string = "";
    fromDate = new Date();
    toDate = new Date();


    datasourceVWOrderPerson: any = {
        data: []
    }

    constructor(public dialog: MatDialog, private toastr: ToastrService, public confirmService: AppConfirmService,private router: Router, private route: ActivatedRoute, public service: OrderService, private servVisaTypeInputTypes: VisaTypeInputTypesService, private servOrderPerson: OrderPersonService, public translate: TranslateService, private loader: AppLoaderService)
    {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {
        this.service.VWformData = new VWOrderModule();

        this.sub = this.route.params.subscribe(params => {
            this.vtid = +params['vtid'];
            this.nid = +params['nid'];
            this.vid = +params['vid'];
            this.rid = +params['rid'];
            this.oid = +params['oid'];
            this.email = params['email'];
            this.dates = params['date'];
        });

        this.gnCheckOrderValidity();
        this.buildItemForm();
        this.ActiveInput();
        this.loader.open();
        this.getAllOrderPerson();
    }

    public getAllOrderPerson = () => {

        this.servOrderPerson.getServiceVWOrder(this.oid)
            .subscribe(res => {
                this.datasourceVWOrderPerson = res;
                if (this.SelectedLanguage == "en") {

                }
                else {
                    for (var i = 0; i < this.datasourceVWOrderPerson.length; i++) {

                        if (this.datasourceVWOrderPerson[i].gender == "Male" ) {
                            this.datasourceVWOrderPerson[i].gender = "ذكر";
                        }
                        else if (this.datasourceVWOrderPerson[i].gender == "Female") {
                            this.datasourceVWOrderPerson[i].gender = "أنثى";
                        }

                        if (this.datasourceVWOrderPerson[i].marriedStatus == "Single") {
                            this.datasourceVWOrderPerson[i].marriedStatus = "أعزب";
                        }
                        else if (this.datasourceVWOrderPerson[i].marriedStatus == "Married") {
                            this.datasourceVWOrderPerson[i].marriedStatus = "متزوج";
                        }
                        else if (this.datasourceVWOrderPerson[i].marriedStatus == "Divorced") {
                            this.datasourceVWOrderPerson[i].marriedStatus = "مطلق";
                        }
                        else if (this.datasourceVWOrderPerson[i].marriedStatus == "Widowed") {
                            this.datasourceVWOrderPerson[i].marriedStatus = "أرملة";
                        }

                        this.datasourceVWOrderPerson[i].birthCountryNameEn = this.datasourceVWOrderPerson[i].birthCountryNameAr;
                        this.datasourceVWOrderPerson[i].firstLanguageNameEn = this.datasourceVWOrderPerson[i].firstLanguageNameAr;
                        this.datasourceVWOrderPerson[i].secondLanguageNameEn = this.datasourceVWOrderPerson[i].secondLanguageNameAr;
                        this.datasourceVWOrderPerson[i].motherNationalityNameEn = this.datasourceVWOrderPerson[i].motherNationalityNameAr;
                        this.datasourceVWOrderPerson[i].motherPreviousNationalityNameEn = this.datasourceVWOrderPerson[i].motherPreviousNationalityNameAr;
                        this.datasourceVWOrderPerson[i].motherBirthCountryNameEn = this.datasourceVWOrderPerson[i].motherBirthCountryNameAr;
                        this.datasourceVWOrderPerson[i].fatherNationalityNameEn = this.datasourceVWOrderPerson[i].fatherNationalityNameAr;
                        this.datasourceVWOrderPerson[i].fatherPreviousNationalityNameEn = this.datasourceVWOrderPerson[i].fatherPreviousNationalityNameAr;
                        this.datasourceVWOrderPerson[i].fatherBirthCountryNameEn = this.datasourceVWOrderPerson[i].fatherBirthCountryNameAr;
                        this.datasourceVWOrderPerson[i].jobCategoryNameEn = this.datasourceVWOrderPerson[i].jobCategoryNameAr;
                        this.datasourceVWOrderPerson[i].ordinaryDocumentNameEn = this.datasourceVWOrderPerson[i].ordinaryDocumentNameAr;
                        this.datasourceVWOrderPerson[i].previousNationalityNameEn = this.datasourceVWOrderPerson[i].previousNationalityNameAr;
                        this.datasourceVWOrderPerson[i].passportIssueCountryEn = this.datasourceVWOrderPerson[i].passportIssueCountryAr;
                    }
                }

                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

    firstNameActive: boolean = false;
    middleNameActive: boolean = false;
    lastNameActive: boolean = false;
    fullNameActive: boolean = false;
    genderActive: boolean = false;
    marriedStatusActive: boolean = false;
    dobActive: boolean = false;
    birthCountryIDActive: boolean = false;
    birthPlaceActive: boolean = false;
    religionIDActive: boolean = false;
    spouseNameActive: boolean = false;
    firstLanguageIDActive: boolean = false;
    secondLanguageIDActive: boolean = false;
    motherNameActive: boolean = false;
    motherNationalityIDActive: boolean = false;
    motherPerviousNationalityIDActive: boolean = false;
    motherBirthPlaceActive: boolean = false;
    motherBirthCountryIDActive: boolean = false;
    fatherNameActive: boolean = false;
    fatherNationalityIDActive: boolean = false;
    fatherPerviousNationalityIDActive: boolean = false;
    fatherBirthPlaceActive: boolean = false;
    fatherBirthCountryIDActive: boolean = false;
    jobTitleActive: boolean = false;
    jobCategoryIDActive: boolean = false;
    ordinaryDocumentIDActive: boolean = false;
    passportNoActive: boolean = false;
    passportIssueDateActive: boolean = false;
    passportExpiryDateActive: boolean = false;
    passportIssueCountryIDActive: boolean = false;
    phoneNumberActive: boolean = false;
    emailActive: boolean = false;
    posteCodeActive: boolean = false;
    previousNationalityIDActive: boolean = false;
    isNameChangedActive: boolean = false;
    visibleIdentificationMarkActive: boolean = false;
    educationQualificationActive: boolean = false;
    acquireNationalityByActive: boolean = false;
    isOtherValidPassportICActive: boolean = false;
    otherPassportIssueCountryIDActive: boolean = false;
    visitPlace1Active: boolean = false;
    visitPlace2Active: boolean = false;
    isVisitBeforeActive: boolean = false;
    visitedPlaceBeforeActive: boolean = false;
    cityVisitedBeforeActive: boolean = false;
    lastVisaNoActive: boolean = false;
    lastVisaTypeActive: boolean = false;
    lastIssuePlaceActive: boolean = false;
    lastIssueDateActive: boolean = false;
    presentHouseNoStreetActive: boolean = false;
    presentCityActive: boolean = false;
    presentCountryIDActive: boolean = false;
    presentStateActive: boolean = false;
    presentPostalCodeActive: boolean = false;
    presentPhoneNoActive: boolean = false;
    presentMobileNoActive: boolean = false;
    presentEmailActive: boolean = false;
    permanentHouseNoStreetActive: boolean = false;
    permanentCityActive: boolean = false;
    permanentStateActive: boolean = false;


    public dataSourceInputTypes = new MatTableDataSource<VWVisaTypeInputTypesAndInputTypesModule>();
    public ActiveInput = () => {
        this.servVisaTypeInputTypes.getServiceVWVisaType(this.vtid)
            .subscribe(res => {
                this.dataSourceInputTypes.data = res as VWVisaTypeInputTypesAndInputTypesModule[];

                for (let rows of this.dataSourceInputTypes.data) {
                    if (rows.code == "FirstName") {
                        this.firstNameActive = true;
                    }
                    else if (rows.code == "MiddleName") {
                        this.middleNameActive = true;
                    }
                    else if (rows.code == "LastName") {
                        this.lastNameActive = true;
                    }
                    else if (rows.code == "FullName") {
                        this.fullNameActive = true;
                    }
                    else if (rows.code == "Gender") {
                        this.genderActive = true;
                    }
                    else if (rows.code == "MarriedStatus") {
                        this.marriedStatusActive = true;
                    }
                    else if (rows.code == "BirthCountry") {
                        this.birthCountryIDActive = true;
                    }
                    else if (rows.code == "BirthPlace") {
                        this.birthPlaceActive = true;
                    }
                    else if (rows.code == "Religion") {
                        this.religionIDActive = true;
                    }
                    else if (rows.code == "SpouseName") {
                        this.spouseNameActive = true;
                    }
                    else if (rows.code == "FirstLanguage") {
                        this.firstLanguageIDActive = true;
                    }
                    else if (rows.code == "SecondLanguage") {
                        this.secondLanguageIDActive = true;
                    }
                    else if (rows.code == "MotherName") {
                        this.motherNameActive = true;
                    }
                    else if (rows.code == "MotherNationality") {
                        this.motherNationalityIDActive = true;
                    }
                    else if (rows.code == "MotherPerviousNationality") {
                        this.motherPerviousNationalityIDActive = true;
                    }
                    else if (rows.code == "MotherBirthPlaceActive") {
                        this.motherBirthPlaceActive = true;
                    }
                    else if (rows.code == "MotherBirthCountry") {
                        this.motherBirthCountryIDActive = true;
                    }
                    else if (rows.code == "FatherName") {
                        this.fatherNameActive = true;
                    }
                    else if (rows.code == "FatherNationality") {
                        this.fatherNationalityIDActive = true;
                    }
                    else if (rows.code == "FatherPerviousNationality") {
                        this.fatherPerviousNationalityIDActive = true;
                    }
                    else if (rows.code == "FatherBirthPlace") {
                        this.fatherBirthPlaceActive = true;
                    }
                    else if (rows.code == "FatherBirthCountry") {
                        this.fatherBirthCountryIDActive = true;
                    }
                    else if (rows.code == "JobTitle") {
                        this.jobTitleActive = true;
                    }
                    else if (rows.code == "JobCategory") {
                        this.jobCategoryIDActive = true;
                    }
                    else if (rows.code == "OrdinaryDocument") {
                        this.ordinaryDocumentIDActive = true;
                    }
                    else if (rows.code == "PassportNo") {
                        this.passportNoActive = true;
                    }
                    else if (rows.code == "PassportIssueDate") {
                        this.passportIssueDateActive = true;
                    }
                    else if (rows.code == "PassportExpiryDate") {
                        this.passportExpiryDateActive = true;
                    }
                    else if (rows.code == "PassportIssueCountry") {
                        this.passportIssueCountryIDActive = true;
                    }
                    else if (rows.code == "PhoneNumber") {
                        this.phoneNumberActive = true;
                    }
                    else if (rows.code == "Email") {
                        this.emailActive = true;
                    }
                    else if (rows.code == "PosteCode") {
                        this.posteCodeActive = true;
                    }
                    else if (rows.code == "PreviousNationality") {
                        this.previousNationalityIDActive = true;
                    }
                    else if (rows.code == "IsNameChanged") {
                        this.isNameChangedActive = true;
                    }
                    else if (rows.code == "VisibleIdentificationMark") {
                        this.visibleIdentificationMarkActive = true;
                    }
                    else if (rows.code == "EducationQualification") {
                        this.educationQualificationActive = true;
                    }
                    else if (rows.code == "EcquireNationalityBy") {
                        this.acquireNationalityByActive = true;
                    }
                    else if (rows.code == "IsOtherValidPassportIC") {
                        this.isOtherValidPassportICActive = true;
                    }
                    else if (rows.code == "OtherPassportIssueCountry") {
                        this.otherPassportIssueCountryIDActive = true;
                    }
                    else if (rows.code == "VisitPlace1") {
                        this.visitPlace1Active = true;
                    }
                    else if (rows.code == "VisitPlace2") {
                        this.visitPlace2Active = true;
                    }
                    else if (rows.code == "IsVisitBefore") {
                        this.isVisitBeforeActive = true;
                    }
                    else if (rows.code == "VisitedPlaceBefore") {
                        this.visitedPlaceBeforeActive = true;
                    }
                    else if (rows.code == "CityVisitedBefore") {
                        this.cityVisitedBeforeActive = true;
                    }
                    else if (rows.code == "LastVisaNo") {
                        this.lastVisaNoActive = true;
                    }
                    else if (rows.code == "LastVisaType") {
                        this.lastVisaTypeActive = true;
                    }
                    else if (rows.code == "LastIssuePlace") {
                        this.lastIssuePlaceActive = true;
                    }
                    else if (rows.code == "LastIssueDate") {
                        this.lastIssueDateActive = true;
                    }
                    else if (rows.code == "PresentHouseNoStreet") {
                        this.presentHouseNoStreetActive = true;
                    }
                    else if (rows.code == "PresentCity") {
                        this.presentCityActive = true;
                    }
                    else if (rows.code == "PresentCountry") {
                        this.presentCountryIDActive = true;
                    }
                    else if (rows.code == "PresentState") {
                        this.presentStateActive = true;
                    }
                    else if (rows.code == "PresentPostalCode") {
                        this.presentPostalCodeActive = true;
                    }
                    else if (rows.code == "PresentPhoneNo") {
                        this.presentPhoneNoActive = true;
                    }
                    else if (rows.code == "PresentMobileNo") {
                        this.presentMobileNoActive = true;
                    }
                    else if (rows.code == "PresentEmail") {
                        this.presentEmailActive = true;
                    }
                    else if (rows.code == "PermanentHouseNoStreet") {
                        this.permanentHouseNoStreetActive = true;
                    }
                    else if (rows.code == "PermanentCity") {
                        this.permanentCityActive = true;
                    }
                    else if (rows.code == "PermanentState") {
                        this.permanentStateActive = true;
                    }
                }
            })
    }


    buildItemForm() {

        this.service.getServiceVWId(this.oid)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];


                this.service.VWformData = this.datas;

                this.stayDays = this.service.VWformData.stayPeriod.toString();
                this.orderNo = this.service.VWformData.orderNo.toString();
                if (this.SelectedLanguage == "en") {
                    this.countryName = this.service.VWformData.visaCountryNameEn;
                }
                else {
                    this.countryName = this.service.VWformData.visaCountryNameAr;

                    this.service.VWformData.currencyNameEn = this.service.VWformData.currencyNameAr;
                    this.service.VWformData.visaTypeNameEn = this.service.VWformData.visaTypeNameAr;
                    this.service.VWformData.visaCountryNameEn = this.service.VWformData.visaCountryNameAr;
                    this.service.VWformData.countryNameEn = this.service.VWformData.countryNameAr;
                    this.service.VWformData.nationalityNameEn = this.service.VWformData.nationalityNameAr;
                }

                if (this.SelectedLanguage == "en") {
                    this.visaTypeName = this.service.VWformData.visaTypeNameEn;
                }
                else {
                    this.visaTypeName = this.service.VWformData.visaTypeNameAr;
                }

                this.fromDate = this.service.VWformData.validFromDate;
                this.toDate = this.service.VWformData.validToDate;
          

            });
    }



    Edit_PersonBT(id) {
        this.router.navigate(['step3', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email, id: id}]);
    }

    Add_Extra_PersonBT() {
        this.router.navigate(['step3', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
    }

    GoNextBT() {
        this.router.navigate(['step6', { vtid: this.vtid, nid: this.nid, rid: this.rid, date: this.dates, vid: this.vid, oid: this.oid, email: this.email }]);
    }




    checkMessage: string = "";
    datasa: any = {};
    gnCheckOrderValidity() {
        var isValid = true;
        this.service.getCheckOrder(this.oid, this.SelectedLanguage)
            .subscribe(res => {
                this.checkMessage = res;


                this.loader.close();

                if (this.checkMessage == "success") {

                }
                else {

                    isValid = false;

                    let title = 'create';
                    let dialogRef: MatDialogRef<any> = this.dialog.open(FailedDialogComponent, {
                        width: '720px',
                        disableClose: true,
                        data: { title: title, payload: this.datasa, messageTXT: this.checkMessage }
                    })
                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res) {
                                return isValid;
                            }

                            this.router.navigate(['home']);

                        })
                }

            },
                err => {
                    this.loader.close(); console.log(err);
                });

        return isValid;
    }

    DeleteBT(id) {
        if (this.gnCheckOrderValidity()) {

            this.confirmService.confirm({ title: this.titleConfirm, message: this.messageConfirm })
                .subscribe((result) => {
                    if (result) {

                        this.loader.open();

                        this.datasourceVWOrderPerson = [];

                        this.servOrderPerson.deleteService(id).then(res => {
                            this.loader.close();
                            this.getAllOrderPerson();
                            this.toastr.success('Deleted Successfully', '');


                        },
                            err => {
                                this.loader.close();
                                this.getAllOrderPerson();
                                this.toastr.success('Deleted Successfully', '');
                            });
                    }

                });

        
        }
        
    }
}
