import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { VoucherModule } from './voucher.module';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }



    getServiceSource(SourceNo: number, Source: string): Observable<VoucherModule[]> {
        return this.http.get<VoucherModule[]>(environment.apiURL + 'Agent/Voucher/search/' + SourceNo);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Orders/Voucher/' + id).toPromise();
    }

    formData: VoucherModule = new VoucherModule();
    list: VoucherModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Orders/Voucher/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Orders/Voucher/' + this.formData.voucherID, this.formData);
    }
}