import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { OrderModule, VWOrderModule } from './order.module';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
   
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceStatus(CatgID: string): Observable<OrderModule[]> {
        return this.http.get<OrderModule[]>(environment.apiURL + 'Orders/Order/search/' + CatgID);
    }

    getServiceId(id: number): Observable<OrderModule[]> {
        return this.http.get<OrderModule[]>(environment.apiURL + 'Orders/Order/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Orders/Order/' + id).toPromise();
    }

    formData: OrderModule = new OrderModule();
    list: OrderModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Orders/Order/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Orders/Order/' + this.formData.orderID, this.formData);
    }

    getNewOrderCost(id: number) {
        return this.http.get(environment.apiURL + "Orders/VWOrder/NewOrderCost/?orderID=" + id, { responseType: 'text' });
    }


    VWformData: VWOrderModule = new VWOrderModule();
    getServiceVWId(id: number): Observable<VWOrderModule[]> {
        return this.http.get<VWOrderModule[]>(environment.apiURL + 'Orders/VWOrder/' + id);
    }

    getServiceVWTrack(no: number, email: string): Observable<VWOrderModule[]> {
        return this.http.get<VWOrderModule[]>(environment.apiURL + "Orders/VWOrder/Track/?orderNo=" + no + "&email=" + email);
    } 

    getServiceHome() {
        return this.http.get(environment.apiURL + "Orders/VWOrder/Home/", { responseType: 'text' });
    }

    getCheckOrder(oid: number, SelectedLanguage: string) {
        return this.http.get(environment.apiURL + "Orders/VWOrder/CheckOrderAvailable/?orderID=" + oid + "&Lang=" + SelectedLanguage, { responseType: 'text' });
    }


    getServiceVWEmail(orderID: number, status: string, lang: string) {
        return this.http.get(environment.apiURL + "Orders/VWOrder/Email/?orderID=" + orderID + "&status=" + status + "&lang=" + lang, { responseType: 'text' });
    }
}
