<app-header-one></app-header-one>

<div class="home-area">
    <div class="home-slides owl-carousel owl-theme">
        <div class="hero-banner banner-bg2">
            <div class="main-banner-content2">
                <h1>{{'Masirah' | translate }}</h1>


            </div>
        </div>
    </div>

    <div class="main-banner-content">
        <br />
        <br />
        <h1>
            <br />
            <br /><span>{{'Track' | translate }}</span> {{'Your_Application' | translate }}
        </h1>

        <div class="main-search-wrap">
            <form  autocomplete="off" #trackingF="ngForm" (ngSubmit)="onClickFindTrack(orderNoTX.value, emailTX.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label><i class='bx bxs-report'></i></label>
                            <input type="text" placeholder="{{'Order_No' | translate }}"  name="orderNoTX" ngModel #orderNoTX>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label><a href="#"><i class='bx bxs-envelope'></i></a></label>
                            <input type="email" placeholder="{{'Your_email' | translate }}"  name="emailTX" ngModel #emailTX>
                        </div>
                    </div>

              
                </div>

                <div class="main-search-btn">
                    <button type="submit">{{'Search' | translate }} <i class='bx bx-search-alt'></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
