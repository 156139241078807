export class LanguageModule {
    languageID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    notes: string = '';
 }

export interface Language {
    languageID: number;
    nameAr: string;
    nameEn: string;
    notes: string;
}
