import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VWOrderModule } from '../../../appshared/orders/order/order.module';
import { OrderService } from '../../../appshared/orders/order/order.service';
import { VoucherService } from '../../../appshared/orders/voucher/voucher.service';
import { AppLoaderService } from '../../../common/app-loader/app-loader.service';
import { SessionStoreService } from '../../../common/session-store.service';
import { SavedOrderService } from '../../../appshared/tools/saved-order/saved-order.service';

@Component({
    selector: 'app-payment-result',
    templateUrl: './payment-result.component.html',
    styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {

    private sub: any;
    oid: number = 0;
    private datas: any;

    public SelectedLanguage: string = "en";
    public CurrencyName: string = "";

    public itemDetails: string = "";
    public insuranceAmount: number = 0;
    public promotionCodeID: number = 0;
    public price: number = 0;
    public quantity: number = 0;
    public subtotal: number = 0;
    public total: number = 0;
    public voucherAmount: number = 0;
    public isDisabledVoucher: boolean = false;


    public isSuccess: boolean = false;
    public isFaild: boolean = false;


    public orderNo: number = 0;
    public amountPaid: number = 0;
    public insuranceAmountCurr: number = 0;
    public priceCurr: number = 0;
    public subtotalCurr: number = 0;
    public totalCurr: number = 0;
    public voucherAmountCurr: number = 0;

    constructor(public service: OrderService, public servVoucher: VoucherService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private sessionS: SessionStoreService, private loader: AppLoaderService, private ServSavedOrder: SavedOrderService) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.oid = +params['oid'];
        });

       this.buildItemForm();
    }

    buildItemForm() {
        this.loader.open();
        this.service.getServiceVWId(this.oid)
            .subscribe(res => {
                this.datas = res as VWOrderModule[];


                this.service.VWformData = this.datas;


                this.service.formData.orderID = this.datas.orderID;
                this.service.formData.orderNo = this.datas.orderNo;
                this.service.formData.orderDate = this.datas.orderDate;
                this.service.formData.status = this.datas.status;
                this.service.formData.source = this.datas.source;
                this.service.formData.visaCountryID = this.datas.visaCountryID;
                this.service.formData.residenceCountryID = this.datas.residenceCountryID;
                this.service.formData.nationalityID = this.datas.nationalityID;
                this.service.formData.visaTypeID = this.datas.visaTypeID;
                this.service.formData.isAgreeTerms = this.datas.isAgreeTerms;
                this.service.formData.email = this.datas.email;
                this.service.formData.currencyID = this.datas.currencyID;
                this.service.formData.agentID = this.datas.agentID;
                this.service.formData.userID = this.datas.userID;
                this.service.formData.dialing = this.datas.dialing;
                this.service.formData.phoneNo = this.datas.phoneNo;
                this.service.formData.applicantRequesterName = this.datas.applicantRequesterName;
                this.service.formData.arrivalDate = this.datas.arrivalDate;
                this.service.formData.leaveDate = this.datas.leaveDate;
                this.service.formData.arrivalPort = this.datas.arrivalPort;
                this.service.formData.rejectReason = this.datas.rejectReason;
                this.service.formData.modificationRequestDetails = this.datas.modificationRequestDetails;
                this.service.formData.promotionCodeID = this.datas.promotionCodeID;
                this.service.formData.stayPeriod = this.datas.stayPeriod;
                this.service.formData.validFromDate = this.datas.validFromDate;
                this.service.formData.validToDate = this.datas.validToDate;
                this.service.formData.defaultLanguage = this.datas.defaultLanguage;
                this.service.formData.pricePerPerson = this.datas.pricePerPerson;
                this.service.formData.subTotalAmount = this.datas.subTotalAmount;
                this.service.formData.voucherAmount = this.datas.voucherAmount;
                this.service.formData.groupDiscountAmount = this.datas.groupDiscountAmount;
                this.service.formData.vat = this.datas.vat;
                this.service.formData.totalAmount = this.datas.totalAmount;
                this.service.formData.insuranceAmount = this.datas.insuranceAmount;
                this.service.formData.net = this.datas.net;
                this.service.formData.pricePerPersonCurr = this.datas.pricePerPersonCurr;
                this.service.formData.subTotalAmountCurr = this.datas.subTotalAmountCurr;
                this.service.formData.voucherAmountCurr = this.datas.voucherAmountCurr;
                this.service.formData.groupDiscountAmountCurr = this.datas.groupDiscountAmountCurr;
                this.service.formData.vatCurr = this.datas.vatCurr;
                this.service.formData.totalAmountCurr = this.datas.totalAmountCurr;
                this.service.formData.insuranceAmountCurr = this.datas.insuranceAmountCurr;
                this.service.formData.netCurr = this.datas.netCurr;
                this.service.formData.totalPaid = this.datas.totalPaid;
                this.service.formData.totalPaidCurr = this.datas.totalPaidCurr;
                this.service.formData.isRefundPaidBack = this.datas.isRefundPaidBack;
                this.service.formData.refundAmount = this.datas.refundAmount;
                this.service.formData.refundRemark = this.datas.refundRemark;
                this.service.formData.insuranceRemark = this.datas.insuranceRemark;
                this.service.formData.isInsurancePaidBack = this.datas.isInsurancePaidBack;
                this.service.formData.insuranceReturnAmount = this.datas.insuranceReturnAmount;

                this.orderNo = this.service.VWformData.orderNo;
                this.amountPaid = this.service.VWformData.totalAmount;
                this.itemDetails = this.service.VWformData.visaCountryNameEn + " / " + this.service.VWformData.visaTypeNameEn;

                if (this.service.formData.status == "Paid") {

                    this.ServSavedOrder.removeOrder(this.service.formData.orderID);

                    this.isSuccess = true;

                    this.SendNotification(this.service.formData.status);
                }
                else {
                    this.isFaild = true;
                    
                }
               
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }


    SendNotification(status: string) {
        //this.loader.open();
        this.service.getServiceVWEmail(this.service.formData.orderID, status, this.service.formData.defaultLanguage).subscribe(
            res => {
                this.loader.close();
            },
            err => {
                this.loader.close();
            }
        );
    }

    goHome() {

        this.router.navigate(['home']);

    }



}
