import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { VisaTypePriceGroupModule } from './visa-type-price-group.module';

@Injectable({
  providedIn: 'root'
})
export class VisaTypePriceGroupService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceVisaTypeAll(VisaTypeID: number): Observable<VisaTypePriceGroupModule[]> {
        return this.http.get<VisaTypePriceGroupModule[]>(environment.apiURL + 'Visa/VisaTypePriceGroup/search/' + VisaTypeID);
    }

    getServiceVisaTypeClient(VisaTypeID: number): Observable<VisaTypePriceGroupModule[]> {
        return this.http.get<VisaTypePriceGroupModule[]>(environment.apiURL + 'Visa/VisaTypePriceGroup/searchClient/?visaTypeID=' + VisaTypeID);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Visa/VisaTypePriceGroup/' + id).toPromise();
    }

    formData: VisaTypePriceGroupModule = new VisaTypePriceGroupModule();
    list: VisaTypePriceGroupModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Visa/VisaTypePriceGroup/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Visa/VisaTypePriceGroup/' + this.formData.visaTypePriceGroupID, this.formData);
    }
}
