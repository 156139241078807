import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { VisaTypePriceGroupModule } from '../../../../appshared/visa/visa-type-price-group/visa-type-price-group.module';
import { VisaTypePriceGroupService } from '../../../../appshared/visa/visa-type-price-group/visa-type-price-group.service';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-offer-dialog',
  templateUrl: './offer-dialog.component.html',
  styleUrls: ['./offer-dialog.component.scss']
})
export class OfferDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private loader: AppLoaderService, private serv: VisaTypePriceGroupService,
        public dialogRef: MatDialogRef<OfferDialogComponent>) { }

    public dataSource = new MatTableDataSource<VisaTypePriceGroupModule>();


    ngOnInit(): void {
        this.buildItemForm()
    }

    buildItemForm() {


        var CurrencyRate = +this.data.CurrencyRate;
        this.loader.open();
        this.serv.getServiceVisaTypeClient(this.data.vid)
            .subscribe(res => {
                this.dataSource.data = res as VisaTypePriceGroupModule[];
                for (var i = 0; i < this.dataSource.data.length; i++) {
                    var visaAmount = +this.data.visaCost;

                    if (this.dataSource.data[i].costPerPerson != 0) {
                        visaAmount = this.dataSource.data[i].costPerPerson * CurrencyRate;
                    }

                    if (this.dataSource.data[i].discountPercentage != 0) {

                        var disc = (visaAmount * (this.dataSource.data[i].discountPercentage / 100));
                        visaAmount = visaAmount - disc;
                    }


                    this.dataSource.data[i].costPerPerson = visaAmount;
                }

                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }



    onSubmit() {

        this.dialogRef.close();

    }

}
