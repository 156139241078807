<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2 [innerHtml]="PageTitle"></h2>
        </div>
    </div>
</div>



<div [innerHtml]="PageContentHTML"></div>

