export class OrderPersonModule {
    orderPersonID: number = 0;
    orderID: number = 0;
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    fullName: string = '';
    gender: string = '';
    marriedStatus: string = '';
    dob: Date = new Date();
    birthCountryID: number = 0;
    birthPlace: string = '';
    email: string = '';
    religionID: number = 0;
    spouseName: string = '';
    firstLanguageID: number = 0;
    secondLanguageID: number = 0;


    motherName: string = '';
    motherNationalityID: number = 0;
    motherPerviousNationalityID: number = 0;
    motherBirthPlace: string = '';
    motherBirthCountryID: number = 0;
    fatherName: string = '';
    fatherNationalityID: number = 0;
    fatherPerviousNationalityID: number = 0;
    fatherBirthPlace: string = '';
    fatherBirthCountryID: number = 0;
    jobCategoryID: number = 0;
    jobTitle: string = '';
    ordinaryDocumentID: number = 0;
    passportNo: string = '';
    passportIssueDate: Date = new Date();
    passportExpiryDate: Date = new Date();
    passportIssueCountryID: number = 0;

    phoneNumber: string = '';
    posteCode: string = '';
    previousNationalityID: number = 0;
    isNameChanged: boolean = false;
    visibleIdentificationMark: string = '';
    educationQualification: string = '';
    acquireNationalityBy: string = '';
    isOtherValidPassportIC: boolean = false;
    otherPassportIssueCountryID: number = 0;
    visitPlace1: string = '';
    visitPlace2: string = '';
    isVisitBefore: boolean = false;
    visitedPlaceBefore: string = '';
    cityVisitedBefore: string = '';

      


    lastVisaNo: string = '';
    lastVisaType: string = '';
    lastIssuePlace: string = '';
    lastIssueDate: Date = new Date();
    presentHouseNoStreet: string = '';
    presentCity: string = '';
    presentCountryID: number = 0;
    presentState: string = '';
    presentPostalCode: string = '';
    presentPhoneNo: string = '';
    presentMobileNo: string = '';
    presentEmail: string = '';
    permanentHouseNoStreet: string = '';
    permanentCity: string = '';
    permanentState: string = '';

    jobCategoryOther?: string = '';
    employer?: string = '';
    arrivalAirpotPortName?: string = '';
 }

export class VWOrderPersonModule {
    orderPersonID: number = 0;
    orderID: number = 0;
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    fullName: string = '';
    gender: string = '';
    marriedStatus: string = '';
    dob: Date = new Date();
    birthCountryID: number = 0;
    birthPlace: string = '';
    email: string = '';
    religionID: number = 0;
    spouseName: string = '';
    firstLanguageID: number = 0;
    secondLanguageID: number = 0;


    motherName: string = '';
    motherNationalityID: number = 0;
    motherPerviousNationalityID: number = 0;
    motherBirthPlace: string = '';
    motherBirthCountryID: number = 0;
    fatherName: string = '';
    fatherNationalityID: number = 0;
    fatherPerviousNationalityID: number = 0;
    fatherBirthPlace: string = '';
    fatherBirthCountryID: number = 0;
    jobCategoryID: number = 0;
    jobTitle: string = '';
    ordinaryDocumentID: number = 0;
    passportNo: string = '';
    passportIssueDate: Date = new Date();
    passportExpiryDate: Date = new Date();
    passportIssueCountryID: number = 0;

    phoneNumber: string = '';
    posteCode: string = '';
    previousNationalityID: number = 0;
    isNameChanged: boolean = false;
    visibleIdentificationMark: string = '';
    educationQualification: string = '';
    acquireNationalityBy: string = '';
    isOtherValidPassportIC: boolean = false;
    otherPassportIssueCountryID: number = 0;
    visitPlace1: string = '';
    visitPlace2: string = '';
    isVisitBefore: boolean = false;
    visitedPlaceBefore: string = '';
    cityVisitedBefore: string = '';




    lastVisaNo: string = '';
    lastVisaType: string = '';
    lastIssuePlace: string = '';
    lastIssueDate: Date = new Date();
    presentHouseNoStreet: string = '';
    presentCity: string = '';
    presentCountryID: number = 0;
    presentState: string = '';
    presentPostalCode: string = '';
    presentPhoneNo: string = '';
    presentMobileNo: string = '';
    presentEmail: string = '';
    permanentHouseNoStreet: string = '';
    permanentCity: string = '';
    permanentState: string = '';


    orderNo?: number = 0;
    orderDate?: Date = new Date();
    status?: string = '';
    birthCountryNameAr?: string = '';
    birthCountryNameEn?: string = '';
    firstLanguageNameAr?: string = '';
    firstLanguageNameEn?: string = '';
    secondLanguageNameAr?: string = '';
    secondLanguageNameEn?: string = '';
    motherNationalityNameAr?: string = '';
    motherNationalityNameEn?: string = '';
    motherPreviousNationalityNameAr?: string = '';
    motherPreviousNationalityNameEn?: string = '';
    motherBirthCountryNameAr?: string = '';
    motherBirthCountryNameEn?: string = '';
    fatherNationalityNameAr?: string = '';
    fatherNationalityNameEn?: string = '';
    fatherPreviousNationalityNameAr?: string = '';
    fatherPreviousNationalityNameEn?: string = '';
    fatherBirthCountryNameAr?: string = '';
    fatherBirthCountryNameEn?: string = '';
    jobCategoryNameAr?: string = '';
    jobCategoryNameEn?: string = '';
    ordinaryDocumentNameAr?: string = '';
    ordinaryDocumentNameEn?: string = '';
    previousNationalityNameAr?: string = '';
    previousNationalityNameEn?: string = '';
    passportIssueCountryAr?: string = '';
    passportIssueCountryEn?: string = '';
    otherPassportIssueCountryAr?: string = '';
    otherPassportIssueCountryEn?: string = '';
    presentCountryAr?: string = '';
    presentCountryEn?: string = '';

    jobCategoryOther?: string = '';
    employer?: string = '';
    arrivalAirpotPortName?: string = '';
}
