import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { RequestAttachmentModule, VWRequestAttachmentTypeAndAttachmentTypeModule } from './request-attachment.module';

@Injectable({
  providedIn: 'root'
})
export class RequestAttachmentService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceRequest(RequestID: number): Observable<RequestAttachmentModule[]> {
        return this.http.get<RequestAttachmentModule[]>(environment.apiURL + 'Agents/RequestAttachment/search/' + RequestID);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Agents/RequestAttachment/' + id).toPromise();
    }

    formData: RequestAttachmentModule = new RequestAttachmentModule();
    list: RequestAttachmentModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Agents/RequestAttachment/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Agents/RequestAttachment/' + this.formData.requestAttachmentID, this.formData);
    }



    postServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        const formData = new FormData();
        let fileToUpload = <File>files[0];

        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('attachmentTypeID', this.formData.attachmentTypeID.toString());
        formData.append('requestID', this.formData.requestID.toString());
        formData.append('type', this.formData.type.toString());
        formData.append('notes', this.formData.notes.toString());

        return this.http.post(environment.apiURL + 'Agents/RequestAttachment/PostUpload', formData, { reportProgress: true, observe: 'events' });
    }

    putServiceWithFile(files) {
        if (files.length === 0) {
            return;
        }
        let fileToUpload = <File>files[0];
        const formData = new FormData();

        formData.append('filePath', fileToUpload, fileToUpload.name);
        formData.append('requestAttachmentID', this.formData.requestAttachmentID.toString());
        formData.append('attachmentTypeID', this.formData.attachmentTypeID.toString());
        formData.append('requestID', this.formData.requestID.toString());
        formData.append('type', this.formData.type.toString());
        formData.append('notes', this.formData.notes.toString());

        return this.http.put(environment.apiURL + 'Agents/RequestAttachment/PutUpload', formData, { reportProgress: true, observe: 'events' });
    }





    getServiceVW(): Observable<VWRequestAttachmentTypeAndAttachmentTypeModule[]> {
        return this.http.get<VWRequestAttachmentTypeAndAttachmentTypeModule[]>(environment.apiURL + 'Agents/VWRequestAttachmentTypeAndAttachmentType/');
    }
    getServiceVWRequest(RequestID: number): Observable<VWRequestAttachmentTypeAndAttachmentTypeModule[]> {
        return this.http.get<VWRequestAttachmentTypeAndAttachmentTypeModule[]>(environment.apiURL + 'Agents/VWRequestAttachmentTypeAndAttachmentType/search/' + RequestID);
    }
}
