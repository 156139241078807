import { Injectable } from '@angular/core';
import { LocalStoreService } from '../../../common/local-store.service';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class SavedOrderService {

    constructor(private localS: LocalStoreService) { }

    setNewOrder(vtid: number, nid: number, vid: number, rid: number, oid: number, email: string, date: string, pid: number, status: string) {

        var SOrders =  this.localS.getItem("SavedOrder");


        var addDate = new Date();


        let JSONDatas = [
            { "vtid": "" + vtid + "", "nid": "" + nid + "", "vid": "" + vid + "", "rid": "" + rid + "", "oid": "" + oid + "", "email": "" + email + "", "date": "" + date + "", "Adddate": addDate, "pid": pid,  "status": status  },
        ];

        if (SOrders != null) {
            var ActiveDate = new Date();
            ActiveDate.setDate(addDate.getDate() - 1);
            for (var i = 0; i < SOrders.length; i++) {
                var OredrActiveDate = this.convetMoment(SOrders[i].Adddate);
                if (ActiveDate < OredrActiveDate) {
                    JSONDatas.push({ vtid: SOrders[i].vtid, nid: SOrders[i].nid, vid: SOrders[i].vid, rid: SOrders[i].rid, oid: SOrders[i].oid, email: SOrders[i].email, date: SOrders[i].date, Adddate: SOrders[i].Adddate, pid: pid, status: SOrders[i].status })

                }
             }
        }


        this.localS.setItem("SavedOrder", JSONDatas);


    }

    updateOrderStatus(vtid: number, nid: number, vid: number, rid: number, oid: number, email: string, date: string, pid: number,status: string) {

        var SOrders = this.localS.getItem("SavedOrder");
        let JSONDatas = [];
        var addDate = new Date();
        var ActiveDate = new Date();
        ActiveDate.setDate(addDate.getDate() - 1);

        if (SOrders != null) {
            for (var i = 0; i < SOrders.length; i++) {

                var OredrActiveDate = this.convetMoment(SOrders[i].Adddate);
                if (ActiveDate < OredrActiveDate) {
                    if (SOrders[i].oid == oid) {
                        JSONDatas.push({ vtid: SOrders[i].vtid, nid: SOrders[i].nid, vid: SOrders[i].vid, rid: SOrders[i].rid, oid: SOrders[i].oid, email: SOrders[i].email, date: SOrders[i].date, Adddate: SOrders[i].Adddate, pid: pid, status: status })

                    }
                    else {
                        JSONDatas.push({ vtid: SOrders[i].vtid, nid: SOrders[i].nid, vid: SOrders[i].vid, rid: SOrders[i].rid, oid: SOrders[i].oid, email: SOrders[i].email, date: SOrders[i].date, Adddate: SOrders[i].Adddate, pid: SOrders[i].pid, status: SOrders[i].status })
                    }
                }
            }
        }


        this.localS.setItem("SavedOrder", JSONDatas);
    }


    removeOrder(oid: number) {
        var SOrders = this.localS.getItem("SavedOrder");
        let JSONDatas = [];

        var addDate = new Date();
        var ActiveDate = new Date();
        ActiveDate.setDate(addDate.getDate() - 1);

        if (SOrders != null) {
            for (var i = 0; i < SOrders.length; i++) {

                var OredrActiveDate = this.convetMoment(SOrders[i].Adddate);
                if (ActiveDate < OredrActiveDate) {
                    if (SOrders[i].oid == oid) {
                      
                    }
                    else {
                        JSONDatas.push({ vtid: SOrders[i].vtid, nid: SOrders[i].nid, vid: SOrders[i].vid, rid: SOrders[i].rid, oid: SOrders[i].oid, email: SOrders[i].email, date: SOrders[i].date, Adddate: SOrders[i].Adddate, pid: SOrders[i].pid, status: SOrders[i].status })
                    }
                }
            }
        }
        this.localS.setItem("SavedOrder", JSONDatas);
    }

    getOrdersStatus(status: string) {

        var SOrders = this.localS.getItem("SavedOrder");
        let JSONDatas = [];

        var addDate = new Date();
        var ActiveDate = new Date();
        ActiveDate.setDate(addDate.getDate() - 1);
        ActiveDate = this.convetMoment(ActiveDate);
        if (SOrders != null) {
            for (var i = 0; i < SOrders.length; i++) {
                var OredrActiveDate = this.convetMoment(SOrders[i].Adddate);
                if (ActiveDate < OredrActiveDate) {
                    if (SOrders[i].status == status) {
                        JSONDatas.push({ vtid: SOrders[i].vtid, nid: SOrders[i].nid, vid: SOrders[i].vid, rid: SOrders[i].rid, oid: SOrders[i].oid, email: SOrders[i].email, date: SOrders[i].date, Adddate: SOrders[i].Adddate, pid: SOrders[i].pid, status: SOrders[i].status })
                    }
                }
            }
        }

        return JSONDatas;
    }


    // Tools
    convetMoment(startDate: Date): Date {
        let momentToDate = moment(startDate).toDate();
        return new Date(Date.UTC(momentToDate.getFullYear(), momentToDate.getMonth(), momentToDate.getDate(), momentToDate.getHours(), momentToDate.getMinutes(), momentToDate.getSeconds()));

    }

}
