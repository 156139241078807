import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { NationalityModule } from './nationality.module';

@Injectable({
  providedIn: 'root'
})
export class NationalityService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(): Observable<NationalityModule[]> {
        return this.http.get<NationalityModule[]>(environment.apiURL + 'Master/Nationality/');
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Master/Nationality/' + id).toPromise();
    }

    formData: NationalityModule = new NationalityModule();
    list: NationalityModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Master/Nationality/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Master/Nationality/' + this.formData.nationalityID, this.formData);
    }
}
