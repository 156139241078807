export class RequestModule {
    requestID: number = 0;
    companyName: string = '';
    address: string = '';
    phoneNo: string = '';
    email: string = '';
    crNo: string = '';
    touristLicenseNo: string = '';
    jobTitle: string = '';
    status: string = '';
    notes: string = '';
    rejectReason: string = '';
    applicantName: string = '';
    insuranceAmount: number = 0;
}
