import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { VWVisaTypeInputTypesAndInputTypesModule } from '../../../../appshared/visa/visa-type-input-types/visa-type-input-types.module';
import { VisaTypeInputTypesService } from '../../../../appshared/visa/visa-type-input-types/visa-type-input-types.service';
import { AppLoaderService } from '../../../../common/app-loader/app-loader.service';

@Component({
  selector: 'app-input-info-dialog',
  templateUrl: './input-info-dialog.component.html',
  styleUrls: ['./input-info-dialog.component.scss']
})
export class InputInfoDialogComponent implements OnInit {

    public SelectedLanguage: string = "en";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public service: VisaTypeInputTypesService,
        public dialogRef: MatDialogRef<InputInfoDialogComponent>, public translate: TranslateService, private loader: AppLoaderService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit() {
        this.buildItemForm(this.data.id, this.data.code)
    }

    public details: string = "";
    private datas3: any;

    buildItemForm(visaTypeId: number, code: string) {

        this.loader.open();
        this.service.getServiceVWVisaTypeCode(visaTypeId, code)
            .subscribe(res => {
                this.datas3 = res as VWVisaTypeInputTypesAndInputTypesModule[];

                this.service.formDataVW.visaTypeInputTypesID = this.datas3[0].visaTypeInputTypesID;
                this.service.formDataVW.visaTypeID = this.datas3[0].visaTypeID;
                this.service.formDataVW.name = this.datas3[0].name;
                this.service.formDataVW.code = this.datas3[0].code;
                this.service.formDataVW.descriptionAr = this.datas3[0].descriptionAr;
                this.service.formDataVW.descriptionEn = this.datas3[0].descriptionEn;
                this.service.formDataVW.inputType = this.datas3[0].inputType;
                this.service.formDataVW.inputTypesID = this.datas3[0].inputTypesID;


                if (this.SelectedLanguage == "en") {


                    this.details = this.datas3[0].descriptionEn;
                }
                else {
                    this.details = this.datas3[0].descriptionAr;


                }
                this.loader.close();


            },
                err => {
                    this.loader.close(); console.log(err);
                });

       
    }

}
