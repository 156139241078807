<header class="header-area">
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="header-contact-info">
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClassLang()">
                                    <img src="{{CurrentLangSrc}}" alt="image">
                                    <span>{{CurrentLangName}} <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classApplied">
                                    <button (click)="setLang()" class="dropdown-item d-flex align-items-center">
                                        <img src="{{SecondLangSrc}}" class="shadow-sm" alt="flag">
                                        <span>{{SecondLangName}}</span>
                                    </button>

                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="dropdown currency-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClassCurr()">
                                    <span>{{CurrentCurrencyName}} <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classAppliedCurr">
                                    <button (click)="setCurrency(true)" class="dropdown-item d-flex align-items-center">
                                        <span>{{SecondCurrencyName}}</span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="top-header-discount-info">

                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">

                        <!--<li>{{'OperatorHeader_TXT' | translate }}</li>-->
                    </ul>

                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                           
    
                            <li class="nav-item"><a routerLink="/home" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Home' | translate }}</a></li>
                            <li class="nav-item"><a routerLink="/tracking" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Track_Application' | translate }}</a></li>
                            <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'About_Us' | translate }}</a></li>
                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Services' | translate }}</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Contact' | translate }}</a></li>
                        </ul>

                       <div class="others-option">
                            <div class="d-flex align-items-center">
                              
                                <div class="option-item">
                                    <img src="assets/img/logo2.png" alt="logo">
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
