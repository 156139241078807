import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
//import { Observable } from 'rxjs';

import { VisaTypeModule } from './visa-type.module';
import { of, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VisaTypeService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getServiceVisaCountry(VisaCountryID: number): Observable<VisaTypeModule[]> {
        return this.http.get<VisaTypeModule[]>(environment.apiURL + 'Visa/VisaType/VisaTypeWeb/?visaCountryID=' + VisaCountryID);
    }


    getServiceVisaCountryActive(VisaCountryID: number, NationalityID: number): Observable<VisaTypeModule[]> {

        return this.http.get<VisaTypeModule[]>(environment.apiURL + "Visa/VisaType/searchActive/?VCountryID=" + VisaCountryID + "&NationalityID=" + NationalityID+ "", { headers: this.headers });

    }


    getCheckService(VisaTypeID: number, VisaCountryID: number, NationalityID: number, ResidenceCountryID: number, ArrivalDate: string, Lang: string) {
      
        return this.http.get(environment.apiURL + "Visa/VisaType/CheckVisaAvailable/?VTypeID=" + VisaTypeID + "&VCountryID=" + VisaCountryID + "&RCountryID=" + ResidenceCountryID + "&NationalityID=" + NationalityID + "&date=" + ArrivalDate + "&Lang=" + Lang + "", { responseType: 'text' });
     
    }

    getInsertOrder(VisaTypeID: number, VisaCountryID: number, NationalityID: number, ResidenceCountryID: number, ArrivalDate: string, Lang: string, CurrID: number, email: string, arrivalPort: string, phoneNo: string, applicantRequesterName: string, dialing: string) {

        return this.http.get(environment.apiURL + "Visa/VisaType/InsertOrder/?VTypeID=" + VisaTypeID + "&VCountryID=" + VisaCountryID + "&RCountryID=" + ResidenceCountryID + "&NationalityID=" + NationalityID + "&date=" + ArrivalDate + "&Lang=" + Lang + "&CurrID=" + CurrID + "&email=" + email + "&arrivalPort=" + arrivalPort + "&phoneNo=" + phoneNo + "&applicantRequesterName=" + applicantRequesterName + "&dialing=" + dialing +"&userID=0&agentID=0", { responseType: 'text' });
    }
     
    getServiceId(id: number): Observable<VisaTypeModule[]> {
        return this.http.get<VisaTypeModule[]>(environment.apiURL + 'Visa/VisaType/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Visa/VisaType/' + id).toPromise();
    }

    formData: VisaTypeModule = new VisaTypeModule();
    list: VisaTypeModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Visa/VisaType/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Visa/VisaType/' + this.formData.visaTypeID, this.formData);
    }

    getCheckVoucher(visaTypeID: number, code: string) {
        return this.http.get(environment.apiURL + "Visa/VisaType/CheckVoucher/?visaTypeID=" + visaTypeID + "&code=" + code, { responseType: 'text' });
    }

    getCheckAgeWarnings(visaTypeID: number, nationalityID: number, lang: string) {
        return this.http.get(environment.apiURL + "Visa/VisaType/CheckAgeWarnings/?visaTypeID=" + visaTypeID + "&nationalityID=" + nationalityID + "&lang=" + lang, { responseType: 'text' });
    }

    getCheckGenderWarnings(visaTypeID: number, nationalityID: number, lang: string) {
        return this.http.get(environment.apiURL + "Visa/VisaType/CheckGenderWarnings/?visaTypeID=" + visaTypeID + "&nationalityID=" + nationalityID + "&lang=" + lang, { responseType: 'text' });
    }


    getCheckWarnings(dobActive: boolean, genderActive: boolean, visaTypeID: number, nationalityID: number, gender: string, dob: string, lang: string) {
        return this.http.get(environment.apiURL + "Visa/VisaType/CheckAgeGenderWarnings/?visaTypeID=" + visaTypeID + "&nationalityID=" + nationalityID + "&gender=" + gender + "&dob=" + dob + "&lang=" + lang, { responseType: 'text' });
    }
}
