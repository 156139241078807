<section class="funfacts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <!--<i class='bx bx-bullseye'></i>-->
                    <p>{{'Oman_Visa_Orders' | translate}}</p>
                    <h3 class="odometer" [attr.data-count]="OmanOrders">0</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <!--<i class='bx bx-group'></i>-->
                    <p>{{'International_Visa_Orders' | translate}}</p>
                    <h3 class="odometer" [attr.data-count]="InternationalOrders">0</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <!--<i class='bx bx-shape-polygon'></i>-->
                    <p>{{'Countries_Covered' | translate}}</p>
                    <h3 class="odometer" [attr.data-count]="CountriesCovered">0</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <!--<i class='bx bx-trophy'></i>-->
                    <p>{{'Years_of_Experience' | translate}}</p>
                    <h3 class="odometer" [attr.data-count]="YearsExperience">0</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
