import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { OrderUserCommentModule, VWOrderUserCommentAndMGUserModule } from './order-user-comment.module';

@Injectable({
  providedIn: 'root'
})
export class OrderUserCommentService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }



    getServiceOrder(OrderID: number): Observable<OrderUserCommentModule[]> {
        return this.http.get<OrderUserCommentModule[]>(environment.apiURL + 'Orders/OrderUserComment/search/' + OrderID);
    }

    getServiceOrderCommentType(OrderID: number, commentType: string): Observable<OrderUserCommentModule[]> {
        return this.http.get<OrderUserCommentModule[]>(environment.apiURL + 'Orders/OrderUserComment/searchStatus/?orderID=' + OrderID + '&commentType=' + commentType);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Orders/OrderUserComment/' + id).toPromise();
    }

    formData: OrderUserCommentModule = new OrderUserCommentModule();
    list: OrderUserCommentModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Orders/OrderUserComment/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Orders/OrderUserComment/' + this.formData.orderUserCommentID, this.formData);
    }




    getServiceVW(): Observable<VWOrderUserCommentAndMGUserModule[]> {
        return this.http.get<VWOrderUserCommentAndMGUserModule[]>(environment.apiURL + 'Orders/VWOrderUserCommentAndMGUser/');
    }
    getServiceVWVisaTypeNationality(VisaTypeNationalityID: number): Observable<VWOrderUserCommentAndMGUserModule[]> {
        return this.http.get<VWOrderUserCommentAndMGUserModule[]>(environment.apiURL + 'Orders/VWOrderUserCommentAndMGUser/search/' + VisaTypeNationalityID);
    }
}
